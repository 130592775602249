import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import './CharCreatorSteps.css';

import AnimeFSlim from "../images/bodyType/anime/female/AnimeFSlim.png";
import AnimeFSmall from "../images/bodyType/anime/female/AnimeFSmall.png";
import AnimeFAverage from "../images/bodyType/anime/female/AnimeFAverage.png";
import AnimeFLarge from "../images/bodyType/anime/female/AnimeFLarge.png";
import AnimeFLargest from "../images/bodyType/anime/female/AnimeFLargest.jpg";

import RealFSlim from "../images/bodyType/real/female/RealFSlim.png";
import RealFSmall from "../images/bodyType/real/female/RealFSmall.png";
import RealFAverage from "../images/bodyType/real/female/RealFAverage.png";
import RealFLarge from "../images/bodyType/real/female/RealFLarge.png";
import RealFLargest from "../images/bodyType/real/female/RealFLargest.png";

import AnimeMSlim from "../images/bodyType/anime/male/AnimeMSlim.png";
import AnimeMAverage from "../images/bodyType/anime/male/AnimeMAverage.png";
import AnimeMBulky from "../images/bodyType/anime/male/AnimeMBig.png";

import RealMSlim from "../images/bodyType/real/male/RealMSlim.png";
import RealMAverage from "../images/bodyType/real/male/RealMAverage.png";
import RealMBulky from "../images/bodyType/real/male/RealMBulky.png";
import { useCharacterCreation } from "../CharacterCreationContext";

const SimpleStepSeven = () => {
    const { isAnime, isFemale, characterData, updateCharacterData } = useCharacterCreation();
    const [selectedBodyType, setSelectedBodyType] = useState(characterData.bodyType || '');
    const { t } = useTranslation();

    const bodyTypes = {
        animeFemale: [
            { name: 'Slim', image: AnimeFSlim },
            { name: 'Small', image: AnimeFSmall },
            { name: 'Average', image: AnimeFAverage },
            { name: 'Curvy', image: AnimeFLarge },
            { name: 'Large', image: AnimeFLargest },
        ],
        realFemale: [
            { name: 'Slim', image: RealFSlim },
            { name: 'Small', image: RealFSmall },
            { name: 'Average', image: RealFAverage },
            { name: 'Curvy', image: RealFLarge },
            { name: 'Large', image: RealFLargest },
        ],
        animeMale: [
            { name: 'Slim', image: AnimeMSlim },
            { name: 'Average', image: AnimeMAverage },
            { name: 'Bulky', image: AnimeMBulky },
        ],
        realMale: [
            { name: 'Slim', image: RealMSlim },
            { name: 'Average', image: RealMAverage },
            { name: 'Bulky', image: RealMBulky },
        ],
    };

    const handleBodyTypeSelect = (type) => {
        setSelectedBodyType(type);
        updateCharacterData({ bodyType: type });
    };

    const getCurrentBodyTypes = () => {
        if (isAnime) {
            return isFemale ? bodyTypes.animeFemale : bodyTypes.animeMale;
        } else {
            return isFemale ? bodyTypes.realFemale : bodyTypes.realMale;
        }
    };

    const currentBodyTypes = getCurrentBodyTypes();

    return (
        <div>
            <div className="general-multiple-options-container">
                <div className="top-row">
                    {currentBodyTypes.slice(0, 3).map((type, index) => (
                        <div key={`${type.name}-${index}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedBodyType === type.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleBodyTypeSelect(type.name)}
                            >
                                <img
                                    src={type.image}
                                    alt={type.name}
                                    className={`general-image-creator ${selectedBodyType === type.name ? 'selected' : ''}`}
                                    style={{pointerEvents: 'none'}}
                                />
                                <span className='enforced-display-char-creator'>{t(type.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="bottom-row-uneven">
                    {currentBodyTypes.slice(3).map((type, index) => (
                        <div key={`${type.name}-${index + 3}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedBodyType === type.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleBodyTypeSelect(type.name)}
                            >
                                <img
                                    src={type.image}
                                    alt={type.name}
                                    className={`general-image-creator ${selectedBodyType === type.name ? 'selected' : ''}`}
                                    style={{pointerEvents: 'none'}}
                                />
                                <span className='enforced-display-char-creator'>{t(type.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SimpleStepSeven;
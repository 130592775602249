import {getUserAccessToken} from "../../App";

export async function fetchUserData(tagName) {
    const response = await fetch('https://api.elyza.app/v1/fetch-user-data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({
            tagname: tagName,
        }),
    });
    const data = await response.json();
    if (data.error === "User not found") {
        throw new Error("User not found");
    }
    return data;
}


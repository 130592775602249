import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import './AdvancedCharCreatorSteps.css';
import { useAdvancedCharacterCreation } from "../AdvancedCharacterContext";

const AdvancedStepTwo = () => {
    const { t } = useTranslation();
    const { advancedCharData, updateAdvancedCharacterData } = useAdvancedCharacterCreation();

    const [age, setAge] = useState(advancedCharData.age || '');
    const [name, setName] = useState(advancedCharData.charName || '');
    const [shortDescription, setShortDescription] = useState(advancedCharData.description || '');
    const [isTyping, setIsTyping] = useState(false);

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        };
    };

    const validateAge = useCallback(() => {
        if (age !== '') {
            const numAge = parseInt(age, 10);
            if (numAge < 18) {
                setAge('18');
                updateAdvancedCharacterData({age: '18'});
            } else {
                updateAdvancedCharacterData({age: numAge.toString()});
            }
        }
    }, [age, updateAdvancedCharacterData]);
//eslint-disable-next-line
    const debouncedValidateAge = useCallback(debounce(validateAge, 1500), [validateAge]);

    useEffect(() => {
        if (!isTyping) {
            debouncedValidateAge();
        }
    }, [age, isTyping, debouncedValidateAge]);

    const handleNameChange = (e) => {
        setName(e.target.value);
        updateAdvancedCharacterData({charName: e.target.value});
    };

    const handleAgeChange = (e) => {
        const value = e.target.value;
        if (value === '' || /^\d+$/.test(value)) {
            setAge(value);
            setIsTyping(true);
            debounce(() => setIsTyping(false), 1500)();
        }
    };

    const handleDescriptionChange = (e) => {
        setShortDescription(e.target.value);
        updateAdvancedCharacterData({description: e.target.value});
    };

    return (
        <div className="final-step-container">
            <div className="input-container-top">
                <div className="textbox-name-general" style={{width: '50%'}}>
                    <label className="label-sign-up-h">{t('CharacterName')}</label>
                    <input
                        type="text"
                        placeholder={t('CharacterNamePlaceholder')}
                        value={name}
                        onChange={handleNameChange}
                        maxLength="25"
                    />
                </div>
                <div className="textbox-name-general" style={{width: '50%'}}>
                    <label className="label-sign-up-h">{t('Age')}</label>
                    <input
                        type="text"
                        placeholder={'18+'}
                        value={age}
                        onChange={handleAgeChange}
                        maxLength="3"
                    />
                </div>
            </div>

            <div className="input-container-middle">
                <div className="textbox-name-general-short">
                        <label  className="label-sign-up-h">{t('ShortDescription')}</label>
                    <textarea
                        id="personalityDescription"
                        value={shortDescription}
                        onChange={handleDescriptionChange}
                        rows="4"
                        maxLength="300"
                        cols="50"
                        placeholder={t('ShortDescriptionPlaceholder')}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdvancedStepTwo;
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ViewMemoryModal from "../../CharacterCard/ViewMemoryModal";
import {userIDExists} from "../../../App";

const PublicMemoriesUserMobile = ({ userData, tagName, setIsUserLoggedIn, publicMemories, setPublicMemories}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [shareCode, setShareCode] = useState('');
    const navigate = useNavigate();

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        className: 'public-chats-slider',
    };

    const fetchPublicMemories = useCallback(async () => {
        if (userData) {
            try {
                setIsLoading(true);
                if (Array.isArray(userData.public_memories)) {
                    setPublicMemories(userData.public_memories);
                } else {
                    setPublicMemories([]);
                }
            } catch (err) {
                console.error('Error fetching public memories:', err);
                setPublicMemories([]);
            } finally {
                setIsLoading(false);
            }
        }
        //eslint-disable-next-line
    }, [userData]);

    useEffect(() => {
        fetchPublicMemories();
    }, [fetchPublicMemories]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const memoryId = urlParams.get('memoryId');
        const shareCode = urlParams.get('shareCode');

        if (memoryId) {
            const chat = publicMemories.find(chat => chat.id === parseInt(memoryId));
            if (chat) {
                setSelectedChat(chat);
                setIsModalOpen(true);
                if (shareCode) {
                    setShareCode(shareCode);
                }
            }
        }
    }, [publicMemories]);

    const handleUsernameClick = (username, e) => {
        e.stopPropagation(); // Prevent slide change when clicking username
        navigate('/user/' + username);
    };

    const handleChatClick = async (chat) => {
        const isLoggedIn = await userIDExists();

        if (!isLoggedIn) {
            setIsUserLoggedIn(true);
            return;
        }

        setSelectedChat(chat);
        setIsModalOpen(true);
    };

    const truncate = (str, num) => {
        if (str.length <= num) return str;
        return str.slice(0, num) + '...';
    };

    if (isLoading) {
        return null;
    }

    return (
        <div className="public-chats-mobile-container">
            <Slider {...sliderSettings}>
                {publicMemories.map((chat, index) => (
                    <div key={chat.id || index} className="slider-chat-item">
                        <div className="chat-card" onClick={() => handleChatClick(chat)}>
                            <div className="public-chat-upper"
                                 onClick={(e) => handleUsernameClick(tagName, e)}
                            >
                                <img
                                    src={chat.profile_url}
                                    alt={''}
                                    className="user-avatar"
                                />
                                <div className="public-chat-author">
                                    <span className="public-chat-by-author">
                                        {t('By')}
                                    </span>
                                    <br />
                                    @{tagName}
                                </div>
                            </div>

                            <div className="public-chat-title">{chat.memory_name}</div>

                            <div className="public-chat-messages">
                                {chat.last_two_messages.map((message, msgIndex) => (
                                    <div
                                        key={msgIndex}
                                        className={message.isUser ? "public-chat-user" : "public-chat-bot"}
                                    >
                                        {truncate(message.content, 50)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>

            {selectedChat && (
                <ViewMemoryModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        setShareCode('');
                    }}
                    title={selectedChat.memory_name}
                    memoryId={selectedChat.memory_id}
                    profileURL={selectedChat.profile_url}
                    setIsUserLoggedIn={setIsUserLoggedIn}
                    shareCode={shareCode.length > 0 ? shareCode : selectedChat.share_code}
                    privacyStatus={selectedChat.privacy_status}
                    identityName={selectedChat.identity_name}
                    isUserPage={true}
                >
                    <div className="modal-chat-messages">
                        {selectedChat.lastMessages && selectedChat.lastMessages.length > 0 ? (
                            selectedChat.lastMessages
                                .sort((a, b) => (a.isUser === b.isUser) ? 0 : a.isUser ? 1 : -1)
                                .map((message, index) => (
                                    <div
                                        key={index}
                                        className={message.isUser ? "modal-chat-user" : "modal-chat-bot"}
                                    >
                                        {message.content}
                                    </div>
                                ))
                        ) : (
                            <div></div>
                        )}
                    </div>
                </ViewMemoryModal>
            )}
        </div>
    );
};

export default React.memo(PublicMemoriesUserMobile);
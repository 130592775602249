import React from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";
import {useProfileData} from "../Context/ProfileDataContext";
// import {ReactComponent as VisaGray} from "../UserSettings/ProfileIcons/FreeMoney.svg";
import {ReactComponent as Visa} from "../UserSettings/ProfileIcons/Visa.svg";
import {ReactComponent as MasterCard} from "../UserSettings/ProfileIcons/MasterCard.svg";
import {ReactComponent as Crypto} from "../UserSettings/ProfileIcons/Crypto.svg";
import {ReactComponent as ChevronRight} from "../UserSettings/ProfileIcons/ChevronRight.svg";

const AutomaticPurchaseModal = ({
                                    isOpen,
                                    onClose,
                                    handleInstantTransaction,
                                    tokens,
                                    cost,
                                    handleRegularTransaction,
                                    brand
                                }) => {
    const {t} = useTranslation();
    const {profileData} = useProfileData();
    let lastFourDigits = profileData.subscription_data.lastFourDigits;

    const handleAutomaticPurchase = () => {
        handleInstantTransaction();
        onClose();
    }

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-label'
                         style={{paddingBottom: '20px'}}
                    >
                        {t('confirmPurchase')}
                        <div className="modal-close-icon-notification" onClick={() => {
                            onClose(false)
                        }}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">

                        <div className="automatic-card-detail"
                             onClick={() => handleRegularTransaction()}
                        >
                            <div className="act-container">
                                {lastFourDigits === '0000' ? (
                                    <Crypto
                                        className="sub-cart-details"
                                        style={{paddingTop: '0'}}
                                    />
                                ) : brand === 'mastercard' ? (
                                    <MasterCard
                                        className="sub-cart-details"
                                        style={{paddingTop: '0'}}
                                    />
                                ) : brand === 'visa' ? (
                                    <Visa
                                        className="sub-cart-details"
                                        style={{paddingTop: '0'}}
                                    />
                                ) : null}

                                <div
                                    className={`container-text-capture ${profileData.subscription_data.tier === 'FREE' ? 'free-plan' : ''}`}>
                                    {profileData.subscription_data.tier === 'FREE' ? (
                                        <div className="plan-length"
                                             style={{cursor: 'unset'}}
                                        ></div>) : (
                                        <>
                                            <div className="plan-length">{t('EndingIn')}</div>
                                            <div className="plan-type-2">
                                                <svg height="10" width="10">
                                                    <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                </svg>
                                                <svg height="10" width="10">
                                                    <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                </svg>
                                                <svg height="10" width="10">
                                                    <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                </svg>
                                                <svg height="10" width="10">
                                                    <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                </svg>
                                                {lastFourDigits}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div
                                className="instant-transaction-cost">
                                {cost} $
                                <ChevronRight/>
                            </div>
                        </div>
                    </div>

                    <button onClick={() => {handleAutomaticPurchase()}}
                            className="notification-button-main">
                        {t('Continue')}
                    </button>

                    <span
                        style={{
                            display: "flex",
                            color: "#c4c4c4",
                            marginTop: '10px',
                            width: "100%",
                            textAlign: "center",
                            justifyContent: "center"
                        }}
                    >{t('youWillReceive')}
                        {tokens} {' '}
                        {t('tokens')}</span>
                </div>
            </div>
        ),
        document.body
    );
};

export default AutomaticPurchaseModal
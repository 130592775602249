import React, { createContext, useState, useContext } from 'react';
import {useTranslation} from "react-i18next";

const TagContext = createContext();

export const useTags = () => useContext(TagContext);

export const TagProvider = ({ children }) => {
    const {t} = useTranslation();
    const [tags] = useState([
        `${t('Anime')}`,
        `${t('Real')}`,
        `${t('Furry')}`,
        `${t('Gay')}`,
        `${t('Lesbian')}`,
        `${t('Bisexual')}`,
        `${t('Femboy')}`,
        `${t('Futanari')}`,
        `${t('Tomboy')}`,
        `${t('Goth')}`,
        `${t('Family')}`,
        // `${t('Celebrity')}`,
        `${t('YouTuber')}`,
        `${t('VTuber')}`,
        `${t('NonEnlish')}`,
        `${t('Manga')}`,
        `${t('Videogames')}`,
        `${t('Cartoon')}`,
        `${t('Teenager')}`,
        `${t('School')}`,
        `${t('Girlfriend')}`,
        `${t('Wholesome')}`,
        `${t('Romantic')}`,
        `${t('Cute')}`,
        `${t('Drama')}`,
        `${t('Childhood Friend')}`,
        `${t('Wife')}`,
        `${t('BDSM')}`,
        `${t('Femdom')}`,
        `${t('NonConsensual')}`,
        `${t('MILF')}`,
        `${t('Chubby')}`,
        `${t('Original')}`,
        `${t('MindControl')}`,
        `${t('NonHuman')}`,
        `${t('SizeDifference')}`,
        `${t('Historical')}`,
        `${t('Kink')}`
    ]);
    const [selectedTags, setSelectedTags] = useState([]);

    const handleTagClick = (tag) => {
        setSelectedTags(prevTags => prevTags.includes(tag) ? prevTags.filter(t => t !== tag) : [...prevTags, tag]);
    };

    return (
        <TagContext.Provider value={{ tags, selectedTags, setSelectedTags, handleTagClick }}>
            {children}
        </TagContext.Provider>
    );
};

// characterApiFunctions.js
import { getUserAccessToken, getUserID } from "../../../../App";

export const fetchData = async (identityName) => {
    try {
        const userId = await getUserID();
        const userAccessToken = await getUserAccessToken();

        const response = await fetch('https://api.elyza.app/v1/direct-identity-info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userAccessToken
            },
            body: JSON.stringify({ userId, identityName })
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }

        const directIdentityInfo = await response.json();

        if (directIdentityInfo.data) {
            return directIdentityInfo.data;
        } else {
            return null;
        }
    } catch (error) {
        console.error('Failed to fetch data:', error);
        throw error;
    }
};

export const updateCharacter = async (updatedCharacterData) => {
    try {
        const userId = await getUserID();
        const userAccessToken = await getUserAccessToken();

        const response = await fetch('https://api.elyza.app/v1/advanced-identity-edit', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': userAccessToken
            },
            body: JSON.stringify({
                userId,
                ...updatedCharacterData
            })
        });

        if (!response.ok) {
            throw new Error(`Failed to update character: ${response.status}`);
        }

        return true;
    } catch (error) {
        console.error('An error occurred while updating the character:', error);
        throw error;
    }
};
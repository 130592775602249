import React from "react";
import {ReactComponent as CloseIcon} from "./ProfileIcons/Close.svg"
import {useTranslation} from "react-i18next";

const NameChangePopup = ({handleClosePopup, setNewUsername, internalOnSave, usernameChanged, newUsername}) => {

    const handleNewNameChange = (event) => {
        setNewUsername(event.target.value);
    };

    const { t } = useTranslation();

    return (

        <div className="popup-background"
             onClick={handleClosePopup}>

            <div className="name-change-popup" onClick={(e) => e.stopPropagation()}>
                <div className="main-upper-text-row">
                    <div className="profile-setting-label">{t('GetNewUsername')}</div>
                    <div className="close-modal-icon" onClick={handleClosePopup}><CloseIcon/></div>
                </div>
                <div className="profile-setting-sublabel">{t('ChangeUsernameDescription')}
                </div>

                <input
                    style={{marginTop: '15px'}}
                    type="text"
                    placeholder="New Username"
                    className="new-name-input"
                    value={newUsername}
                    onChange={handleNewNameChange}
                />

                <div className='a-straight-line-3'></div>
                <button className="save-new-name-button" onClick={internalOnSave}>
                    {t('SaveUsername')}
                </button>

                {usernameChanged === 'success' && (
                    <div className="profile-success-message">{t('UserNameSavedSuccessfully')}</div>)}
                {usernameChanged === 'error' && (
                    <div className="profile-error-message">{t('UserNameSaveFailed')}</div>)}
            </div>

        </div>

    )
}

export default NameChangePopup;
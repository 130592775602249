import React, { useState } from 'react';
import { ReactComponent as NavArrow } from "../../../components/Sales&SEO/icons/dropdown.svg";
import './FaqPricing.css';
import {useTranslation} from "react-i18next";

const PricingFrequentQuestions = () => {
    const [activeIndex, setActiveIndex] = useState(-1);

    const { t } = useTranslation();


    const faqData = [
        {
            id: 1,
            question: t('question1'),
            answer: t('answer1')
        },
        {
            id: 2,
            question: t('question2'),
            answer: t('answer2')
        },
        {
            id: 3,
            question: t('question3'),
            answer: t('answer3')
        },
        {
            id: 4,
            question: t('question4'),
            answer: t('answer4')
        },
        {
            id: 5,
            question: t('question5'),
            answer: t('answer5')
        },
        {
            id: 6,
            question: t('question6'),
            answer: t('answer6')
        },
        {
            id: 7,
            question: t('question7'),
            answer: t('answer7')
        }
    ];

    const toggleAnswer = (index) => {
        if (activeIndex === index) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div className="faq-container"
        style={{borderTop: '1px solid #2D2D2D'}}
        >
            <div className="carousel-title">
                <span className="upper-text">{t('title')}</span>
                <span className="carousel-subtitle-2">{t('subtitle1')}</span>
                <span className="carousel-subtitle-2">{t('subtitle2')}</span>
            </div>
            <div className="faq-desktop"
            style={{marginTop: '-10px'}}
            >
                {faqData.map((item, index) => (
                    <div
                        className={`faq-item ${index === activeIndex ? 'active' : ''}`}
                        key={item.id}
                        onClick={() => toggleAnswer(index)}
                        style={{width: '100%', maxWidth: '800px'}}
                    >
                        <div className="faq-question-pricing">{item.question}</div>
                        <NavArrow className={`faq-toggle-icon ${index === activeIndex ? 'active' : ''}`} />
                        {index === activeIndex && <div className="faq-answer-pricing"
                        style={{whiteSpace: 'pre-line'}}
                        >{item.answer}</div>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PricingFrequentQuestions;

import {getUserAccessToken} from "../../App";

export async function potentialLoraSearch(charName) {
    try {
        const response = await fetch('https://api.elyza.app/v1/potential-style', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken(),
            },
            body: JSON.stringify({name: charName}),
        });

        return await response.json();
    } catch (error) {
        console.error('Error fetching image styles:', error);
        return [];
    }
}

export async function generateAndSaveProfileImage(generationParams) {
    try {
        const response = await fetch('https://api.elyza.app/v1/generate-new-profile', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken(),
            },
            body: JSON.stringify({generationParams}),
        });

        return await response.json();
    } catch (error) {
        console.error('Error fetching image styles:', error);
        return [];
    }
}
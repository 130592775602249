import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as CloseIcon } from '../icons/cross.svg';
import { useTranslation } from "react-i18next";

const DeleteShareMemoryModal = ({ isOpen, onClose, onDeleteConfirm }) => {

    const handleDeleteConfirm = () => {
        onDeleteConfirm();
        onClose();
    }

    const { t } = useTranslation();

    if (!isOpen) return null;

    const modalContent = (
        <div className="main-space-finder-modal" onClick={onClose} role="dialog" aria-modal="true">
            <div className="app-modal-content-response-settings"
                 style={{ width: '50%', minWidth: '420px', maxWidth: '420px'}}
                 onClick={(e) => e.stopPropagation()}>

                <div className="main-upper-text-row">
                    <div className="profile-setting-label">{t('deleteMemoryTitle')}</div>
                    <div className="close-modal-icon" onClick={onClose} aria-label={t('close')}>
                        <CloseIcon />
                    </div>
                </div>

                <div className="profile-setting-sublabel">
                    {t('deleteMemoryDescription')}
                </div>

                <div className="parent">
                    <button onClick={handleDeleteConfirm}
                            className="save-settings-button"
                            style={{ width: '100%', height: '45px', marginTop: '15px' }}>
                        {t('deleteMemoryButton')}
                    </button>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.body
    );
};

export default DeleteShareMemoryModal;

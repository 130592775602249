import React, { useState } from 'react';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import AnimeFWhite from "../images/ethnicity/anime/female/AnimeFWhite.jpg";
import AnimeFAsian from "../images/ethnicity/anime/female/AnimeFAsian.jpeg";
import AnimeFBlack from "../images/ethnicity/anime/female/AnimeFBlack.jpg";
import AnimeFLatina from "../images/ethnicity/anime/female/AnimeFLatina.jpeg";
import AnimeFArabic from "../images/ethnicity/anime/female/AnimeFArabic.jpeg";

import AnimeMWhite from "../images/ethnicity/anime/male/AnimeMWhite.jpeg";
import AnimeMAsian from "../images/ethnicity/anime/male/AnimeMAsian.jpeg";
import AnimeMBlack from "../images/ethnicity/anime/male/AnimeMBlack.jpeg";
import AnimeMLatina from "../images/ethnicity/anime/male/AnimeMLatino.jpeg";
import AnimeMArabic from "../images/ethnicity/anime/male/AnimeMArabic.jpeg";

import RealFWhite from "../images/ethnicity/real/female/RealFWhite.jpeg";
import RealFAsian from "../images/ethnicity/real/female/RealFAsian.jpeg";
import RealFBlack from "../images/ethnicity/real/female/RealFBlack.jpeg";
import RealFLatina from "../images/ethnicity/real/female/RealFLatina.jpeg";
import RealFArabic from "../images/ethnicity/real/female/RealFArabic.jpeg";

import RealMWhite from "../images/ethnicity/real/male/RealMWhite.jpeg";
import RealMAsian from "../images/ethnicity/real/male/RealMAsian.jpeg";
import RealMBlack from "../images/ethnicity/real/male/RealMBlack.png";
import RealMLatina from "../images/ethnicity/real/male/RealMLatino.jpeg";
import RealMArabic from "../images/ethnicity/real/male/RealMArabic.jpeg";

// import {ReactComponent as ForwardArrow} from "../icons/backArrowIcon.svg";
// import {ReactComponent as BackwardArrow} from "../icons/forwardArrowIcon.svg";

import './CustomSliderStyles.css';
import {useCharacterCreation} from "../CharacterCreationContext";

const SimpleStepTwo = () => {
    const { isAnime, characterData, isFemale, updateCharacterData } = useCharacterCreation();
    const [selectedEthnicity, setSelectedEthnicity] = useState(characterData.ethnicity || '');

    const { t } = useTranslation();

    const ethnicityFemaleAnime = [
        { name: 'White', image: AnimeFWhite },
        { name: 'Asian', image: AnimeFAsian },
        { name: 'Black', image: AnimeFBlack },
        { name: 'Latina', image: AnimeFLatina },
        { name: 'Arabic', image: AnimeFArabic },
    ];

    const ethnicityMaleAnime = [
        { name: 'White', image: AnimeMWhite },
        { name: 'Asian', image: AnimeMAsian },
        { name: 'Black', image: AnimeMBlack },
        { name: 'Latino', image: AnimeMLatina },
        { name: 'Arabic', image: AnimeMArabic },
    ];

    const ethnicityFemaleReal = [
        { name: 'White', image: RealFWhite },
        { name: 'Asian', image: RealFAsian },
        { name: 'Black', image: RealFBlack },
        { name: 'Latina', image: RealFLatina },
        { name: 'Arabic', image: RealFArabic },
    ];

    const ethnicityMaleReal = [
        { name: 'White', image: RealMWhite },
        { name: 'Asian', image: RealMAsian },
        { name: 'Black', image: RealMBlack },
        { name: 'Latino', image: RealMLatina },
        { name: 'Arabic', image: RealMArabic },
    ];

    const handleStyleSelect = (ethnicity) => {
        setSelectedEthnicity(ethnicity);
        updateCharacterData({ethnicity: ethnicity})
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2.33,
        slidesToScroll: 1,
        centerPadding: '60px',
        // prevArrow: <BackwardArrow/>,
        // nextArrow: <ForwardArrow/>,
      arrows: false,
    };

    const currentStyles = isAnime
        ? (isFemale ? ethnicityFemaleAnime : ethnicityMaleAnime)
        : (isFemale ? ethnicityFemaleReal : ethnicityMaleReal);

    return (
        <div className="character-style-carousel">
            <Slider {...settings}>
                {currentStyles.map((style) => (
                    <div key={style.name} className="slide-item">
                        <div
                            className={`general-option-creator-carousel ${selectedEthnicity === style.name ? 'selected' : ''}`}
                            onClick={() => handleStyleSelect(style.name)}
                        >
                            <img
                                src={style.image}
                                alt={style.name}
                                className="general-image-creator-carousel"
                            />
                            <span className='enforced-display-char-creator'
                            style={{left: '15px', top: '10px'}}
                            >{t(style.name)}</span>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SimpleStepTwo;
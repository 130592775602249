import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getUserAccessToken } from "../../App";
import { useNavigate } from "react-router-dom";

const AnalyticsContext = createContext(null);
const HEARTBEAT_INTERVAL = 60000;
const BATCH_INTERVAL = 45000;
const STORAGE_KEY = 'analytics_queue';
const ANALYTICS_ENABLED = false;

function generateUUID() {
    // Try native crypto.randomUUID() first
    if (crypto && crypto.randomUUID) {
        try {
            return crypto.randomUUID();
        } catch (e) {
            console.log('crypto.randomUUID failed, using fallback');
        }
    }

    // Fallback UUID v4 implementation
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        //eslint-disable-next-line
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function AnalyticsProvider({ children }) {
    const [visitorId] = useState(() => {
        const stored = localStorage.getItem('visitor_id');
        if (stored) return stored;
        const newId = generateUUID();
        localStorage.setItem('visitor_id', newId);
        return newId;
    });
    const [sessionId] = useState(() => `${Date.now()}-${Math.random().toString(36).slice(2, 15)}`);
    const [sessionStartTime] = useState(Date.now());
    const [eventQueue, setEventQueue] = useState(() => {
        try {
            const stored = localStorage.getItem(STORAGE_KEY);
            return stored ? JSON.parse(stored) : [];
        } catch {
            return [];
        }
    });

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(eventQueue));
    }, [eventQueue]);

    async function processEventQueue() {
        if (!ANALYTICS_ENABLED) return;
        if (eventQueue.length === 0) return;

        try {
            // Take a snapshot of current queue before processing
            const eventsToProcess = [...eventQueue];

            const response = await fetch('https://api.elyza.app/v1/analytics', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({ events: eventsToProcess })
            });

            if (!response.ok) throw new Error(`Analytics batch error: ${response.status}`);

            // Only clear processed events
            setEventQueue(currentQueue =>
                currentQueue.slice(eventsToProcess.length)
            );

        } catch (error) {
            console.error('Analytics batch error:', error);
        }
    }

    // Base event tracking
    async function trackEvent(eventName, properties = {}) {
        if (!ANALYTICS_ENABLED) return;
        const event = {
            event_name: eventName,
            properties,
            visitor_id: visitorId,
            session_id: sessionId,
            page_url: window.location.href,
            device_info: {
                userAgent: navigator.userAgent,
                screenSize: `${window.screen.width}x${window.screen.height}`
            },
            timestamp: Date.now()
        };

        setEventQueue(currentQueue => {
            const newQueue = [...currentQueue, event];
            // Don't process here, just notify when limit is reached
            // if (newQueue.length >= BATCH_SIZE_LIMIT) {
            //     console.log(`Queue size limit reached (${BATCH_SIZE_LIMIT} events)`);
            // }
            return newQueue;
        });
    }

    // Real-time session tracking
    async function trackSession(eventName, properties = {}) {
        if (!ANALYTICS_ENABLED) return;
        try {
            const payload = {
                event_name: eventName,
                visitor_id: visitorId,
                session_id: sessionId,
                properties,
                page_url: window.location.href,
                referrer: document.referrer,
                device_info: {
                    userAgent: navigator.userAgent,
                    screenSize: `${window.screen.width}x${window.screen.height}`
                }
            };

            const response = await fetch('https://api.elyza.app/v1/session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) throw new Error(`Session tracking error: ${response.status}`);
            return await response.json();
        } catch (error) {
            console.error('Session tracking error:', error);
            return { error: error.message };
        }
    }

    // Queue processing effects
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (eventQueue.length > 0) {
                processEventQueue();
            }
        }, BATCH_INTERVAL);

        return () => clearInterval(intervalId);
        //eslint-disable-next-line
    }, [eventQueue]);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden' && eventQueue.length > 0) {
                processEventQueue();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('pagehide', processEventQueue);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('pagehide', processEventQueue);
        };
        //eslint-disable-next-line
    }, [eventQueue]);

    // Session tracking effect
    useEffect(() => {
        let intervalId;

        const startTracking = async () => {
            const isFirstVisit = !localStorage.getItem('returning_visitor');
            if (!isFirstVisit) {
                localStorage.setItem('returning_visitor', 'true');
            }

            await trackSession('session_started', {
                is_first_visit: isFirstVisit,
                entry_page: window.location.href
            });

            intervalId = setInterval(async () => {
                await trackSession('session_heartbeat', {
                    duration: Date.now() - sessionStartTime,
                });
            }, HEARTBEAT_INTERVAL);
        };

        startTracking();

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
        //eslint-disable-next-line
    }, []);

    return (
        <AnalyticsContext.Provider value={{
            trackEvent,
            visitorId,
            sessionId,
            trackSession
        }}>
            {children}
        </AnalyticsContext.Provider>
    );
}

// Main analytics hook
export function useAnalytics() {
    const context = useContext(AnalyticsContext);
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return context;
}

export function useButtonTracking() {
    const { trackEvent } = useAnalytics();
    const navigate = useNavigate();

    // Memoize the tracking function
    return useCallback((action, data = {}) => {
        const eventData = {
            action,
            path: window.location.pathname,
            timestamp: Date.now(),
            ...data
        };

        // Use a stable version of trackEvent
        trackEvent('button_click', eventData);

        if (data.callback) {
            data.callback();
        }

        if (data.navigate) {
            navigate(data.navigate);
        }
        //eslint-disable-next-line
    }, []); // Empty dependency array since we don't need to react to changes
}


export function usePageTracking(pageName) {
    const { trackEvent } = useAnalytics();

    useEffect(() => {
        const startTime = Date.now();
        const trackPage = async () => {
            // Track page enter once
            await trackEvent('page_enter', {
                page: pageName,
                timestamp: startTime
            });
        };

        trackPage();

        // Cleanup function
        return () => {
            const trackExit = async () => {
                const timeSpent = Date.now() - startTime;
                await trackEvent('page_exit', {
                    page: pageName,
                    time_spent: timeSpent,
                    page_url: window.location.href
                });
            };
            trackExit();
        };
        //eslint-disable-next-line
    }, [pageName]);
}
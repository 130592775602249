import {ReactComponent as CloseIcon} from "../../ChatIcons/cross.svg";
import React from "react";
import {useTranslation} from "react-i18next";

export const SuccessModal = ({ onClose }) => {

    const {t} = useTranslation()

    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-label'>
                    ✅ {t('ThankYou')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {t('CharWasTweaked')} <br></br>
                    <div
                        style={{marginTop: '20px'}}
                        className="sublabel-notification"> {t('ChangesApplySoon')}
                    </div>
                </div>


                <button onClick={onClose} className="notification-button-main"
                        style={{marginTop: '25px', marginBottom: "0"}}
                >{t('Okay')}

                </button>


            </div>
        </div>
    );
};
import React, {createContext, useState, useEffect, useContext} from 'react';
import {getUserAccessToken, getUserID} from "../../App";
import i18n from "i18next";

export const ProfileContext = createContext();

export const ProfileDataProvider = ({children}) => {
    const [profileData, setProfileData] = useState({username: 'Guest'});
    const [userTokens, setUserTokens] = useState(parseInt(profileData.tokens, 10) || 0);
    const [userBonusMessages, setUserBonusMessages] = useState(parseInt(profileData.bonus_messages, 10) || 0);
    const [isDataFetched, setIsDataFetched] = useState(false);

    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('userLanguage') || i18n.language;
    });

    const languageCodeToEnglishName = {
        'en': 'English',
        'es': 'Spanish',
        'fr': 'French',
        'ru': 'Russian',
        'de': 'German',
        'it': 'Italian',
        'pl': 'Polish',
        'pt': 'Portuguese',
        'ja': 'Japanese',
        'ko': 'Korean',
        'zh': 'Chinese'
    };

    useEffect(() => {
        setUserTokens(parseInt(profileData.tokens, 10) || 0);
        setUserBonusMessages(parseInt(profileData.bonus_messages, 10) || 0);
    }, [profileData.tokens, profileData.bonus_messages]);

    useEffect(() => {
        const fetchUserData = async () => {
            const userID = await getUserID();
            if (!userID) {
                setProfileData({
                    username: 'Guest',
                    subscription_data: {
                        tier: 'FREE',
                    }
                });

                setIsDataFetched(true);
                return;
            }

            const token = await getUserAccessToken();
            try {
                await fetch('https://api.elyza.app/v1/update-profile', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify({
                        userId: userID,
                        imageBase64: "",
                        newUsername: "",
                        genderPreference: localStorage.getItem('genderPreference') ?? "Female",
                        justRegistered: true,
                        affCode: localStorage.getItem('affCode') ?? "",
                        referralCode: localStorage.getItem('referralCode') ?? "",
                        campaignCode: localStorage.getItem('campaignCode') ?? "",
                        targetTypes: JSON.parse(localStorage.getItem('type') ?? JSON.stringify([])),
                        language: languageCodeToEnglishName[language] ?? "English",
                    })
                });
            } catch (error) {
                console.log('Error fetching user data:', error);
            }

            try {
                const response = await fetch('https://api.elyza.app/v1/profile-data', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token,
                    },
                    body: JSON.stringify({userId: userID}),
                });

                if (!response.ok) {
                    console.error('Failed to fetch user data:', response);
                    if (response.status === 404) {
                        console.error('User not found, logging out...');
                        localStorage.clear();
                        window.location.href = '/';
                    }
                    setIsDataFetched(true);
                    return;
                }

                const data = await response.json();
                setProfileData(data);
                setIsDataFetched(true);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (!isDataFetched) {
            fetchUserData();
        }
        //eslint-disable-next-line
    }, [isDataFetched, language]);

    const mapLanguageToCode = (language) => {
        const languageMap = {
            'English': 'en',
            'Spanish': 'es',
            'Russian': 'ru',
            'French': 'fr',
            'Chinese': 'zh',
            'Japanese': 'ja',
            'Korean': 'ko',
            'German': 'de',
            'Italian': 'it',
            'Polish': 'pl',
            'Portuguese': 'pt',
        };
        return languageMap[language] || language.toLowerCase();
    };

    useEffect(() => {
        const changeLanguage = async () => {
            if (profileData.language) {
                const languageCode = mapLanguageToCode(profileData.language);
                if (languageCode) {
                    await i18n.changeLanguage(languageCode);
                    setLanguage(languageCode);
                    localStorage.setItem('userLanguage', languageCode);
                }
            }
        };

        changeLanguage();
    }, [profileData.language]);

    useEffect(() => {
        const handleLanguageChange = async () => {
            if (language) {
                await i18n.changeLanguage(language);
                localStorage.setItem('userLanguage', language);
            }
        };

        handleLanguageChange();
    }, [language]);

    if (!isDataFetched) return null;

    return (
        <ProfileContext.Provider value={{
            profileData,
            userTokens,
            setUserTokens,
            userBonusMessages,
            setUserBonusMessages,
            language,
            setLanguage
        }}>
            {children}
        </ProfileContext.Provider>
    );
};

export const useProfileData = () => {
    const context = useContext(ProfileContext);
    if (context === undefined) {
        throw new Error('useProfileData must be used within a ProfileDataProvider');
    }
    return context;
};
import React, {useState} from 'react';
import AnimeFElyzaChan from "../images/gender/anime/AnimeFElyzaChan.jpeg";
import AnimeMElyzaDude from "../images/gender/anime/AnimeMElyzaDude.jpeg";
import RealFElyzaChan from "../images/gender/real/RealFElyzaChan.jpeg";
import RealMElyzaDude from "../images/gender/real/RealMElyzaDude.jpeg";
import { useTranslation } from "react-i18next";
import './CharCreatorSteps.css';
import {useCharacterCreation} from "../CharacterCreationContext";

const SimpleStepOne = () => {
    const { isAnime, characterData, updateCharacterData, setVoiceSelection } = useCharacterCreation();
    const [selectedGender, setSelectedGender] = useState(characterData.gender || 'Female');
    const { t } = useTranslation();

    const genderLists = {
        realistic: {
            'Female': RealFElyzaChan,
            'Male': RealMElyzaDude,
        },
        anime: {
            'Female': AnimeFElyzaChan,
            'Male': AnimeMElyzaDude,
        }
    };

    const currentGenderList = isAnime ? genderLists.anime : genderLists.realistic;

    const handleGenderSelect = (gender) => {
        setSelectedGender(gender);
        updateCharacterData({ gender, voiceSelection: '' });
        setVoiceSelection('')
    };

    return (
        <>
            <div className="general-options-container">
                {Object.entries(currentGenderList).map(([gender, image]) => (
                    <div className="general-option-wrapper" key={gender}>
                        <div
                            className={`general-option-creator ${selectedGender === gender ? 'general-option-creator-selected' : ''}`}
                            onClick={() => handleGenderSelect(gender)}
                        >
                            <img
                                src={image}
                                alt={gender}
                                className={`general-image-creator ${selectedGender === gender ? 'selected' : ''}`}
                                style={{pointerEvents: 'none'}}
                            />
                            <span className='enforced-display-char-creator'>{t(gender)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default SimpleStepOne;
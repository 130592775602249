import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from '../../icons/cross.svg';
import { useTranslation } from "react-i18next";

const ReportDislikeButton = ({
                                 isOpen,
                                 onCancel,
                                 imageUrl,
                                 reportNegativeImage,
                                 reportFormMessage,
                                 handleShortDescriptionChange
                             }) => {
    const { t } = useTranslation();

    if (!isOpen) return null;

    const modalContent = (
        <div className="modal-backdrop" onClick={onCancel}>
            <div className="main-space-finder-modal" onClick={onCancel}>
                <div className="app-modal-content-response-settings" onClick={(e) => e.stopPropagation()}>

                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">{t('havingProblem')}</div>
                        <div className="close-modal-icon" onClick={onCancel}><CloseIcon/></div>
                    </div>

                    <div className="profile-setting-sublabel">
                        {t('noWorries')}
                    </div>

                    <div className="line-container">
                        <span className="text-line-container">{t('report')}</span>
                        <div className="line"></div>
                    </div>

                    <div className="profile-setting-sublabel">
                        {t('inputMatters')}
                    </div>

                    <div className="textbox-name-general-short">
                        <label htmlFor="shortDescription" className="label-sign-up-h">
                            {t('writeComplaintHere')}
                        </label>

                        <textarea
                            id="shortDescription"
                            placeholder={t('complaintPlaceholder')}
                            rows="3"
                            maxLength="250"
                            value={reportFormMessage}
                            onChange={handleShortDescriptionChange}
                        />
                    </div>

                    <div className="line" style={{marginTop: '15px'}}></div>

                    <div className="parent">
                        <button className="save-settings-button" style={{width: "100%", height: '50px'}}
                                onClick={() => {
                                    reportNegativeImage(imageUrl, reportFormMessage);
                                }}>{t('submit')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {ReactDOM.createPortal(modalContent, document.body)}
        </>
    );
};

export default ReportDislikeButton;

import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from "../../../icons/cross.svg";
import {useTranslation} from "react-i18next";

const PublishSuccess = ({isOpen, onClose, characterName}) => {

    const {t} = useTranslation();

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-label'>
                        🎉 {t('woohoo')}
                        <div className="modal-close-icon-notification" onClick={() => onClose(false)}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            {t('PublishSuccess1')}
                            {characterName}
                            {t('PublishSuccess2')}
                            <br></br>
                            <br></br>
                            {t('PublishSuccess3')}
                        </div>
                    </div>

                    <button
                        className="notification-button-main"
                        onClick={onClose}
                    >
                        {t('Good')}
                    </button>
                </div>
            </div>
        ),
        document.body
    );
};

export default PublishSuccess;
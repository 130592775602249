import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import { ReactComponent as UnlockIcon } from "../MainMenuPage/icons/unlock.svg";
import { AuthModal } from "../Forms/AuthModal";
import { useTranslation } from "react-i18next";
import {useButtonTracking} from "../Analytics/UserAnalyticsContext";

const SignUpToUseGeneral = ({isOpen, onClose }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const trackButtonClick = useButtonTracking();

    // Track modal display
    useEffect(() => {
        if (isOpen) {
            trackButtonClick('signup_notification_shown', {
                source: window.location.pathname,
                timestamp: Date.now()
            });
        }
    }, [isOpen, trackButtonClick]);

    const handleUnlockClick = () => {
        trackButtonClick('signup_unlock_click', {
            action: 'open_auth_modal',
            source: window.location.pathname,
            interaction_time: Date.now()
        });
        setShowModal(true);
    };

    // const handleClose = () => {
    //     trackButtonClick('signup_modal_closed', {
    //         action: 'dismiss',
    //         source: window.location.pathname,
    //         time_spent: Date.now() - openTime
    //     });
    //     onClose();
    // };

    // const openTime = useEffect(() => {
    //     if (isOpen) {
    //         const openTimestamp = Date.now();
    //         return () => {
    //             if (isOpen) {
    //                 trackButtonClick('signup_modal_engagement', {
    //                     duration_ms: Date.now() - openTimestamp,
    //                     action: 'auto_close',
    //                     source: window.location.pathname
    //                 });
    //             }
    //         };
    //     }
    // }, [isOpen, trackButtonClick]);

    if (!isOpen) return null;


    return ReactDOM.createPortal(
        (<div
            className="modal-backdrop"
            style={{
                zIndex: '9999',
                alignItems: 'center'
            }}
        >
            <div
                className="modal-content-notification"
                style={{
                    zIndex: '10',
                    borderRadius: '16px',
                }}
            >
                <div className='notification-label'>
                    {t('signUpToSeeMore')}
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        {t('getUnlimitedMessages')}
                        <span className="colored-purple-text"> {t('unlimitedMessagesSmall')} </span>
                        {t('andCreateCompanion')}
                        <br /><br />
                        {t('otherFeatures')}
                    </div>
                </div>

                <button
                    onClick={handleUnlockClick}
                    className="notification-button-main"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}
                >
                    <UnlockIcon />
                    {t('unlockNow')}
                </button>
            </div>
            {showModal && <AuthModal onClose={() => setShowModal(false)} />}
        </div>),
        document.body
    );
};

export default SignUpToUseGeneral;
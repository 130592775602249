import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import './CharCreatorSteps.css';
import {useCharacterCreation} from "../CharacterCreationContext";

const SimpleStepFive = () => {
    const {characterData, updateCharacterData } = useCharacterCreation();
    const [selectedHairColor, setSelectedHairColor] = useState(characterData.hairColor || '');
    const { t } = useTranslation();

    const colors = [
        { name: 'Blonde', color: '#d9b380' },  // Pale golden blonde
        { name: 'Black', color: '#2C2C2C' },   // Jet black with slight natural highlights
        { name: 'Brown', color: '#6d4730' },   // Medium brown
        { name: 'Gray', color: '#D3D3D3' },    // Silver gray
        { name: 'Red', color: '#B7410E' },     // Auburn red
        { name: 'Purple', color: '#7c4fd0' },  // Deep plum purple (often seen in dyed hair)
        { name: 'Blue', color: '#7584b3' },    // Steel blue (dyed hair)
        { name: 'Pink', color: '#e27589' },    // Hot pink (dyed hair)
    ];

    const handleColorSelect = (colorName) => {
        setSelectedHairColor(colorName);
        updateCharacterData({ hairColor: colorName });
    };

    return (
        <div className="hair-color-selector">

            <div className="general-multiple-options-container"
            style={{gap: '15px'}}
            >
                <div className="top-row-color">
                    {colors.slice(0, 4).map((color, index) => (
                        <div key={`${color.name}-${index}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedHairColor === color.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleColorSelect(color.name)}
                            >
                                <div
                                    className="color-box"
                                    style={{backgroundColor: color.color}}
                                ></div>
                                <span className='enforced-display-char-creator-small'
                                style={{fontSize: '0.75em', left: '10px'}}
                                >{t(color.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="bottom-row-color">
                    {colors.slice(4).map((color, index) => (
                        <div key={`${color.name}-${index + 4}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedHairColor === color.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleColorSelect(color.name)}
                            >
                                <div
                                    className="color-box"
                                    style={{backgroundColor: color.color}}
                                ></div>
                                <span className='enforced-display-char-creator-small'
                                      style={{fontSize: '0.75em', left: '10px'}}
                                >{t(color.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SimpleStepFive;
import React from 'react';
import '../MainRewardModal.css';
import {ReactComponent as ElyzaCoin} from '../RewardIcons/elyzaCoin.svg';
import {ReactComponent as ElyzaCoinRedeem} from '../RewardIcons/elyzaCoinRedeem.svg';
import {ReactComponent as RewardMessage} from '../RewardIcons/rewardMessage.svg';
import {ReactComponent as RewardMessageRedeem} from '../RewardIcons/rewardMessageRedeem.svg';
import {ReactComponent as ElyzaCoinCrossedOut} from '../RewardIcons/rewardCoinCrossedOut.svg';
import {useProfileData} from "../../Context/ProfileDataContext";
import {getUserAccessToken} from "../../../App";
import {useTranslation} from "react-i18next";

export const RewardSection = ({
                                  generalTasks,
                                  tier,
                                  clearTaskRewards,
                                  userBonusMessages,
                                  setUserBonusMessages,
                                  userTokens,
                                  setUserTokens
                              }) => {

    const {profileData} = useProfileData();

    const isAccountFree = profileData.subscription_data.tier === "FREE";

    const {t} = useTranslation();

    const redeemReward = async (taskItem) => {
        console.log(taskItem.task_code.toUpperCase())
        clearTaskRewards(taskItem.task_code);
        const response = await fetch('https://api.elyza.app/v1/redeem-task', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({taskType: taskItem.task_code.toUpperCase()})
        });

        if (!response.ok) {
            console.error('Failed to redeem reward');
        }

        const data = await response.json();
        const rewards = data.redemptionResponse.rewardCount.split('|');

        setUserBonusMessages(userBonusMessages + parseInt(rewards[0]));
        setUserTokens(userTokens + parseInt(rewards[1]));
    }

    const isTaskComplete = (taskItem) => (taskItem.progress / taskItem.goal) >= 1;
    const isRewardAvailable = (taskItem) => taskItem.extra_data.rewards !== undefined && taskItem.extra_data.rewards.length > 0;
    const isRewardRedeemed = (taskItem) => (taskItem.progress / taskItem.goal) === 1 && taskItem.extra_data.rewards !== undefined && taskItem.extra_data.rewards.length === 0;

    return (
        <>
            {generalTasks.map((taskItem, index) => (
                <div className="reward-item-container" key={index}>
                    <div className={`reward-item-container-sub ${isRewardRedeemed(taskItem) ? 'reward-item-redeemed' : ''}`}>

                    <div
                            className={`reward-item-title ${isRewardRedeemed(taskItem) ? 'reward-item-redeemed' : ''}`}>
                            {taskItem.name} {taskItem.level}
                            <br></br>
                            {taskItem.progress || 0}/{taskItem.goal || 0}
                        </div>

                        {isTaskComplete(taskItem) && isRewardAvailable(taskItem) ? (
                            <button className="redeem-reward-button" onClick={() => redeemReward(taskItem)}>
                                {t('redeem')}
                                <div className="reward-item-reward-button">
                                    {isAccountFree ? (
                                        <>
                                            +{taskItem.reward.split('|')[0]}
                                            <RewardMessage/>
                                        </>
                                    ) : (
                                        <>
                                            +{taskItem.reward.split('|')[1]}
                                            <ElyzaCoin/>
                                        </>
                                    )}
                                </div>
                            </button>
                        ) : (
                            <div className="reward-item-reward">
                                {isAccountFree ? (
                                    <>
                                        +{taskItem.reward.split('|')[0]}
                                        {isRewardRedeemed(taskItem) ? <RewardMessageRedeem/> : <RewardMessage/>}
                                        <span className="crossed-out-reward">
                                         +{taskItem.reward.split('|')[1]}
                                            <ElyzaCoinCrossedOut/>
                                         </span>
                                    </>
                                ) : (
                                    <>
                                        +{taskItem.reward.split('|')[1]}
                                        {isRewardRedeemed(taskItem) ? <ElyzaCoinRedeem/> : <ElyzaCoin/>}
                                    </>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="progress-bar-container-reward">
                        <div
                            className="progress-bar-reward"
                            style={{width: `${(taskItem.progress / taskItem.goal) * 100}%`}}
                        ></div>
                    </div>
                </div>
            ))}
        </>
    );
}
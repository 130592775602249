import React, {useState, useRef, useEffect} from 'react';
import {useProfileData} from "../../../components/Context/ProfileDataContext";
import './ExpandableDescription.css';
import {ReactComponent as CloseIcon} from "../IconsSub/cross.svg";
import {useTranslation} from "react-i18next";

const ExpandableDescriptionDesktop = ({description, maxLength, charName}) => {
    const {profileData} = useProfileData();
    const username = profileData.username;
    const [isExpanded, setIsExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const contentRef = useRef(null);
    const modalRef = useRef(null);

    const {t} = useTranslation();

    const correctDescription = description
        .replace(/{{char}}/gi, charName)
        .replace(/{{user}}/g, username)
        .replace(/user/g, username)
        .replace(/{char}/g, charName)
        .replace(/{user}/g, username)
        .replace(/{Char}/g, charName)
        .replace(/{User}/g, username);

    useEffect(() => {
        setShowButton(correctDescription.length > maxLength);
    }, [correctDescription, maxLength]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleBackdropClick = (e) => {
        if (e.target.className === 'modal-backdrop') {
            toggleExpand();
        }
    };

    return (
        <div className="description-wrapper">
            <div className="description-container">
                <h4 className="description-content">
                    <span ref={contentRef}>
                        {correctDescription.slice(0, maxLength)}
                        {correctDescription.length > maxLength && '...'}
                    </span>
                </h4>
            </div>
            {showButton && (
                <button
                    onClick={toggleExpand}
                    className="read-more-button"
                >
                    {t('readMore')}
                </button>
            )}
            {isExpanded && (
                <>
                    <div className="modal-backdrop" onClick={handleBackdropClick}>
                        <div
                            className="modal-content-notification"
                            style={{minWidth: '480px', maxWidth: '520px'}}
                            ref={modalRef}
                        >
                            <div className='notification-label'>
                                {t('aboutMe')}
                                <div className="modal-close-icon-notification" onClick={toggleExpand}>
                                    <CloseIcon/>
                                </div>
                            </div>

                            <div className="gap-between-notification">
                                <div className='sublabel-notification'>
                                    {correctDescription}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ExpandableDescriptionDesktop;
import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import { useTranslation } from 'react-i18next';

const MicroSuccess = ({isOpen, onClose, tokens}) => {
    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop"
             style={{alignItems: 'center'}}
        >
            <div className="modal-content-notification"
                 style={{
                     borderRadius: '16px'
                 }}
            >
                <div className='notification-label'>
                    🎉 {t('woohoo')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">

                    <div className='sublabel-notification'>
                        {t('YouvePurchased')}
                        <span className="colored-purple-text"> {tokens} {t('tokens')} </span>
                    </div>

                    <div className='sublabel-notification'>
                        {t('ThankYouForUsingElyza')}
                    </div>

                </div>

                <button onClick={onClose} className="notification-button-main">{t('Okay')}</button>

            </div>
        </div>
    );
};

export default MicroSuccess;

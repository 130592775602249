import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {ReactComponent as CloseIcon} from '../../../icons/cross.svg';
import "./CustomizationModal.css";
import {useTranslation} from "react-i18next";

const EditCustomizationModal = ({isOpen, onClose, item, onSave, type}) => {
    const [customizationName, setCustomizationName] = useState('');
    const [customizationPrompt, setCustomizationPrompt] = useState('');
    const [portalRoot, setPortalRoot] = useState(null);

    const handleCustomizationNameChange = (e) => {
        setCustomizationName(e.target.value);
    };

    const handleCustomizationPromptChange = (e) => {
        setCustomizationPrompt(e.target.value);
    };

    useEffect(() => {
        if (item && typeof item === 'object') {
            setCustomizationName(item.name || "");
            setCustomizationPrompt(item.description || "");
        }
    }, [item]);

    useEffect(() => {
        setPortalRoot(document.getElementById("modal-root"));
    }, []);

    const handleSave = () => {
        if (item && typeof item === 'object') {
            onSave({...item, name: customizationName, description: customizationPrompt});
        } else {
            console.error("Item is not a valid object:", item);
        }
    };

    const {t} = useTranslation();

    if (!isOpen || !portalRoot) return null;

    const modalContent = (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-centered-container">
                <div className="app-modal-content-response-settings"
                     style={{maxWidth: "420px"}}
                     onClick={(e) => e.stopPropagation()}>
                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">{t('CustomizationSettings')}</div>
                        <div className="close-modal-icon" onClick={onClose}>
                            <CloseIcon/>
                        </div>
                    </div>
                    <div className="line-container">

                        <span className="text-line-container">
                            {type === 'personality'
                                ? `${t('PersCustLabel')}`
                                : `${t('MemCustLabel')}`
                            }
                        </span>

                        <div className="line"></div>
                    </div>

                    <div className="main-elyza-text-response-settings">{t('NameCustomization')}</div>

                    <div className="textbox-name-general"
                         style={{marginTop: '5px', marginBottom: '15px', width: '100%'}}>
                        <input
                            type="text"
                            placeholder={t('CharacterNamePlaceholder')}
                            value={customizationName}
                            onChange={handleCustomizationNameChange}
                            maxLength="25"
                        />
                    </div>
                    <div className="main-elyza-text-response-settings">
                        {type === 'personalities'
                            ? `${t('EditCustomPersonality')}`
                            : `${t('EditCustomMemory')}`
                        }
                    </div>

                        <div className="textbox-name-general-short"
                             style={{marginTop: '5px', marginBottom: '25px'}}
                        >
                        <textarea
                            id="scenarioDescription"
                            value={customizationPrompt}
                            onChange={handleCustomizationPromptChange}
                            rows="4"
                            maxLength="500"
                            cols="50"
                            placeholder={t('EditCustomizationPlaceholder')}
                        />
                        </div>

                        <div className="footer-button-container">
                            <button className="need-help-button"
                                    style={{backgroundColor: '#757575'}}
                                    onClick={onClose}>
                                {t('Cancel')}
                            </button>
                            <button className="save-settings-button" onClick={handleSave}>
                                {t('save')}
                            </button>
                        </div>

                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(modalContent, portalRoot);
};

export default EditCustomizationModal;
import React from 'react';
import {ReactComponent as ElyzaIcon} from './icons/ElyzaWhiteLogo.svg';
import {ReactComponent as DiscordIcon} from './icons/discord.svg';

import './Footer.css';
import {useNavigate} from "react-router-dom";
// import { Link } from 'react-router-dom';  // import the Link component from react-router-dom

const Footer = () => {
    //TODO make sure discord functions
    const discordLink = 'https://discord.gg/V4YUYRBc5M';
    const navigate = useNavigate()
    // Handle the click event to open the link in a new tab
    const openLink = (link) => {
        window.open(link, '_blank');
    };

    return (<div className="footer">
        <div className="footer-logo">
            <ElyzaIcon/>
        </div>

        <div className="footer-information">
            <span className="footer-subtitle"> Questions? support@elyza.app </span>
            <span className="footer-subtitle"> Or contact us below
                <div className="DiscordIconFooter" onClick={() => openLink(discordLink)}>
                    <DiscordIcon/>
                </div>
            </span>
            <button
                    style={{color: "white", cursor: "pointer", padding: '0'}}
                    onClick={() => navigate("/legal/privacy-policy")} >
                Terms & Conditions
            </button>
            <button
                    style={{color: "white", cursor: "pointer", padding: '0'}}
                    onClick={() => navigate("/legal/privacy-policy")} >
                Privacy Policy
            </button>
        </div>


    </div>);
};

export default Footer;

import React, {useEffect, useState} from "react";
import { generateAndSaveProfileImage } from "../AdvancedCreationFunctions";
import { ReactComponent as GoForwardIcon } from "../icons/forwardIcon.svg";
import { useTranslation } from "react-i18next";
import CreateCharPleaseLogin from "../../../components/Modals/CreateCharPleaseLogin";

const handleGenerateAndSave = async (params) => {
    const {setIsProfileImageGenerating, isLoggedIn, setCreateCharLoginModal, advancedCharData, setTokenErrorModal } = params;

    if (!isLoggedIn) {
        setCreateCharLoginModal(true);
        return;
    }

    //TODO: TEST IMAGE GENERATION AND ADD GOOD LOADING IF NOT ALREADY PRESENT :)

    setIsProfileImageGenerating(true);

    try {
        const { useAiGeneration, imageFile, prompt, setGeneratedProfileImage, showPrompt } = params;
        const { appearance, scenario, description, loraAirUrl, loraTrainedWords, mainStyle } = advancedCharData;

        const backgroundInformation = {
            appearance: appearance || '',
            scenario: scenario || '',
            description: description || '',
            style: mainStyle ?? 'Anime',
            display_name: advancedCharData.display_name || 'Character'
        };

        const generationParams = {
            createPromptWAI: showPrompt,
            isAiGenerate: useAiGeneration,
            baseLora: loraAirUrl,
            triggerWords: loraTrainedWords,
            backgroundInformation,
            base64: imageFile,
            userMadePrompt: prompt,
        };

        const result = await generateAndSaveProfileImage(generationParams);

        if (result.errorCode === 50) {
            setTokenErrorModal(true);
            return;
        }

        setGeneratedProfileImage(result.url);
        return result.url;

    } catch (error) {
        console.error("Error generating profile image:", error);
        // Keep other error handling for different types of errors
        throw error;
    } finally {
        setIsProfileImageGenerating(false);
    }
};

const NavigationButton = ({
                              isLastStep,
                              handleNext,
                              handleFinish,
                              useAiGeneration,
                              showPrompt,
                              imageFile,
                              prompt,
                              advancedCharData,
                              setGeneratedProfileImage,
                              isProfileImageGenerating,
                              setIsProfileImageGenerating,
                              isLoggedIn,
                              tokenErrorModal, setTokenErrorModal
                          }) => {
    const { t } = useTranslation();
    const [createCharLoginModal, setCreateCharLoginModal] = useState(false);

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        const updateDisabledState = () => {
            if (isProfileImageGenerating === true) {
                setIsDisabled(true);
            } else if (showPrompt === true) {
                setIsDisabled(false);
            } else if (prompt === '' && showPrompt === false) {
                setIsDisabled(true);
            } else if (prompt.length > 0 && showPrompt === false) {
                setIsDisabled(false);
            }
            else {
                setIsDisabled(false);
            }
        };

        updateDisabledState();
    }, [isProfileImageGenerating, showPrompt, prompt, isLastStep, imageFile]);

    if (useAiGeneration) {
        return (
            <>
                <button
                    onClick={() => handleGenerateAndSave({
                        useAiGeneration,
                        imageFile,
                        prompt,
                        advancedCharData,
                        setGeneratedProfileImage,
                        isProfileImageGenerating,
                        setIsProfileImageGenerating,
                        isLoggedIn,
                        setCreateCharLoginModal,
                        showPrompt,
                        tokenErrorModal, setTokenErrorModal
                    })}
                    className="nav-button next-button"
                    disabled={isDisabled}
                >
                    <span>{t('Generate')}</span>
                    <GoForwardIcon />
                </button>
                <CreateCharPleaseLogin
                    isOpen={createCharLoginModal}
                    characterName={advancedCharData.charName}
                    onClose={() => setCreateCharLoginModal(false)}
                />
            </>
        );
    } else if (isLastStep) {
        if (imageFile && imageFile.length > 0) {
            return (
                <button
                    onClick={handleFinish}
                    className="nav-button next-button"
                >
                    <span>{t('Finish')}</span>
                    <GoForwardIcon />
                </button>
            );
        } else {
            return (
                <>
                    <button
                        className="nav-button next-button"
                        onClick={() => handleGenerateAndSave({
                            useAiGeneration,
                            imageFile,
                            prompt,
                            advancedCharData,
                            setGeneratedProfileImage,
                            isProfileImageGenerating,
                            setIsProfileImageGenerating,
                            isLoggedIn,
                            setCreateCharLoginModal,
                            tokenErrorModal, setTokenErrorModal
                        })}
                        disabled={!imageFile}
                    >
                        <span>{t('UploadToContinue')}</span>
                        <GoForwardIcon />
                    </button>
                    <CreateCharPleaseLogin
                        isOpen={createCharLoginModal}
                        characterName={advancedCharData.charName}
                        onClose={() => setCreateCharLoginModal(false)}
                    />
                </>
            );
        }
    } else {
        return (
            <button
                onClick={handleNext}
                className="nav-button next-button"
            >
                <span>{t('Next')}</span>
                <GoForwardIcon />
            </button>
        );
    }
};

export default NavigationButton;
import React from 'react';
import './ResponseModeMenu.css';

const modeMapping = {
    'Classic': 'ElyzaChocolate',
    'Bold': 'ElyzaCookies&Cream',
    'Dialogue': 'ElyzaMint',
    'SFW': 'ElyzaVanilla'
};

const ResponseModeMenu = ({ activeMode, onModeChange, isLocked }) => {
    const modes = Object.keys(modeMapping);

    const handleModeChange = (mode) => {
        if (isLocked && mode !== 'Classic') {
            return; // Do nothing if locked and trying to change to a non-Classic mode
        }
        onModeChange(modeMapping[mode]);
    };

    return (
        <div className="menu-container">
            <div className="button-group">
                {modes.map((mode) => (
                    <button
                        key={mode}
                        className={`mode-button ${modeMapping[mode] === activeMode ? 'active' : ''} ${isLocked && mode !== 'Classic' ? 'locked' : ''}`}
                        onClick={() => handleModeChange(mode)}
                        disabled={isLocked && mode !== 'Classic'}
                    >
                        {mode} {isLocked && mode !== 'Classic' && '🔒'}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ResponseModeMenu;
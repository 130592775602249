import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from '../../icons/cross.svg';
import {useTranslation} from "react-i18next";
import './MainRewardModal.css';
import {ReactComponent as ElyzaCoin} from './RewardIcons/elyzaCoin.svg';
import {ReactComponent as RewardMessage} from './RewardIcons/rewardMessage.svg';
import {RewardSection} from "./SubSections/RewardSection";
import {SpecialSection} from "./SubSections/SpecialSection";
import {getUserAccessToken} from "../../App";
import {useProfileData} from "../Context/ProfileDataContext";
import {useNavigate} from "react-router-dom";
import TaskList from "./RewardScreenFunctions";

const MainRewardModal = ({isOpen, onClose}) => {
    const {userTokens, setUserTokens, userBonusMessages, setUserBonusMessages} = useProfileData();

    const [userTasks, setUserTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    //TODO: bool for free tier
    const [tier, setTier] = useState('FREE');
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {decodeTasks} = TaskList();

    const handleGetMoreTokens = () => {
        navigate('/addons');
        onClose();
    }

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('https://api.elyza.app/v1/fetch-user-task', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken()
                    },
                });

                if (!response.ok) {
                    throw new Error(t('fetchError'));
                }

                const data = await response.json();

                const decodedTasks = decodeTasks(data.tasks);

                setUserTasks(decodedTasks);
                setTier(data.tier);
                setIsLoading(false);
            } catch (error) {
                console.error(t('fetchError'), error);
                setError(t('fetchError'));
                setIsLoading(false);
            }
        };

        if (isOpen) {
            fetchUserData();
        }
        //eslint-disable-next-line
    }, [isOpen]);

    const generalTasks = userTasks.filter(task => !task.isSpecial);
    const specialTasks = userTasks.filter(task => task.isSpecial);

    const clearTaskRewards = async (taskCode) => {
        const updatedTasks = userTasks.map(task => {
            if (task.task_code === taskCode) {
                return {
                    ...task,
                    extra_data: {
                        ...task.extra_data,
                        rewards: [] // Clear the rewards
                    }
                };
            }
            return task;
        });
        setUserTasks(updatedTasks);
    }

    if (!isOpen) return null;

    const mainRewardModal = (
        <div className="main-space-finder-modal"
             // style={{backgroundColor: window.innerWidth < 680 ? '#131313' : ''}}
        >
            <div className="app-modal-content-response-settings" onClick={(e) => e.stopPropagation()}>
                <div className="main-upper-text-row">
                    <div className="profile-setting-label">{t('getTokensHere')}</div>
                    <div className="close-modal-icon" onClick={onClose}><CloseIcon/></div>
                </div>

                <div className="main-elyza-text-response-settings">
                    {t('taskReferCollect')}
                </div>

                {isLoading ? (
                    <div className="reward-screen-loading">
                        <div className="reward-loader-label"></div>
                        <div className="token-counter-loader"></div>
                        {[...Array(3)].map((_, index) => (
                            <div
                                key={index}
                                className="reward-loading-item"
                            />
                        ))}
                        <div className="reward-loader-label"></div>
                        {[...Array(2)].map((_, index) => (
                            <div
                                key={index}
                                className="reward-loading-item"
                                style={{height: '65px'}}
                            />
                        ))}
                    </div>
                ) : error ? (
                    <div>{t('error')}: {error}</div>
                ) : (
                    <>
                        <div className="line-container">
                            <span className="text-line-container">{t('balance')}</span>
                            <div className="line"></div>
                        </div>

                        <div className="token-counter-section">
                            <div className="token-counter-reward">
                                {/*TODO: Vlad determine if free users should see tokens or nah here to tempt them, what about on profile menu?*/}
                                {/*TODO: Add those message counters for FREE users in chat settings menu along with proper pricing for msgs*/}
                                {tier === 'FREE' ? <>
                                    {userBonusMessages}
                                    <RewardMessage/>
                                </> : <>
                                    {userTokens}
                                    <ElyzaCoin/>
                                </>}
                            </div>

                            <button className="get-more-tokens-button" onClick={handleGetMoreTokens}>
                                {t('getMoreTokens')}
                            </button>
                        </div>

                        <div className="line-container">
                            <span className="text-line-container">{t('rewards')}</span>
                            <div className="line"></div>
                        </div>

                        <div className="reward-section">
                            <RewardSection
                                generalTasks={generalTasks}
                                tier={tier}
                                clearTaskRewards={clearTaskRewards}
                                userTokens={userTokens}
                                setUserTokens={setUserTokens}
                                userBonusMessages={userBonusMessages}
                                setUserBonusMessages={setUserBonusMessages}
                            />
                        </div>

                        <div className="line-container">
                            <span className="text-line-container">{t('special')}</span>
                            <div className="line"></div>
                        </div>

                        <div className="reward-section">
                            <SpecialSection
                                specialTasks={specialTasks}
                                tier={tier}
                                clearTaskRewards={clearTaskRewards}
                                userTokens={userTokens}
                                setUserTokens={setUserTokens}
                                userBonusMessages={userBonusMessages}
                                setUserBonusMessages={setUserBonusMessages}
                            />
                        </div>

                        <div className="a-straight-line-3" style={{marginTop: '10px', marginBottom: '10px'}}></div>

                        <button className="save-settings-button" onClick={onClose}
                                style={{width: '100%'}}>
                            {t('save')}
                        </button>
                    </>
                )}
            </div>
        </div>
    );

    return ReactDOM.createPortal(mainRewardModal, document.body);
};

export default MainRewardModal;

import React, {useState, useEffect, useRef} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import './MobileSlideMenu.css';
import {ReactComponent as HomeIcon} from '../icons/HomeIcon.svg';
import {ReactComponent as ElyzaCoin} from '../../RewardScreen/RewardIcons/elyzaCoin.svg';
import {ReactComponent as ArrowRight} from './icons/arrow.svg';
import {ReactComponent as ProfileIcon} from './icons/profileIcon.svg';
import {ReactComponent as ImageIcon} from './icons/generateImage.svg';
import {ReactComponent as SignOutIcon} from './icons/signOutIcon.svg';
import {ReactComponent as SupportIcon} from './icons/supportIcon.svg';
import {ReactComponent as ChatIcon} from '../../MainMenuPage/icons/ChatIcon.svg';

import {ReactComponent as HiddenGemIcon} from './icons/HiddenGemIcon.svg';
// import {ReactComponent as DiscordIcon} from './icons/discordIcon.svg';
import {useTranslation} from "react-i18next";
import {useProfileData} from "../../Context/ProfileDataContext";
import {useButtonTracking} from "../../Analytics/UserAnalyticsContext";
import {supabase} from "../../../App";
import {useUserSettings} from "../../UserSettings/UserSettingContext";

const MobileSlideMenu = ({isOpen, onClose, isLoggedIn, setIsAuthModalOpen}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {profileData, userTokens} = useProfileData();
    const trackButtonClick = useButtonTracking();
    const {toggleProfileEditModal, toggleSupportModal} = useUserSettings();
    const menuRef = useRef(null);
    const [isAnimatingOut, setIsAnimatingOut] = useState(false);

    const handleItemClick = (item) => {
        if (item.requiresLogin && !isLoggedIn) {
            setIsAuthModalOpen(true);
        } else {
            item.action();
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                handleClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.body.style.overflow = 'unset';
        };
        //eslint-disable-next-line
    }, [isOpen]);

    const handleClose = () => {
        setIsAnimatingOut(true);
        setTimeout(() => {
            setIsAnimatingOut(false);
            onClose();
        }, 300);
    };

    const handleUsernameClick = (tagName) => {
        trackButtonClick('profile_menu_username_click', {
            tagname: tagName,
            source: 'mobile_slide_menu'
        });
        handleClose();
        navigate(`/user/${tagName}`);
    };

    const handleSignOut = async () => {
        const {error} = await supabase.auth.signOut();
        if (error) {
            console.error('Error signing out:', error.message);
        } else {
            localStorage.clear();
            navigate('/');
            window.location.reload();
        }
    };

    const handleTokensClick = () => {
        trackButtonClick('profile_menu_tokens_click', {
            current_tokens: userTokens,
            source: 'mobile_slide_menu'
        });
    };

    const isPremiumShiny = profileData.subscription_data.tier === 'FREE'

    const menuItems = [
        {
            icon: <HomeIcon/>,
            label: t('Home'),
            path: '/',
            action: () => {
                handleClose();
                navigate('/');
            }
        },
        {
            icon: <ChatIcon/>,
            label: t('Chat'),
            path: '/chat',
            action: () => {
                handleClose();
                navigate('/chat');
            }
        },
        {
            icon: <ProfileIcon/>,
            label: t('YourProfile'),
            action: () => {
                trackButtonClick('profile_menu_option_click', {
                    option: 'profile_edit',
                    source: 'mobile_slide_menu'
                });
                toggleProfileEditModal();
                handleClose();
            },
            requiresLogin: true
        },

        {
            icon: <ImageIcon/>,
            label: t('GenerateImage'),
            action: () => {
                trackButtonClick('profile_menu_option_click', {
                    option: 'generate_image',
                    source: 'mobile_slide_menu'
                });
                handleClose();
                navigate('/generate');
            }
        },
        {
            icon: <HiddenGemIcon/>,
            label: t('upgrade'),
            path: '/pricing',
            action: () => {
                handleClose();
                navigate('/pricing');
            },
            isPremium: isPremiumShiny
        },
        {
            icon: <SupportIcon/>,
            label: t('Support'),
            action: () => {
                trackButtonClick('profile_menu_option_click', {
                    option: 'support',
                    source: 'mobile_slide_menu'
                });
                toggleSupportModal();
                handleClose();
            },
            requiresLogin: true
        },
        {
            icon: <SignOutIcon/>,
            label: t('SignOut'),
            action: handleSignOut,
            requiresLogin: true
        },
    ];

    if (!isOpen && !isAnimatingOut) return null;

    return (
        <>

        <div className="mobile-menu-overlay" onClick={onClose}>
            <div
                className={`mobile-menu-container ${isOpen && !isAnimatingOut ? 'open' : 'pop-out'}`}
                onClick={e => e.stopPropagation()}
                ref={menuRef}
            >
                {isLoggedIn && (
                    <>
                        <div className="control-menu-header"
                             style={{
                                 paddingLeft: '10px',
                                 paddingRight: '10px',
                                 paddingTop: '10px',
                                 paddingBottom: '5px'
                             }}
                             onClick={() => handleUsernameClick(profileData?.tagname)}
                        >
                            <div className="user-avatar-profile-control-menu">
                                <img
                                    src={profileData?.profile}
                                    alt=''
                                    className='user-avatar-profile-control-menu'
                                />
                            </div>
                            <div className="profile-control-menu-username">
                                @{profileData?.tagname}
                            </div>
                            <div className="profile-control-menu-arrow">
                                <ArrowRight/>
                            </div>
                        </div>

                        <div className="line-container"
                             style={{paddingLeft: '10px', paddingRight: '10px'}}
                        >
                            <span className="text-line-container">{t('Balance')}</span>
                            <div className="line"></div>
                        </div>

                        <div className="balance-counter-main"
                             style={{
                                 paddingLeft: '20px',
                                 paddingRight: '20px',
                                 paddingTop: '10px',
                                 paddingBottom: '10px'
                             }}
                        >
                            <div className="profile-count-counter"
                                 style={{color: '#fff'}}
                            >
                                {userTokens}
                                <ElyzaCoin/>
                            </div>
                            <Link
                                to="/addons"
                                className="get-more-tokens-sign"
                                onClick={handleTokensClick}
                            >
                                {t('GetMoreTokens')}
                            </Link>
                        </div>

                        <div className="line-container"
                             style={{paddingLeft: '10px', paddingRight: '10px'}}
                        >
                            <span className="text-line-container">{t('Options')}</span>
                            <div className="line"></div>
                        </div>
                    </>
                )}

                <div className="mobile-menu-items">
                    {menuItems.map((item, index) => (
                        <div
                            key={index}
                            className={`mobile-menu-item ${location.pathname === item.path ? 'active' : ''}`}
                            onClick={() => handleItemClick(item)}
                        >
                            <div className={item.isPremium ? "menu-icon-mobile-premium" : "menu-icon-mobile"}>
                                {item.icon}
                            </div>
                            <span className={item.isPremium ? "menu-label-premium" : "menu-label"}>
                                    {item.label}
                                </span>
                        </div>
                    ))}
                </div>
                    {/*<div className="join-discord-button">*/}
                    {/*    <DiscordIcon/>*/}
                    {/*</div>*/}
            </div>
        </div>
        </>
    );
};

export default MobileSlideMenu;
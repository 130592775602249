import React from 'react';
import ReactDOM from 'react-dom';
import '../ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../MainMenuPage/icons/cross.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const RanOutOfTokens = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleUpgradeClick = () => {
        onClose();
        navigate('/pricing');
    };

    if (!isOpen) return null;



    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop"
                 style={{alignItems: 'center'}}
            >
                <div className="modal-content-notification"
                     style={{
                         borderRadius: '16px'
                     }}
                >
                    <div className='notification-label'>
                        ✋ {t('WaitASecond')}
                        <div className="modal-close-icon-notification" onClick={onClose}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className="sublabel-notification">
                            <p>
                                {t('notEnoughTokens')}
                                <span className="colored-purple-text font-bold">  </span>
                            </p>
                            <p>
                                {t('getMoreTokensLong')}
                                <span className="colored-purple-text font-bold"> {t('premiumUser')} </span>.
                            </p>
                        </div>
                    </div>

                    <button onClick={handleUpgradeClick}
                            className="notification-button-main">
                        {t('upgradeNow')}</button>
                </div>
            </div>
        ),
        document.body
    );
};

export default RanOutOfTokens;
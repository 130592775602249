import React from 'react';
import './GeneralLegal.css';
import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import { useNavigate } from 'react-router-dom';

const documents = [
    { id: 1, name: 'Terms of Use', url: '/legal/terms-of-use' },
    { id: 2, name: 'Privacy Policy', url: '/legal/privacy-policy' },
    { id: 3, name: 'Content Removal', url: '/legal/content' },
    { id: 4, name: 'DMCA', url: '/legal/dmca' },
    { id: 5, name: '18 U.S.C. § 2257', url: '/legal/other' },
    { id: 6, name: 'Public Content Policy', url: '/legal/public-content' },
];

const LegalNavigation = () => {
    const navigate = useNavigate();

    const handleDocumentClick = (url) => {
        navigate(url);
    }

    return (
        <div className="MainNewPage">
            <HeaderMainMenu />
            <div className="LegalApp">
                <header className="LegalHeader">
                    <h1>Legal Information</h1>
                    <div className="DocumentNav">
                        {documents.map((doc) => (
                            <button
                                key={doc.id}
                                onClick={() => handleDocumentClick(doc.url)}
                                className="DocButton"
                            >
                                {doc.name}
                            </button>
                        ))}
                    </div>
                    <div className="DocumentDisplay">
                    </div>
                </header>
            </div>
        </div>
    );
}

export default LegalNavigation;

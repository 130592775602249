import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const TagItem = ({ tag, selected, onTagClick }) => {
    const [isActive, setIsActive] = useState(false);

    const {t} = useTranslation();

    const handleMouseDown = () => {
        if (!selected) {
            setIsActive(true);
        }
    };

    const handleMouseUp = () => {
        setIsActive(false);
    };

    const handleMouseLeave = () => {
        setIsActive(false);
    };

    return (
        <div
            className={`tag-item ${selected ? 'selected' : ''} ${isActive && !selected ? 'active' : ''}`}
            onClick={() => onTagClick(tag)}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
        >
            {t(tag)}
        </div>
    );
};

const TagList = ({ tags, selectedTag, onSelectTag }) => {
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const tagListRef = useRef(null);
    const requestRef = useRef();
    const [isScrolling, setIsScrolling] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const animateScroll = (direction) => {
        if (tagListRef.current) {
            const scrollAmount = direction === 'left' ? -5 : 5;
            tagListRef.current.scrollLeft += scrollAmount;
            requestRef.current = requestAnimationFrame(() => animateScroll(direction));
        }
    };

    const startScrolling = (direction) => {
        if (!isScrolling) {
            setIsScrolling(true);
            animateScroll(direction);
        }
    };

    const stopScrolling = () => {
        if (isScrolling) {
            setIsScrolling(false);
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        }
    };

    const handleMouseLeave = () => {
        stopScrolling();
    };

    const handleMouseMove = (e) => {
        const tagListContainer = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const hoverMargin = 30;

        if (mouseX < tagListContainer.left || mouseX > tagListContainer.right || mouseY < tagListContainer.top || mouseY > tagListContainer.bottom) {
            stopScrolling();
            return;
        }

        if (mouseX - tagListContainer.left < hoverMargin) {
            startScrolling('left');
        } else if (tagListContainer.right - mouseX < hoverMargin) {
            startScrolling('right');
        } else {
            stopScrolling();
        }
    };

    useEffect(() => {
        return () => stopScrolling();
        //eslint-disable-next-line
    }, []);

    const checkScroll = () => {
        const node = tagListRef.current;
        if (node) {
            const atStart = node.scrollLeft === 0;
            const atEnd = node.scrollLeft + node.offsetWidth >= node.scrollWidth - 1;

            setCanScrollLeft(node.scrollLeft > 0);
            setCanScrollRight(!atEnd);

            if (atStart) {
                node.classList.add('no-blur-left');
            } else {
                node.classList.remove('no-blur-left');
            }

            if (atEnd) {
                node.classList.add('no-blur-right');
            } else {
                node.classList.remove('no-blur-right');
            }
        }
    };

    useEffect(() => {
        const node = tagListRef.current;
        if (node) {
            node.addEventListener('scroll', checkScroll);

            // Use ResizeObserver to detect changes in content size
            const resizeObserver = new ResizeObserver(() => {
                checkScroll();
                if (!isLoaded) setIsLoaded(true);
            });
            resizeObserver.observe(node);

            // Initial check after a short delay
            setTimeout(checkScroll, 100);

            return () => {
                node.removeEventListener('scroll', checkScroll);
                resizeObserver.disconnect();
            };
        }
    }, [isLoaded]);

    return (
        <div
            className="tag-list-container-character-card"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
        >
            <div className={`tag-list ${canScrollLeft ? 'no-blur-left' : ''} ${canScrollRight ? 'no-blur-right' : ''}`}
            style={{justifyContent: window.innerWidth < 780 ? 'center' : 'flex-start'}}
            >
                <div className="tag-list-gradient-character-card" ref={tagListRef}>
                    {tags.map((tag, index) => (
                        <TagItem
                            key={tag + index}
                            tag={tag}
                            selected={selectedTag === tag}
                            onTagClick={onSelectTag}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const TagSystemCharacterCard = ({ tagList }) => {
    const navigate = useNavigate();

    const handleTagClick = (tag) => {
        if(tagList.includes(tag)) {
            navigate(`/?tag=${encodeURIComponent(tag)}`);
        }
    };

    return (
        <TagList
            tags={tagList}
            selectedTag={''}
            onSelectTag={handleTagClick}
        />
    );
};

export default TagSystemCharacterCard;
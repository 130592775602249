import React from 'react';
import {ReactComponent as CloseIcon} from "./ProfileIcons/Close.svg"

const EmailChangePopup = ({handleClosePopup, newEmail, handleNewEmailChange, emailChangeStatus, updateEmail}) => {
    return (

        <div className="modal-backdrop" onClick={handleClosePopup}>
            <div className="modal-content-notification" style={{padding: '20px'}}
                 onClick={(e) => e.stopPropagation()}>
                <div className="notification-label" style={{paddingBottom: '5px'}}>Edit Email
                    <div className="modal-close-icon-notification" onClick={handleClosePopup}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification' style={{paddingBottom: '10px'}}>
                    You will have to verify your new email address.
                </div>

                <input
                    type="email"
                    placeholder="New Email"
                    className="new-name-input"
                    value={newEmail}
                    onChange={handleNewEmailChange}
                />

                <div className='a-straight-line'></div>
                <button className="notification-button-main" style={{margin: 0}} onClick={updateEmail}>
                    Send
                </button>

                {emailChangeStatus === 'success' && (
                    <div className="profile-success-message">Email changed successfully!</div>)}
                {emailChangeStatus === 'error' && (
                    <div className="profile-error-message">Failed to change email. Please try
                        again.</div>)}
            </div>
        </div>

    );
}

export default EmailChangePopup;
import React from 'react';
import Slider from "react-slick";
import {ReactComponent as FireIcon} from "../../../icons/FireIcon.svg";
import {useTranslation} from 'react-i18next';

const PricingToggleSlider = ({
                                 yearlyPricing,
                                 setYearlyPricing,
                                 selectedPlan
                             }) => {
    const {t} = useTranslation();

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        // prevArrow: <BackwardArrow/>,
        // nextArrow: <ForwardArrow/>,
        arrows: false,
    };

    return (
        <Slider {...settings}>
            <div className="pricing-container">
                <div
                    className={yearlyPricing ? "new-pricing-btn new-pricing-btn-active" : "new-pricing-btn"}
                    onClick={() => setYearlyPricing(true)}
                >
                    <div className="discount-amount-pricing">
                        {selectedPlan ? selectedPlan.annual.discountLabel : "50% Off!"}
                    </div>
                    <div className="most-popular-pin">
                        <FireIcon/>
                        {t('POPULAR')}
                    </div>
                    <div className="pricing-title-text">
                        {t('Annually')}
                    </div>
                    <div className="pricing-container-general">
                        <div className="pricing-cost-text">
                            {selectedPlan ? selectedPlan.annual.price : "$12.99"}
                        </div>
                        <div className="pricing-cost-text-main">
                            <div className="pricing-cost-text">
                <span className="crossed-out">
                  {selectedPlan ? selectedPlan.annual.originalPrice : "$24.99"}
                </span>
                                <span>{t('PerMonth')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="pricing-subtitle-text">
                        {t('CancelAnytime')}
                    </div>
                </div>
            </div>

            <div className="pricing-container">
                <div
                    className={!yearlyPricing ? "new-pricing-btn new-pricing-btn-active" : "new-pricing-btn"}
                    onClick={() => setYearlyPricing(false)}
                >
                    <div className="discount-amount-pricing">
                        {selectedPlan ? selectedPlan.monthly.discountLabel : "40% Off!"}
                    </div>
                    <div className="pricing-title-text">
                        {t('Monthly')}
                    </div>
                    <div className="pricing-container-general">
                        <div className="pricing-cost-text">
                            {selectedPlan ? selectedPlan.monthly.price : "$14.99"}
                        </div>
                        <div className="pricing-cost-text-main">
                            <div className="pricing-cost-text">
                <span className="crossed-out">
                  {selectedPlan ? selectedPlan.monthly.originalPrice : "$24.99"}
                </span>
                                <span>{t('PerMonth')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="pricing-subtitle-text">
                        {t('CancelAnytime')}
                    </div>
                </div>
            </div>
        </Slider>
    );
};

export default PricingToggleSlider;
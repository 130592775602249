import React from 'react';
import ReactDOM from 'react-dom';
import '../ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../MainMenuPage/icons/cross.svg";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

const UpgradeModal = ({ isOpen, onClose, children }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleUpgrade = () => {
        navigate('/pricing')
        onClose();
    }

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop"
                 style={{alignItems: 'center'}}
            >
                <div className="modal-content-notification"
                     style={{
                         borderRadius: '16px'
                     }}
                >
                    <div className='notification-label'>
                        💕 {t('wantMore')}
                        <div className="modal-close-icon-notification" onClick={onClose}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            {t('premiumUsersGet')}
                            <span className="colored-purple-text">{t('selfiesAudios')}</span>
                            {t('andUpTo')}
                            <span className="colored-purple-text">100 {t('images')}</span>
                            {t('everyMonth')}
                            <br></br>
                            <br></br>
                            {t('otherFeaturesInterested')}
                        </div>
                    </div>

                    <button onClick={() => {handleUpgrade()}} className="notification-button-main">
                        {t('upgrade')}
                    </button>
                </div>
            </div>
        ),
        document.body
    );
};

export default UpgradeModal;

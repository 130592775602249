import React, { useState } from 'react';
import brownEyes from "../images/eyeColor/brownEyes.png";
import hazelEyes from "../images/eyeColor/hazelEyes.png";
import grayEyes from "../images/eyeColor/grayEyes.png";
import greenEyes from "../images/eyeColor/greenEyes.png";
import blueEyes from "../images/eyeColor/blueEyes.png";
import { useTranslation } from "react-i18next";
import './CharCreatorSteps.css';
import {useCharacterCreation} from "../CharacterCreationContext";

const SimpleStepFour = () => {
    const { characterData, updateCharacterData } = useCharacterCreation();
    const [selectedEyeColor, setSelectedEyeColor] = useState(characterData.eyeColor || '');

    const { t } = useTranslation();

    const styles = [
        { name: 'Brown', image: brownEyes },
        { name: 'Hazel', image: hazelEyes },
        { name: 'Gray', image: grayEyes },
        { name: 'Green', image: greenEyes },
        { name: 'Blue', image: blueEyes },
    ];

    const handleStyleSelect = (eyeColor) => {
        setSelectedEyeColor(eyeColor);
        updateCharacterData({ eyeColor: eyeColor });
    };

    return (
        <div>
            <div className="general-multiple-options-container">
                <div className="top-row">
                    {styles.slice(0, 3).map((style, index) => (
                        <div key={`${style.name}-${index}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedEyeColor === style.name ? 'selected' : ''}`}
                                onClick={() => handleStyleSelect(style.name)}
                            >
                                <img
                                    src={style.image}
                                    alt={style.name}
                                    className={'general-image-creator'}
                                    style={{pointerEvents: 'none'}}
                                />
                                <span className='enforced-display-char-creator'>{t(style.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="bottom-row-uneven">
                    {styles.slice(3).map((style, index) => (
                        <div key={`${style.name}-${index + 3}`} className="general-option-wrapper-eye-color">
                            <div
                                className={`general-option-creator-multiple-items ${selectedEyeColor === style.name ? 'selected' : ''}`}
                                onClick={() => handleStyleSelect(style.name)}
                            >
                                <img
                                    src={style.image}
                                    alt={style.name}
                                    className={'general-image-creator'}
                                    style={{pointerEvents: 'none'}}
                                />
                                <span className='enforced-display-char-creator'>{t(style.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SimpleStepFour;
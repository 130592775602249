import React, { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTranslation } from "react-i18next";
import './CharCreatorSteps.css';

import animeFemaleBangs from "../images/hairStyles/female/anime/AnimeFBangs.jfif";
import animeFemaleCurly from "../images/hairStyles/female/anime/AnimeFCurly.jfif";
import animeFemaleMessybun from "../images/hairStyles/female/anime/AnimeFMessybun.jfif";
import animeFemalePonytail from "../images/hairStyles/female/anime/AnimeFPonytail.jfif";
import animeFemaleShort from "../images/hairStyles/female/anime/AnimeFShort.jfif";
import animeFemaleStraight from "../images/hairStyles/female/anime/AnimeFStraight.jfif";

import realFemaleBangs from "../images/hairStyles/female/real/RealFBangs.jfif";
import realFemaleCurly from "../images/hairStyles/female/real/RealFCurly.jfif";
import realFemaleMessybun from "../images/hairStyles/female/real/RealFMessybun.jfif";
import realFemalePonytail from "../images/hairStyles/female/real/RealFPonytail.jfif";
import realFemaleShort from "../images/hairStyles/female/real/RealFShort.jfif";
import realFemaleStraight from "../images/hairStyles/female/real/RealFStraight.jfif";

import realMaleBuzz from "../images/hairStyles/male/real/RealMaleBuzz.jfif";
import realMaleMessy from "../images/hairStyles/male/real/RealMaleMessy.jfif";
import realMaleUndercut from "../images/hairStyles/male/real/RealMaleUndercut.jfif";

import animeMaleBuzz from "../images/hairStyles/male/anime/AnimeMaleBuzz.jfif";
import animeMaleMessy from "../images/hairStyles/male/anime/AnimeMaleMessy.jfif";
import animeMaleUndercut from "../images/hairStyles/male/anime/AnimeMaleUndercut.jfif";
import {useCharacterCreation} from "../CharacterCreationContext";


const SimpleStepFive = () => {
    const { isAnime, isFemale, characterData, updateCharacterData } = useCharacterCreation();
    const [selectedHairColor, setSelectedHairColor] = useState(characterData.hairColor || '');

    const { t } = useTranslation();

    const stylesAnimeFemale = [
        { name: 'Bangs', image: animeFemaleBangs },
        { name: 'Curly', image: animeFemaleCurly },
        { name: 'Bun', image: animeFemaleMessybun },
        { name: 'Ponytail', image: animeFemalePonytail },
        { name: 'Short', image: animeFemaleShort },
        { name: 'Straight', image: animeFemaleStraight },
    ];

    const stylesRealFemale = [
        { name: 'Bangs', image: realFemaleBangs },
        { name: 'Curly', image: realFemaleCurly },
        { name: 'Bun', image: realFemaleMessybun },
        { name: 'Ponytail', image: realFemalePonytail },
        { name: 'Short', image: realFemaleShort },
        { name: 'Straight', image: realFemaleStraight },
    ];

    const stylesRealMale = [
        { name: 'Buzz', image: realMaleBuzz },
        { name: 'Messy', image: realMaleMessy },
        { name: 'Undercut', image: realMaleUndercut },
    ];

    const stylesAnimeMale = [
        { name: 'Buzz', image: animeMaleBuzz },
        { name: 'Messy', image: animeMaleMessy },
        { name: 'Undercut', image: animeMaleUndercut },
    ];

    const handleStyleSelect = (style) => {
        setSelectedHairColor(style);
        updateCharacterData({ hairStyle: style });
    };

    const getCurrentStyles = () => {
        if (isAnime) {
            return isFemale ? stylesAnimeFemale : stylesAnimeMale;
        } else {
            return isFemale ? stylesRealFemale : stylesRealMale;
        }
    };

    const currentStyles = getCurrentStyles();

    return (
        <div>
            <div className="general-multiple-options-container">
                <div className="top-row">
                    {currentStyles.slice(0, 3).map((style, index) => (
                        <div key={`${style.name}-${index}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedHairColor === style.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleStyleSelect(style.name)}
                            >
                                <img
                                    src={style.image}
                                    alt={style.name}
                                    className={`general-image-creator ${selectedHairColor === style.name ? 'selected' : ''}`}
                                    style={{pointerEvents: 'none'}}
                                />
                                <span className='enforced-display-char-creator-small'>{t(style.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="bottom-row">
                    {currentStyles.slice(3).map((style, index) => (
                        <div key={`${style.name}-${index + 3}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedHairColor === style.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleStyleSelect(style.name)}
                            >
                                <img
                                    src={style.image}
                                    alt={style.name}
                                    className={`general-image-creator ${selectedHairColor === style.name ? 'selected' : ''}`}
                                    style={{pointerEvents: 'none'}}
                                />
                                <span className='enforced-display-char-creator-small'>{t(style.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SimpleStepFive;
import React, {useState} from 'react';
import './SelectMessageControlMenu.css';
import {useChat} from "../Context/ChatContext";
import {ReactComponent as CloseIcon} from './ShareChatModal/icons/cross.svg';
import {ReactComponent as ShareIcon} from './ShareChatModal/icons/share.svg';
import {Checkbox} from "@mui/material";
import {getUserAccessToken} from "../../App";
import CreateShareMemoryModal from "../Modals/CreateShareMemoryModal";
import {useTranslation} from "react-i18next";

const SelectMessageControlMenu = () => {

    const {
        selectedMessages,
        setSelectMessageMode,
        setSelectedMessages,
        selectAll,
        setSelectAll,
        isGalleryBannerVisible,
        setReloadShareMemories
    } = useChat();

    const identityName = window.location.pathname.substring(6);
    const [openShareMemoryModal, setOpenShareMemoryModal] = useState(false)
    const [shareMemoryName, setShareMemoryName] = useState('');

    const {t} = useTranslation();

    const SharePopup = ({show}) => (
        <div className={`share-popup ${show ? 'show' : ''}`}>
            {t('selectMessagesBeforeSharing')}
        </div>
    );

    const handleStopSelecting = () => {
        setSelectMessageMode(false);
        setSelectedMessages([]);
    }

    const createShareableMemory = async () => {
        try {
            const userAccessToken = await getUserAccessToken();

            const response = await fetch('https://api.elyza.app/v1/create-chat-memory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userAccessToken
                },
                body: JSON.stringify({
                    identityName: identityName,
                    messageIds: selectedMessages,
                    memoryName: shareMemoryName,
                    privacyStatus: 'private'
                })
            });

            if (!response.ok) {
                throw new Error('Failed to create shareable memory');
            }

            return await response.json();
        } catch (error) {
            console.error('Error creating shareable memory:', error);
            throw error;
        } finally {
            setOpenShareMemoryModal(false);
            setSelectMessageMode(false);
            setSelectedMessages([]);
            setReloadShareMemories(true)
        }
    };

    const handleSubmitShareMemory = async () => {
        await createShareableMemory()
    }

    const [showSharePopup, setShowSharePopup] = useState(false);

    const handleShareClick = () => {
        if (selectedMessages.length === 0) {
            setShowSharePopup(true);
            setTimeout(() => setShowSharePopup(false), 2000);
            return;
        }
        setOpenShareMemoryModal(true);
    };

    const handleSelectAll = (event) => {
        setSelectAll(event.target.checked);
    }

    return (
        <>
            <div className='select-messages-general'
                 style={{paddingRight: isGalleryBannerVisible ? '380px' : '20px'}}
            >
                <div className='menu-item'>
                    <CloseIcon onClick={handleStopSelecting} className='icon'/>
                </div>

                <div className="menu-item message-selected-amount">
                    {selectedMessages.length} {t('messagesSelected')}
                </div>

                <div className="menu-item select-all">
                    <span>{t('selectAll')}</span>
                    <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                        sx={{
                            color: '#5324d0',
                            width: '10px',
                            height: '10px',
                            '&.Mui-checked': {
                                color: '#6f46d1',
                                backgroundColor: '#fff'
                            },
                            '&.Mui-disabled': {
                                color: '#5324d0',
                            },
                            // Style for the first message (disabled and checked)
                            '&.Mui-disabled.Mui-checked': {
                                color: '#5324d0',
                                '& .MuiSvgIcon-root': { // This targets the icon inside the checkbox
                                    color: '#383838',
                                }
                            }
                        }}
                    />
                </div>

                <div className="menu-item">
                    <ShareIcon
                        onClick={handleShareClick}
                        className={`icon ${selectedMessages.length === 0 ? 'opacity-50' : ''}`}
                    />
                </div>
                <SharePopup show={showSharePopup}/>
            </div>

            <CreateShareMemoryModal
                isOpen={openShareMemoryModal}
                onClose={() => setOpenShareMemoryModal(false)}
                shareNewMemory={handleSubmitShareMemory}
                shareMemoryName={shareMemoryName}
                setShareMemoryName={setShareMemoryName}
            />
        </>
    );
}

export default SelectMessageControlMenu;
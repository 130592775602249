import React from "react";
import Anime from '../../SimpleCharacterCreation/images/styles/animeFemale.jpg'
import Real from '../../SimpleCharacterCreation/images/styles/realFemale.jpg'
import Furry from '../../SimpleCharacterCreation/images/styles/furryFemale.jpg'

import './GenerateImagePageSubs.css'
import {useTranslation} from "react-i18next";

const modelOptions = [
    { name: 'Anime', image: Anime },
    { name: 'Realistic', image: Real },
    { name: 'Furry', image: Furry }
];

export const ModelSelect = ({ selected, onClick }) => {

    const { t } = useTranslation();

    return (
        <div className="general-multiple-options-container"
        style={{marginTop: '5px'}}
        >
            <div className="top-row">
                {modelOptions.map((option, index) => (
                    <div key={`${option.name}-${index}`} className="general-option-wrapper">
                        <div
                            className={`general-option-creator-multiple-items ${selected === option.name.toLowerCase() ? 'general-option-creator-selected' : ''}`}
                            onClick={() => onClick(option.name.toLowerCase())}
                        >
                            <img
                                src={option.image}
                                alt={option.name}
                                className={`general-image-creator ${selected === option.name.toLowerCase() ? 'selected' : ''}`}
                                style={{pointerEvents: 'none'}}
                            />
                            <span className='enforced-display-char-creator-small'
                            style={{fontSize: '0.7em', left: '15px', top: '10px'}}
                            >{t(option.name)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
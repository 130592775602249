import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {fetchSimilarCharacters} from "../CharacterCardFunctions";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {useProfileData} from "../../../components/Context/ProfileDataContext";
import {useButtonTracking} from "../../../components/Analytics/UserAnalyticsContext";

const SimilarCharacters = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [similarCharacters, setSimilarCharacters] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();
    const {identityName} = useParams();
    const loaderRef = useRef(null);
    const containerRef = useRef(null);
    const itemsPerPage = 5;
    const {profileData} = useProfileData();

    const replaceTextPlaceholders = (text, characterName, username) => {
        if (!text) return '';

        return text
            // Replace character placeholders
            .replace(/\{\{char\}\}/gi, characterName)
            .replace(/\{char\}/gi, characterName)
            // Replace user placeholders
            .replace(/\{\{user\}\}/gi, username)
            .replace(/\{user\}/gi, username)
            // Capitalize first letter variants
            .replace(/\{\{Char\}\}/g, characterName.charAt(0).toUpperCase() + characterName.slice(1))
            .replace(/\{Char\}/g, characterName.charAt(0).toUpperCase() + characterName.slice(1))
            .replace(/\{\{User\}\}/g, username.charAt(0).toUpperCase() + username.slice(1))
            .replace(/\{User\}/g, username.charAt(0).toUpperCase() + username.slice(1));
    };

    const fetchSimilarChars = useCallback(async () => {
        if (identityName && hasMore && !isLoading) {
            try {
                setIsLoading(true);
                const data = await fetchSimilarCharacters(identityName, page * itemsPerPage, itemsPerPage);
                if (data.error) {
                    console.error('Error fetching similar characters:', data.error);
                    setHasMore(false);
                } else {
                    const newCharacters = data.results?.[0]?.["Similar Characters"] || [];
                    setSimilarCharacters(prev => [...prev, ...newCharacters]);
                    setHasMore(newCharacters.length === itemsPerPage);
                    setPage(prevPage => prevPage + 1);
                }
            } catch (err) {
                console.error('Failed to fetch similar characters:', err);
                setHasMore(false);
            } finally {
                setIsLoading(false);
            }
        }
    }, [identityName, hasMore, isLoading, page]);

    useEffect(() => {
        setSimilarCharacters([]);
        setPage(0);
        setHasMore(true);
        fetchSimilarChars();
        //eslint-disable-next-line
    }, [identityName]);

    // Modified scroll handling for better cross-browser compatibility
    useEffect(() => {
        const options = {
            root: null, // Use viewport as root
            rootMargin: '20px', // Add margin to trigger earlier
            threshold: 0.1 // Trigger when even 10% is visible
        };

        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting && hasMore && !isLoading) {
                fetchSimilarChars();
            }
        }, options);

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                //eslint-disable-next-line
                observer.unobserve(loaderRef.current);
            }
        };
    }, [fetchSimilarChars, hasMore, isLoading]);

    const truncateDescription = (description, maxLength = 50) => {
        if (!description) return '';
        const processedDescription = replaceTextPlaceholders(
            description,
            similarCharacters.find(char => char.identity_name === identityName)?.display_name || '',
            profileData?.username || ''
        );
        if (processedDescription.length <= maxLength) return processedDescription;
        return processedDescription.slice(0, maxLength) + '...';
    };

    const trackButtonClick = useButtonTracking();

    const handleCharacterClick = (characterIdentityName) => {
        trackButtonClick('similar_character_click', {
            source: window.location.pathname,
            timestamp: Date.now(),
            click_target: characterIdentityName
        });

        if (characterIdentityName !== identityName) {
            navigate(`/c/${characterIdentityName}`, {replace: true});
        }
    };

    return (
        <div
            className="similar-characters-list"
            ref={containerRef}
            style={{
                overflowY: 'auto',
                overscrollBehavior: 'contain',
                WebkitOverflowScrolling: 'touch', // For iOS momentum scrolling
                // maxHeight: '595px', // Or your desired height
                position: 'relative'
            }}
        >
            {similarCharacters.map((character, index) => (
                <div
                    key={character.id || index}
                    className='similar-characters-unify'
                    onClick={() => handleCharacterClick(character.identity_name)}
                    style={{cursor: 'pointer'}}
                >
                    {character.profile ? (
                        <div className="similar-characters-avatar">
                            <LazyLoadImage
                                src={`https://elyza.app/cdn-cgi/image/format=avif,width=128,height=128,compression=fast/${character.profile}`}
                                alt={''}
                                loading="lazy"
                                className='similar-characters-avatar-image'
                            />
                        </div>
                    ) : (
                        <div className="similar-chars-placeholder"></div>
                    )}

                    <div className="similar-characters-text-container">
                        <div className="similar-characters-name">{character.display_name}</div>
                        <div className="similar-characters-description">
                            {truncateDescription(character.description)}
                        </div>
                    </div>
                </div>
            ))}
            {hasMore && (
                <div
                    ref={loaderRef}
                    className="similar-char-general-loader"
                    style={{
                        minHeight: '50px',
                        visibility: isLoading ? 'visible' : 'hidden'
                    }}
                >
                    {isLoading && (
                        <div className="similar-char-general-loader">
                            {[...Array(5)].map((_, index) => (
                                <div key={index} className="similar-char-loader"></div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            {!hasMore && similarCharacters.length === 0 && (
                <div className="no-similar-characters">error</div>
            )}
        </div>
    );
};

export default React.memo(SimilarCharacters);
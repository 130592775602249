import React, { useEffect, useState, useCallback } from 'react';
import { ReactComponent as StarIcon } from "../../CharacterCard/IconsSub/favorite.svg";
import { ReactComponent as FilledStartIcon } from "../../CharacterCard/IconsSub/filledFavorite.svg";
import { debounce } from 'lodash';
import {useTranslation} from "react-i18next";
import {AuthModal} from "../../../components/Forms/AuthModal";
import {userIDExists} from "../../../App";

export const FollowButton = ({ isUser: initialIsUser, handleEditBio, handleFollowUser, isFollowing: initialIsFollowing, isUserLoggedIn }) => {
    const [isFollowing, setIsFollowing] = useState(initialIsFollowing);
    const [isUser, setIsUser] = useState(initialIsUser);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsFollowing(initialIsFollowing);
    }, [initialIsFollowing]);

    useEffect(() => {
        setIsUser(initialIsUser);
    }, [initialIsUser]);

    //eslint-disable-next-line
    const debouncedFollowUser = useCallback(
        debounce(async () => {
            setIsLoading(true);
            try {
                await handleFollowUser();
                setIsFollowing(prevState => !prevState);
            } catch (error) {
                console.error('Failed to follow/unfollow user:', error);
                // Optionally, show an error message to the user
            } finally {
                setIsLoading(false);
            }
        }, 1000, { leading: true, trailing: false }),
        [handleFollowUser]
    );

    const [showAuthModal, setShowAuthModal] = useState(false);

    const validateAndExecuteAction = async (action) => {
        const isLoggedIn = await userIDExists();

        if (!isLoggedIn) {
            setShowAuthModal(true);
            return false;
        }

        return true;
    };

    const handleClick = async () => {
        if (isUser) {
            // For editing bio, we still need to validate login
            const isValid = await validateAndExecuteAction();
            if (isValid) {
                handleEditBio();
            }
        } else if (!isLoading) {
            // For following/unfollowing
            const isValid = await validateAndExecuteAction();
            if (isValid) {
                debouncedFollowUser();
            }
        }
    };

    const {t} = useTranslation();

    const ButtonContent = () => (
        <>
            {isFollowing ? <FilledStartIcon /> : <StarIcon />}
            {isUser ? t('Edit') : isLoading ? t('Following') : isFollowing ? t('Following') : t('Follow')}
        </>
    );

    const Button = () => (
        <>

            <div className="character-card-button-container">
                <button
                    onClick={handleClick}
                    className={`follow-user-button ${isLoading ? 'loading' : ''}`}
                    disabled={isLoading}
                >
                    <ButtonContent />
                </button>
            </div>

            {showAuthModal && (
                <AuthModal
                    isOpen={showAuthModal}
                    onClose={() => setShowAuthModal(false)}
                />
            )}
        </>

    );

    return <Button/>;

};
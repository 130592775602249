import React, {useEffect, useState, useRef} from 'react';
import './MobileNavigation.css';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as HomeIcon} from './icons/HomeIcon.svg';
import {ReactComponent as ChatIcon} from './icons/ChatIcon.svg';
import {ReactComponent as CreateCharIcon} from './icons/CreateCharIcon.svg';
import {ReactComponent as PresentIcon} from './icons/presentIcon.svg';
import {ReactComponent as CanRedeemIcon} from './icons/canRedeemIcon.svg';
import {ReactComponent as HiddenGemIcon} from './icons/HiddenGemIcon.svg';
import {ReactComponent as SimpleMode} from './icons/SimpleMode.svg';
import {ReactComponent as AdvancedMode} from './icons/AdvancedMode.svg';


import ProfileEdit from "../UserSettings/ProfileEdit";
import {userIDExists} from "../../App";
import {AuthModal} from "../Forms/AuthModal";
import {useTranslation} from "react-i18next";
import {useUserSettings} from "../UserSettings/UserSettingContext";
import MainRewardModal from "../RewardScreen/MainRewardModal";
import ProfileControlMenu from "../UserSettings/ProfileControlMenu/ProfileControlMenu";
import SupportModal from "../Modals/SupportModal";
import {useProfileData} from "../Context/ProfileDataContext";

export const MobileNavigation = () => {
    const location = useLocation();
    const [selectedMenus, setSelectedMenus] = useState([]);
    const navigate = useNavigate();
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
    const headerRef = useRef(null);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 680;
    const {t} = useTranslation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const {profileData} = useProfileData();
    const isOnCharacterCreator = location.pathname === '/create-character' || location.pathname === '/advanced-creator';

    //TODO: add logic if user already bought premium

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists();
        if (!loggedIn) {
            setIsAuthModalOpen(true);
        }
        return loggedIn;
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const selectedPaths = [];

        // Handle basic navigation paths
        if (location.pathname === '/') {
            selectedPaths.push('/');
        } else if (location.pathname.startsWith('/chat')) {
            selectedPaths.push('/chat');
        } else if (location.pathname === '/create-character') {
            // For simple mode, highlight both create button and simple mode
            selectedPaths.push('/create-character');
        } else if (location.pathname === '/advanced-creator') {
            // For advanced mode, highlight create button and advanced mode only
            selectedPaths.push('/create-character-main'); // Special path just for the create button
            selectedPaths.push('/advanced-creator');
        } else if (location.pathname.startsWith('/c')) {
            selectedPaths.push('/');
        } else if (location.pathname.startsWith('/user')) {
            selectedPaths.push('/');
        } else {
            selectedPaths.push(location.pathname);
        }

        setSelectedMenus(selectedPaths);
    }, [location]);

    const {
        isProfileEditModalOpen,
        toggleProfileEditModal,
        isMainRewardModalOpen,
        toggleMainRewardModal,
        isSupportModalOpen,
        toggleSupportModal,
    } = useUserSettings();

    // Base menu items (first 3 items remain constant)
    const baseMenuItems = [
        {name: <HomeIcon/>, label: t('Home'), path: '/', key: 'home'},
        {name: <ChatIcon/>, label: t('Chat'), path: '/chat', requiresLogin: true, key: 'chat'},
        {
            name: <CreateCharIcon/>,
            label: t('Create'),
            path: '/create-character',
            requiresLogin: false,
            key: 'create',
            // Special handling for the create button's selected state
            isSelected: (selectedPaths) => {
                return location.pathname === '/create-character' ||
                    location.pathname === '/advanced-creator';
            }
        },
    ];

    // Character creator specific menu items
    const characterCreatorMenuItems = [
        {
            name: <SimpleMode/>,
            label: t('Simple'),
            path: '/create-character',
            requiresLogin: false,
            key: 'simple'
        },
        {
            name: <AdvancedMode/>,
            label: t('Advanced'),
            path: '/advanced-creator',
            requiresLogin: false,
            key: 'advanced'
        }
    ];

    const isPremiumShiny = profileData.subscription_data.tier === 'FREE'
    const [hasRewards, setHasRewards] = useState(false);

    useEffect(() => {
        if (profileData.has_rewards) {
            setHasRewards(true);
        } else {
            setHasRewards(false);
        }
    }, [profileData.has_rewards]);

    const regularMenuItems = [
        {
            name: (
                <div className="relative">
                    <PresentIcon/>
                    {hasRewards && (
                        <div style={{
                            position: 'absolute',
                            top: '-6px',
                            right: '24px',
                            width: '16px',
                            height: '16px'
                        }}>
                            <CanRedeemIcon/>
                        </div>
                    )}
                </div>
            ),
            label: t('Rewards'),
            path: '#',
            requiresLogin: true,
            key: 'reward',
            customAction: true,
            onClick: async () => {
                const loggedIn = await validateIsLoggedIn();
                if (loggedIn) {
                    toggleMainRewardModal();
                }
            }
        },
        {name: <HiddenGemIcon/>, label: t('upgrade'), path: '/pricing', requiresLogin: false, key: 'premium', isPremium: isPremiumShiny},
    ];

    // Combine menu items based on current path
    const menuItems = [
        ...baseMenuItems,
        ...(isOnCharacterCreator ? characterCreatorMenuItems : regularMenuItems)
    ];

    useEffect(() => {
        if (isProfileEditModalOpen || isMainRewardModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isProfileEditModalOpen, isMainRewardModalOpen]);

    const headerContainerClass = location.pathname.includes('/chat') ? "header-container-chat-mobile-nav" : "header-container-mobile-nav";

    if (!isMobile) return null;


    return (
        <div
            // className={`main-header-container ${isSearchBarVisible ? 'expanded' : ''} ${isChatRoute ? 'main-header-container-chat' : ''}`}
            className="main-mobile-navigation"

            ref={headerRef}
            // style={{borderBottom: (location.pathname.includes('/c/') || location.pathname.includes('/user')) ? 'none' : '2px solid #242424'}}
        >

            {isAuthModalOpen && (
                <AuthModal
                    isOpen={isAuthModalOpen}
                    onClose={() => setIsAuthModalOpen(false)}
                />
            )}

            <div className={headerContainerClass}>


                <div className="mobile-navigation-main">

                    {menuItems.map((menu, index) => (
                        <div key={index} className="menu-item-container-bottom">
                            {menu.customAction ? (
                                <div
                                    className={`header-menu-item ${selectedMenus.includes(menu.path) ? 'selected' : ''}`}
                                    onClick={menu.onClick}
                                >
                                    <div className="menu-item-content">
                                        {menu.name}
                                        <span className="menu-item-label-navigation">{menu.label}</span>
                                    </div>
                                </div>
                            ) : (
                                <Link
                                    to={menu.path}
                                    className={`header-menu-item ${
                                        menu.isSelected ?
                                            menu.isSelected(selectedMenus) ? 'selected' : '' :
                                            selectedMenus.includes(menu.path) ? 'selected' : ''
                                    }`}
                                    onClick={(e) => {
                                        if (menu.requiresLogin) {
                                            e.preventDefault();
                                            validateIsLoggedIn().then(loggedIn => {
                                                if (loggedIn) {
                                                    navigate(menu.path);
                                                }
                                            });
                                        }
                                    }}
                                >
                                    <div className="menu-item-content">
                                        {/*{menu.name}*/}
                                        {/*<span className="menu-item-label-navigation">{menu.label}</span>*/}

                                        <div className={menu.isPremium ? "menu-item-content-icon-premium" : "menu-item-content-icon"}>
                                            {menu.name}
                                        </div>
                                        <span className={menu.isPremium ? "menu-item-content-label-premium" : "menu-item-content-label"}>
                                            {menu.label}
                                        </span>

                                    </div>
                                </Link>
                            )}
                        </div>
                    ))}
                </div>

            </div>

            <ProfileControlMenu
                isOpen={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}
            />

            {isProfileEditModalOpen && (
                <ProfileEdit
                    onClose={toggleProfileEditModal}
                    loginError={false} // TODO: Add login error logic

                />
            )}

            <MainRewardModal
                isOpen={isMainRewardModalOpen}
                onClose={toggleMainRewardModal}
            />

            <SupportModal
                isOpen={isSupportModalOpen}
                onClose={toggleSupportModal}
            />

        </div>
    );
};
import React from 'react';
import ReactDOM from 'react-dom';
import '../ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../MainMenuPage/icons/cross.svg";
import {useProfileData} from "../../Context/ProfileDataContext";
import {useTranslation} from "react-i18next";

const CustomizationLimitUpsell = ({ isOpen, onClose, type }) => {

    const {t} = useTranslation();

    const { profileData } = useProfileData();
    const currentTier = profileData.subscription_data.tier.toLowerCase();
    const actionType = type === 'personalities' ? t('addMoreProfiles') : t('addMoreFragments');
    const MemPer = type === 'personalities' ? t('personalityLimits') : t('memoriesLimits');

    if (!isOpen) return null;

    const tierMap = {
        'FREE': 'Free',
        'BASIC': 'Basic',
        'PREMIUM': 'Premium',
        'GOLD': 'Ultimate'
    };

    const upgradeTierMap = {
        'FREE': 'Basic',
        'BASIC': 'Premium',
        'PREMIUM': 'Ultimate',
        'GOLD': 'Ultimate'
    };

    const displayTier = tierMap[currentTier] || currentTier;
    const upgradeTier = upgradeTierMap[currentTier] || 'Ultimate';

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop" style={{zIndex: "9999", backdropFilter: "blur(10px)", alignItems: 'center'}}>
                <div className="modal-content-notification"
                     style={{
                         borderRadius: '16px'
                     }}
                >
                    <div className='notification-label'>
                        ✋ {t('whoaThere')}
                        <div className="modal-close-icon-notification" onClick={() => {onClose(false)}}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            {t('reachedLimitsAs')}
                            <span className="colored-purple-text"> {MemPer} </span>
                            {t('asUser')} {displayTier}.
                            <br></br>
                            <br></br>
                            {t('getIncreasedLimits')}
                            <span className="colored-purple-text"> {t('increasedLimits')} </span>
                            {t('asArticle')}
                            <span className="colored-purple-text"> {upgradeTier} </span>
                            {t('user')}.
                            <br></br>
                            <br></br>
                            <span className="colored-purple-text"> {t('upgradeToAdd')} </span>
                            {t('to')} {actionType}.
                        </div>
                    </div>

                    <button onClick={() => {
                        onClose(true)
                    }} className="notification-button-main">{t('UpgradeNow')}</button>
                </div>
            </div>
        ),
        document.body
    );
};

export default CustomizationLimitUpsell;
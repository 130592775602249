import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import './LoadingScreen.css';
import AverageElyzaDev from './loading/AverageElyzaDev.png';
import {useTranslation} from "react-i18next";

const images = [
    AverageElyzaDev
];

const LoadingScreen = ({ isLoading, isAddonModal = false }) => {

    const {t} = useTranslation();

    let loadingTexts = []

    if (isAddonModal === false) {
        loadingTexts = [
            t("PleaseWait"),
            t("JoinDiscord"),
            t("WarmingUpAI"),
            t("TakingLonger"),
            t("CheckAdvancedMode"),
            t("ThankYou"),
            t("EnjoyYourStay"),
        ];
    } else if (isAddonModal === true) {
        loadingTexts = [
            t("PleaseWait"),
            t("ThankYou"),
            t("EnjoyYourStay"),
        ];
    }


    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!isLoading) return;

        // Lock scrolling when the loading screen is visible
        document.body.style.overflow = 'hidden';

        const imageInterval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);

        const textInterval = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
        }, 3000);

        const progressInterval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(progressInterval);
                    return 100;
                }
                return prevProgress + 1;
            });
        }, 150);

        return () => {
            clearInterval(imageInterval);
            clearInterval(textInterval);
            clearInterval(progressInterval);
            // Unlock scrolling when the component unmounts
            document.body.style.overflow = 'unset';
        };
        //eslint-disable-next-line
    }, [isLoading]);

    if (!isLoading) return null;

    return ReactDOM.createPortal(
        <div className="loading-screen-overlay">
            <div className="loading-screen-backdrop"></div>
            <div className="loading-screen-content">
                {images.map((src, index) => (
                    <motion.img
                        key={src}
                        src={src}
                        alt={`Background ${index + 1}`}
                        className="background-image"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: index === currentImageIndex ? 1 : 0 }}
                        transition={{ duration: 1 }}
                    />
                ))}
                <div className="content-wrapper">
                    <motion.div
                        className="spinner"
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    />
                    <motion.p
                        className="loading-text"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        {loadingTexts[currentTextIndex]}
                    </motion.p>
                    <div className="progress-bar-container">
                        <div
                            className="progress-bar"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default LoadingScreen;
import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import ReactDOM from "react-dom";
import {useTranslation} from "react-i18next";

const PleaseUpdateBio = ({ isOpen, onClose, onStartBioEdit }) => {

    const {t} = useTranslation();

    const handleBioEdit = () => {
        onStartBioEdit();
        onClose();
    }

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-label'>
                        {t('HeyThere')}
                        <div className="modal-close-icon-notification" onClick={() => {onClose(false)}}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">

                        <div className='sublabel-notification'>
                            {t('bio_missing')}
                            <br></br>
                            {t('write_now')}
                        </div>

                    </div>


                    <button onClick={handleBioEdit} className="notification-button-main">{t('Continue')}</button>


                </div>
            </div>
        ),
        document.getElementById('modal-root')
    );
};

export default PleaseUpdateBio;
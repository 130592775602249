import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import './CharCreatorSteps.css';
import {useCharacterCreation} from "../CharacterCreationContext";

const personalities = [
    {
        name: 'Sweet',
        description: 'Friendly and outgoing.',
        emoji: '🍬'
    },
    {
        name: 'Mean',
        description: 'Assertive and confident.',
        emoji: '😠'
    },
    {
        name: 'Innocent',
        description: 'Pure and kind-hearted.',
        emoji: '😇'
    },
    {
        name: 'Submissive',
        description: 'Obedient and compliant.',
        emoji: '🙇‍'
    },
    {
        name: 'Dominant',
        description: 'Bold and assertive.',
        emoji: '👑'
    },
    {
        name: 'Shy',
        description: 'Reserved and timid.',
        emoji: '🫣'
    }
];

const SimpleStepThree = () => {
    const { characterData, updateCharacterData } = useCharacterCreation();
    const [selectedPersonality, setSelectedPersonality] = useState(characterData.personality || '');
    const { t } = useTranslation();

    const handlePersonalitySelect = (personality) => {
        setSelectedPersonality(personality);
        updateCharacterData({ personality: personality });
    };

    return (
        <div className="personality-selection-container">
            <div className="personality-grid">
                {personalities.map((personality) => (
                    <div
                        key={personality.name}
                        className={`personality-box ${selectedPersonality === personality.name ? 'selected' : ''}`}
                        onClick={() => handlePersonalitySelect(personality.name)}
                    >
                        <h3 className={'personality-name-creator'}>{t(personality.name)} {personality.emoji}</h3>

                        <p className={'personality-description-creator'}>{t(personality.description)}</p>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default SimpleStepThree;
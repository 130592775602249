import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from '../../icons/cross.svg';
import './ResponseSettings.css';
import Slider from '@mui/material/Slider';
import DropDownGeneral from "./DropDownGeneral";
import {getUserAccessToken, getUserID} from "../../App";
import {useProfileData} from "../Context/ProfileDataContext";
import {useNavigate} from "react-router-dom";
import ResponseModeMenu from "./ResponseSettings/ResponseModeMenu";
import {ReactComponent as InfoIcon} from "./ChatIcons/info-icon.svg";
import ConfirmationModal from "./ResponseSettings/ConfirmationModal";
import LoadingModal from "./ResponseSettings/LoadingModal";
import Tooltip from "@mui/material/Tooltip";
import {FormControlLabel, Switch} from "@mui/material";
import {useTranslation} from "react-i18next";
import AdvancedCharacterEdit from "./CharacterEditScreens/Advanced/AdvancedCharacterEdit";
import CustomScenarioModal from "../Modals/CustomScenarioModal";
import UpgradeNewModel from "../Notifications/Upsells/UpgradeNewModel";
import {ReactComponent as LockIcon} from "./ChatIcons/lock-icon.svg";

const ResponseSettings = ({
                              isVisible,
                              onCancel,
                              llmModel,
                              setLlmModel,
                              maxTokens,
                              setMaxTokens,
                              temperature,
                              setTemperature,
                              responseMode,
                              setResponseMode,
                              dynamicScenarioEnabled,
                              setDynamicScenarioEnabled,
                              isOwnerBoolean,
                              characterId,
                              setDeleteCurrentChat,
                              selectedLanguage,
                              setSelectedLanguage,
                              isSimple
                          }) => {
    const navigate = useNavigate();
    const [subscriptionError, setSubscriptionError] = React.useState(false);
    const {profileData} = useProfileData();
    const [showCharacterEdit, setShowCharacterEdit] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isSubscriptionLocked = profileData.subscription_data.tier === "FREE";

    const handleModifyCharacter = () => {
        setShowCharacterEdit(true)
    }

    useEffect(() => {
        if (llmModel === 'Elyza Polyglot') {
            setResponseMode('ElyzaChocolate');
        }
    }, [selectedLanguage, llmModel, setResponseMode]);

    const handleSwitchClick = (event) => {
        if (isSubscriptionLocked) {
            // event.preventDefault();
            setIsModalOpen(true);
        } else {
            switchToggle(event);
        }
    };

    const switchToggle = (event) => {
        setDynamicScenarioEnabled(event.target.checked);
        setCacheDynamicScenarioEnabled(event.target.checked);
    };

    const [cacheLlmModel, setCacheLlmModel] = useState(llmModel);
    const [cacheMaxTokens, setCacheMaxTokens] = useState(maxTokens);
    const [cacheTemperature, setCacheTemperature] = useState(temperature);
    const [cacheResponseMode, setCacheResponseMode] = useState(responseMode);
    const [cacheDynamicScenarioEnabled, setCacheDynamicScenarioEnabled] = useState(dynamicScenarioEnabled);

    const [modelSwitchExternalCache, setModelSwitchExternalCache] = useState(false);

    useEffect(() => {
        if (isOwnerBoolean === undefined) {
            // Do nothing if isOwnerBoolean is undefined
        }
    }, [isOwnerBoolean]);

    useEffect(() => {
        setCacheLlmModel(llmModel);
        setCacheMaxTokens(maxTokens);
        setCacheTemperature(temperature);
        setCacheResponseMode(responseMode);
        setCacheDynamicScenarioEnabled(dynamicScenarioEnabled)
    }, [llmModel, maxTokens, temperature, responseMode, dynamicScenarioEnabled]);

    const onInternalCancel = () => {
        setCacheLlmModel(llmModel);
        setCacheMaxTokens(maxTokens);
        setCacheTemperature(temperature);
        setCacheResponseMode(responseMode);
        setCacheDynamicScenarioEnabled(dynamicScenarioEnabled)
        onCancel();
    }

    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const handleDeleteIdentityConfirmation = () => {
        setShowConfirmationModal(true);
    };

    let handleDeleteIdentity = async (confirmDelete) => {
        setShowConfirmationModal(false);
        if (!confirmDelete) {
            return;
        }

        setIsLoading(true); // Start loading state
        const userID = await getUserID();
        const userAccessToken = await getUserAccessToken();

        if (!userID || !characterId) {
            console.error("UserID or CharacterID missing");
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch('https://api.elyza.app/v1/delete-identity', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', 'Authorization': userAccessToken,
                }, body: JSON.stringify({userId: userID, characterId: characterId}),
            });

            const data = await response.json();

            if (response.ok) {
                // console.log("Identity deleted successfully", data);
                setDeleteCurrentChat(true);
                onCancel(); // Assuming onCancel is a prop function to close the modal or navigate away
            } else {
                throw new Error(data.error || "Failed to delete identity");
            }
        } catch (error) {
            console.error("Error deleting identity:", error);
            // Optionally, display the error to the user here
        } finally {
            setIsLoading(false); // End loading state
        }
    };

//eslint-disable-next-line
    const submitUpdateSettings = async () => { // TODO: INTEGRATE INTO SUBMISSION
        setLlmModel(cacheLlmModel);
        setMaxTokens(cacheMaxTokens);
        setTemperature(cacheTemperature);
        setResponseMode(cacheResponseMode);
        setDynamicScenarioEnabled(cacheDynamicScenarioEnabled)

        const identityName = window.location.pathname.substring(6);
        await fetch('https://api.elyza.app/v1/change-settings', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                userId: await getUserID(),
                identityName: identityName,
                newModel: cacheLlmModel,
                newMaxTokens: cacheMaxTokens,
                newTemperature: cacheTemperature,
                newResponseMode: cacheResponseMode,
                dynamicScenario: cacheDynamicScenarioEnabled,
                language: selectedLanguage
            })
        });

        onCancel();
    }

    const [MinTokens, setMinTokens] = useState(null);
    const [MaxTokens, setMaxFakeTokens] = useState(null);
    const [defaultFakeValue, setDefaultFakeValue] = useState(null);

    useEffect(() => {
        if (cacheLlmModel === 'Elyza Modern') {
            setMinTokens(340);
            setMaxFakeTokens(620);
            setDefaultFakeValue(380);
            setMaxTokens(380);
        } else {
            setMinTokens(175);
            setMaxFakeTokens(512);
            setDefaultFakeValue(256);
            setMaxTokens(256);
        }
//eslint-disable-next-line
    }, [cacheLlmModel]);

    const {t} = useTranslation();

    if (!isVisible) return null;

    const modalContent = (
        <>
            <div className="main-space-finder-modal"
                // style={{backgroundColor: window.innerWidth < 680 ? '#131313' : ''}}
            >
                {profileData.subscription_data.tier === "FREE" &&
                    <>
                        {subscriptionError && <UpgradeNewModel isOpen={subscriptionError} onClose={(confirm) => {
                            setSubscriptionError(false);
                            if (confirm) {
                                navigate('/pricing');
                            }
                        }}/>}
                    </>
                }
                <div className="app-modal-content-response-settings" onClick={(e) => e.stopPropagation()}>

                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">{t('characterSettings')}</div>
                        <div className="close-modal-icon" onClick={onInternalCancel}><CloseIcon/>

                        </div>

                    </div>
                    <div className="main-elyza-text-response-settings">{t('youMayEdit')}</div>

                    <div className="line-container">
                        <span className="text-line-container">{t('modelSelection')}</span>
                        <div className="line"></div>
                    </div>

                    <div className="main-elyza-text-response-settings">{t('youMaySelect')}</div>

                    <div className="drop-down-menu">
                        <DropDownGeneral
                            value={cacheLlmModel}
                            setValue={setCacheLlmModel}
                            setLlmModel={setLlmModel}
                            modelSwitchExternalCache={modelSwitchExternalCache}
                            setModelSwitchExternalCache={setModelSwitchExternalCache}
                            selectedLanguage={selectedLanguage}
                            setSelectedLanguage={setSelectedLanguage}
                        />
                    </div>

                    <div className="line-container">
                        <span className="text-line-container">{t('chatSettings')}</span>
                        <div className="line"></div>
                    </div>

                    <div className="main-elyza-text-response-settings">{t('hereYouMay')}</div>


                    <div className="slider-label"
                         style={{
                             marginTop: '5px',
                             color: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#fff"
                         }}
                    >{t('dynamicScenarioNoDot')}

                        {profileData.subscription_data.tier === "FREE" &&
                            <div className="menu-item-content-icon-premium-but-smaller"
                                 onClick={() => navigate('/pricing')}>
                                <LockIcon/>
                                {t('premium').charAt(0).toUpperCase() + t('premium').slice(1)}
                            </div>
                        }

                        <div className='two-switches-menu'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={cacheDynamicScenarioEnabled}
                                        // disabled={isSubscriptionLocked}
                                        onChange={handleSwitchClick}
                                        name="switch1"
                                        sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: 'white', // Thumb color when checked
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Ripple color when hovered
                                                },
                                            }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                backgroundColor: '#9072D9', // Track color when checked
                                            }, '& .MuiSwitch-track': {
                                                backgroundColor: 'grey', // Track color when unchecked
                                            },
                                        }}
                                    />}
                                labelPlacement="start"/>
                        </div>
                    </div>

                    <div className="main-elyza-text-response-settings">{t('thisPremiumFeature')}
                    </div>

                    <div className="slider-standard">
                        <div className="slider-label"
                             style={{color: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#fff"}}
                        >{t('responseLength')}
                            {profileData.subscription_data.tier !== "FREE" &&
                                <Tooltip title={t('ResponseLengthTooltip')}
                                         placement="top" arrow>
                                    <InfoIcon/>
                                </Tooltip>
                            }
                            {profileData.subscription_data.tier === "FREE" &&
                                <div className="menu-item-content-icon-premium-but-smaller"
                                     onClick={() => navigate('/pricing')}>
                                    <LockIcon/>
                                    {t('premium').charAt(0).toUpperCase() + t('premium').slice(1)}
                                </div>
                            }

                        </div>
                        <Slider
                            value={cacheMaxTokens}
                            onChange={(event, newValue) => {
                                if (profileData.subscription_data.tier === "FREE") {
                                    setSubscriptionError(true);
                                } else {
                                    setCacheMaxTokens(newValue);
                                }
                            }}
                            aria-labelledby="response-length-slider"
                            valueLabelDisplay="auto"
                            defaultValue={defaultFakeValue}
                            min={MinTokens}
                            max={MaxTokens}
                            step={1}

                            sx={{
                                color: profileData.subscription_data.tier === "FREE" ? "#4c4c4c" : "#9072D9",
                                padding: '0!important',
                                '& .MuiSlider-thumb': {
                                    backgroundColor: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#6335D2",
                                    width: '16px',
                                    height: '16px',
                                },
                                '& .MuiSlider-rail': {
                                    height: '8px', // Adjust this to make the slider track thicker
                                },
                                '& .MuiSlider-track': {
                                    height: '8px', // Adjust this to make the slider track thicker
                                },
                            }}
                        />
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', fontSize: '0.75em', color: '#E3E3E3'
                        }}>
                            <span>{MinTokens}</span>
                            <span>{MaxTokens}</span>
                        </div>
                    </div>

                    <div className="slider-standard">
                        <div className="slider-label"
                             style={{color: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#fff"}}
                        >{t('creativity')}
                            {profileData.subscription_data.tier !== "FREE" &&
                                <Tooltip title={t('TemperatureTooltip')} placement="top" arrow>
                                    <InfoIcon/>
                                </Tooltip>
                            }
                            {profileData.subscription_data.tier === "FREE" &&
                                <div className="menu-item-content-icon-premium-but-smaller"
                                     onClick={() => navigate('/pricing')}>
                                    <LockIcon/>
                                    {t('premium').charAt(0).toUpperCase() + t('premium').slice(1)}
                                </div>
                            }
                        </div>
                        <Slider
                            defaultValue={0.88} // Set default value
                            value={typeof cacheTemperature === 'number' ? cacheTemperature : 0.88} // Ensure value is in the correct range
                            onChange={(event, newValue) => {
                                if (profileData.subscription_data.tier === "FREE") {
                                    setSubscriptionError(true);
                                } else {
                                    setCacheTemperature(newValue);
                                }
                            }}
                            aria-labelledby="response-length-slider"
                            min={0.8}
                            max={1.0}
                            step={0.01}
                            valueLabelDisplay="auto"
                            sx={{
                                color: profileData.subscription_data.tier === "FREE" ? "#4c4c4c" : "#9072D9",
                                padding: '0!important', '& .MuiSlider-thumb': {
                                    backgroundColor: profileData.subscription_data.tier === "FREE" ? "#c4c4c4" : "#6335D2"
                                    , width: '16px', height: '16px',
                                }, '& .MuiSlider-rail': {
                                    height: '8px',
                                }, '& .MuiSlider-track': {
                                    height: '8px',
                                },
                            }}
                        />
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', fontSize: '0.75em', color: '#E3E3E3'
                        }}>
                            <span>{t('rigid')}</span>
                            <span>{t('creative')}</span>
                        </div>
                    </div>

                    <div className="slider-standard">
                        <div className="slider-label">{t('responseMode')}
                            <Tooltip
                                title={t('ResponseModeTooltip')}
                                placement="top" arrow>
                                <InfoIcon/>
                            </Tooltip>
                        </div>

                        <ResponseModeMenu
                            activeMode={responseMode}
                            onModeChange={setResponseMode}
                            isLocked={llmModel === 'Elyza Polyglot'}
                        />

                    </div>
                    <div className="a-straight-line-3"
                         style={{marginTop: '5px', marginBottom: '10px'}}
                    ></div>


                    <div className="footer-button-container"
                         style={{justifyContent: isOwnerBoolean ? 'flex-end' : 'space-between'}}>
                        {isOwnerBoolean && (<>
                            <div className="owner-custom-settings">
                                {!isSimple && (
                                    <button
                                        className="big-blue-button"
                                        onClick={handleModifyCharacter}
                                        // disabled={loading}
                                    >
                                        {t('ModifyCharacter')}
                                    </button>
                                )}

                                <button className="big-red-button" onClick={handleDeleteIdentityConfirmation}>
                                    {t('DeleteCharacter')}
                                </button>

                            </div>
                        </>)}

                        {/*<button className="need-help-button" onClick={handleJoinClick}*/}
                        {/*        style={{width: isOwnerBoolean ? '100%' : '100%'}}>*/}
                        {/*    {t('needHelp')}*/}
                        {/*</button>*/}

                        <button className="save-settings-button" onClick={submitUpdateSettings}
                                style={{width: isOwnerBoolean ? '100%' : '100%'}}>
                            {t('save')}
                        </button>
                    </div>

                </div>
            </div>
            <ConfirmationModal
                isOpen={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={() => handleDeleteIdentity(true)}
            />

            <LoadingModal isOpen={isLoading}/>

            <CustomScenarioModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />

            <AdvancedCharacterEdit
                isOpen={showCharacterEdit}
                onClose={() => setShowCharacterEdit(false)}
            />

            {/*<SimpleCharacterEdit*/}
            {/*    isOpen={simpleCharacterEdit}*/}
            {/*    onClose={() => setSimpleCharacterEdit(false)}*/}
            {/*/>*/}

        </>
    );

    return (<>
        {ReactDOM.createPortal(modalContent, document.body)}
    </>);
};

export default ResponseSettings;
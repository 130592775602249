import React from "react";

export const ProgressBar = ({currentStep, totalSteps}) => {
    return (
        <div className="progress-bar-container-char">
            <div className="progress-bar-background"></div>
            <div className="progress-bar-line" style={{width: `${(currentStep) / (totalSteps - 1) * 100}%`}}></div>
            {Array.from({length: totalSteps}).map((_, index) => (
                <div key={index} className={`circle-progress-bar ${index <= currentStep ? 'active' : ''}`}></div>
            ))}
            <div className="progress-user-icon" style={{left: `${(currentStep) / (totalSteps - 1) * 100}%`}}></div>
        </div>
    );
};
import React, {useEffect} from 'react';
import './UserLoader.css';

const UserScreenLoader = () => {
    // const renderTags = () => {
    //     // Simulating 3 tags
    //     return Array(3).fill(null).map((_, index) => (
    //         <div key={index} className="character-loader-tag"></div>
    //     ));
    // };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const renderSimilarChars = () => {
        // Simulating 4 similar characters
        return Array(4).fill(null).map((_, index) => (
            <div key={index} className="character-loader-similar-char"></div>
        ));
    };

    const renderPublicChats = () => {
        // Simulating 2 public chat containers
        return Array(2).fill(null).map((_, index) => (
            <div key={index} className="character-loader-public-chat-container"></div>
        ));
    };

    return (
        <div className="character-card-loader-column">
            <div className="character-card-loader-main">

                <div className="character-loader-left-column">

                    <div className="user-loader-profile"></div>

                    {/*<div className="character-loader-tags">*/}
                    {/*    /!* Array of character-loader-tag here *!/*/}
                    {/*    {renderTags()}*/}
                    {/*</div>*/}
                </div>

                <div className="character-loader-right-column">

                    <div className="character-loader-top-right">
                        <div className="character-loader-text-profile">
                            <div className="character-loader-name"></div>
                            <div
                                style={{width: '95%', height: '105px'}}
                                className="character-loader-label"></div>
                        </div>

                        <div className="character-loader-gf-button-main">
                            <div className="character-loader-gf-button"></div>
                            <div className="character-loader-sub-button-norm"></div>
                        </div>
                    </div>

                </div>

            </div>
            <div className="character-loader-bottom-row">
                <div className="character-loader-similar-chars">
                    <div className="character-loader-similar-char-label"></div>
                    {/* Array of character-loader-similar-char here */}
                    {renderSimilarChars()}
                </div>
                <div className="character-loader-about-me-general" style={{gap: '10px'}}>
                    <div className="character-loader-about-me-label"></div>
                    {/* Array of character-loader-public-chat-container here */}
                    <div style={{display: "flex", flexDirection: "row", gap: "20px"}}>
                        {renderPublicChats()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserScreenLoader;

import {useTranslation} from "react-i18next";

const TaskList = () => {
    const {t} = useTranslation(); // useTranslation is called within the component

    const taskCodeMap = {
        refer_friends: {
            name: t("refer_friends_name"),
            type: "special",
            description: t("refer_friends_description")
        },
        generate_first_image: {
            name: t("generate_first_image_name"),
            type: "special",
            description: t("generate_first_image_description")
        },
        daily_reward: {
            name: t("daily_reward_name"),
            type: "general",
        },
        public_character_creation: {
            name: t("public_character_creation_name"),
            type: "general",
            level: 'I'
        },
        total_messages_sent: {
            name: t("total_messages_sent_name"),
            type: "general",
        },
        create_chat_memory: {
            name: t("create_chat_memory_name"),
            type: "general",
        },
        create_personality: {
            name: t("create_personality_name"),
            type: "general",
        },
        publish_public_memory: {
            name: t("publish_public_memory_name"),
            type: "general",
        },
        top_trending: {
            name: t("top_trending_name"),
            type: "general",
        },
    };

    function decodeTask(task) {
        const taskInfo = taskCodeMap[task.task_code] || { name: t("unknown_task_name"), type: "general" };

        return {
            ...task,
            name: taskInfo.name,
            description: taskInfo.description,
            isSpecial: taskInfo.type === "special",
            level: taskInfo.level || ''
        };
    }

    return {
        decodeTasks: (tasks) => tasks.map(decodeTask)
    };
};

export default TaskList;
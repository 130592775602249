import React, { useEffect, useState } from 'react';
import '../../Notifications/ModalAndNotifications.css';
import { ReactComponent as CloseIcon } from "../../MainMenuPage/icons/cross.svg";
import { useCharacterCreation } from "../../../pages/SimpleCharacterCreation/CharacterCreationContext";
import {useTranslation} from "react-i18next";

const SimpleReviewScreen = ({ isOpen, onClose, onConfirm, onReturnToStep }) => {
    const { characterData } = useCharacterCreation();
    const [skippedItems, setSkippedItems] = useState([]);
    const { t } = useTranslation()

    useEffect(() => {
        const baseRequiredFields = [
            'style', 'gender', 'ethnicity', 'personality', 'eyeColor',
            'hairStyle', 'hairColor', 'bodyType',
            'charName', 'scenario', 'age', 'languageSelection', 'voiceSelection'
        ];

        const requiredFields = characterData.gender?.toLowerCase() !== 'male'
            ? [...baseRequiredFields, 'breastSize', 'buttSize']
            : baseRequiredFields;

        const skipped = requiredFields.filter(field => {
            const value = characterData[field];
            return !value || (typeof value === 'string' && value.trim() === '');
        });

        setSkippedItems(skipped);
    }, [characterData]);

    const handleSubmitCharacter = () => {
        onConfirm();
        onClose();
    }

    const getStepName = (field) => {
        // Map field names to more user-friendly step names with translation
        const stepNames = {
            charName: t('CharacterName'),
            style: t('Style'),
            gender: t('Gender'),
            ethnicity: t('Ethnicity'),
            personality: t('Personality'),
            eyeColor: t('EyeColor'),
            hairStyle: t('HairStyle'),
            hairColor: t('HairColor'),
            bodyType: t('BodyType'),
            breastSize: t('BreastSize'),
            buttSize: t('ButtSize'),
            scenario: t('Scenario'),
            age: t('Age'),
            languageSelection: t('LanguageSelection'),
            voiceSelection: t('VoiceSelection')
        };
        return stepNames[field] || field;
    };



    const handleCompleteMissingSteps = () => {
        if (skippedItems.length > 0) {
            const firstMissedStep = getStepName(skippedItems[0]);
            onReturnToStep(firstMissedStep);
            onClose();
        }
    }

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop"
             style={{alignItems: 'center'}}
        >
            <div className="modal-content-notification"
                 style={{
                     borderRadius: '16px'
                 }}
            >
                <div className='notification-label'>
                    ✋ {t('WaitASecond')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {skippedItems.length > 0 ? (
                        <>
                            <p>{t('MissedStepsMessage')}</p>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                                gap: '10px',
                                marginTop: '15px',
                                maxHeight: '300px',
                                overflowY: 'auto',
                            }}>
                                {skippedItems.map((item, index) => (
                                    <button
                                        key={index}
                                        onClick={() => {
                                            onReturnToStep(getStepName(item));
                                            onClose();
                                        }}
                                        style={{
                                            background: 'none',
                                            border: '1px solid #C4C4C4',
                                            color: '#C4C4C4',
                                            padding: '8px 12px',
                                            borderRadius: '6px',
                                            cursor: 'pointer',
                                            transition: 'all 0.3s ease',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.background = '#C4C4C4';
                                            e.target.style.color = '#000';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.background = 'none';
                                            e.target.style.color = '#C4C4C4';
                                        }}
                                    >
                                        {getStepName(item)}
                                    </button>
                                ))}
                            </div>
                            <p style={{marginTop: '15px', marginBottom: '-10px'}}>{t('ClickStepToComplete')}</p>

                        </>
                    ) : (
                        <>
                            {t('ReviewBeforeSubmission')}

                            <br/><br/>
                            {t('CanNotEditLater')}

                        </>
                    )}
                </div>

                <button
                    onClick={skippedItems.length > 0 ? handleCompleteMissingSteps : handleSubmitCharacter}
                    className="notification-button-main"
                    disabled={false}
                    style={{
                        transition: 'all 0.3s ease',
                    }}
                    onMouseEnter={(e) => {
                        if (!skippedItems.length) {
                            e.target.style.background = '#915ae8';
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (!skippedItems.length) {
                            e.target.style.background = '';
                        }
                    }}
                >
                    {skippedItems.length > 0 ? t('CompleteMissingSteps') : t('Continue')}
                </button>
            </div>
        </div>
    );
};

export default SimpleReviewScreen;
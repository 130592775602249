import React, {useEffect, useRef, useState} from "react";
import "../ImageGenerationPage.css";
import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import {getUserAccessToken, getUserID, userIDExists} from "../../App";
import {useNavigate} from "react-router-dom";
import {ReactComponent as CreateImageGallery} from "../../icons/createImageGallery.svg";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {ReactComponent as ThumbsUpIcon} from "../../icons/thumbs-up.svg";
import {ReactComponent as ThumbsDownIcon} from "../../icons/thumbs-down.svg";
import ReportDislikeButton from "../../components/ChatComponents/ReportDislikeButton";
import {ReactComponent as CloseIcon} from "../../icons/cross.svg";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {useTranslation} from "react-i18next";
import './GenerateImagePage.css';
import {ImageCounter} from "./Sub/ImageCounter";
import {ModelSelect} from "./Sub/ModelSelect";
import {AspectRatioButton} from "./Sub/AspectRatio";
import {ReactComponent as RemovePoseCross} from "./icons/RemovePose.svg";
import {AuthModal} from "../../components/Forms/AuthModal";
import UpgradeNotification from "../../components/Notifications/Upsells/UpgradeNotification";
import {useAnalytics, usePageTracking} from "../../components/Analytics/UserAnalyticsContext";
import {MobileNavigation} from "../../components/MainMenuPage/MobileNavigation";

function fuzzySearch(items, query) {
    const words = query.toLowerCase().split(/\s+/).filter(word => word.length > 0);

    return items
        .map(item => ({
            item,
            score: words.reduce((score, word) => {
                const labelMatch = item.label.toLowerCase().indexOf(word);
                const valueMatch = item.value.toLowerCase().indexOf(word);
                return score + (labelMatch === -1 ? 0 : 1) + (valueMatch === -1 ? 0 : 1);
            }, 0)
        }))
        .filter(({ score }) => score > 0)
        .sort((a, b) => b.score - a.score || a.item.label.localeCompare(b.item.label))
        .map(({ item }) => item);
}

export default function ImageGenerationPage() {
    const navigate = useNavigate();
    const [images, setImages] = useState([]); // State to store images
    const [imageUrl, setImageUrl] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const {profileData} = useProfileData();
    const {t} = useTranslation();
    const { visitorId, sessionId } = useAnalytics();

    usePageTracking('advanced_gallery_generation');

    const [aspectRatio, setAspectRatio] = useState('portrait');

    const [imageStyle, setImageStyle] = useState('anime');


    const [userInput, setUserInput] = useState('');

    const [lockedPoses, setLockedPoses] = useState([]);

    const [availablePoses] = useState([
        { label: t('Blowjob'), value: 'Blowjob' },
        { label: t('Ahegao'), value: 'Ahegao' },
        { label: t('DoggyStyle'), value: 'Doggy Style' },
        { label: t('Missionary'), value: 'Missionary' },
        { label: t('Deepthroat'), value: 'Deepthroat' },
        { label: t('Handjob'), value: 'Handjob' },
        { label: t('Masturbation'), value: 'Masturbation' },
        { label: t('Bukkake'), value: 'Bukkake' },
        { label: t('Tentacles'), value: 'Tentacles' },
        { label: t('Titfuck'), value: 'Titfuck' },
        { label: t('ReverseCowgirl'), value: 'Reverse Cowgirl' },
        { label: t('Spitroast'), value: 'Spitroast' },
        { label: t('BDSM_HogTied'), value: 'BDSM - Hog Tied' },
        { label: t('BDSM_Ballgag'), value: 'BDSM - Ballgag' },
        { label: t('BDSM_TapeGag'), value: 'BDSM - Tape Gag' },
        { label: t('BDSM_Shibari'), value: 'BDSM - Shibari' }
    ]);

    const inputRef = useRef(null);

    const handleUserInputChange = (event) => {
        setUserInput(event.target.value);
    };

    const filteredPoses = userInput
        ? fuzzySearch(availablePoses.filter(pose => !lockedPoses.includes(pose.value)), userInput)
        : [];

    const selectPose = (pose) => {
        if (lockedPoses.length < 3) {
            setLockedPoses([...lockedPoses, pose.value]);
            setUserInput('');
            inputRef.current.focus();
        }
    };

    const removePose = (pose) => {
        setLockedPoses(lockedPoses.filter(p => p !== pose));
        inputRef.current.focus();
    };

    const [imageCount, setImageCount] = useState(1);

    const openModal = (image) => {
        setSelectedImage(image);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        async function fetchData() {
            const userId = await getUserID();
            const accessToken = await getUserAccessToken();

            const response = await fetch('https://api.elyza.app/v1/character-gallery', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken
                },
                body: JSON.stringify({
                    userId: userId,
                })
            });

            if (response.ok) {
                const data = await response.json();
                setImages(data.images.filter(image => image.like_status !== 2 && image.like_status !== 3));
            } else {
                console.error('Failed to fetch images:', response.statusText);

            }
        }

        fetchData().catch(console.error); // Handle errors in async operations
    }, []);


    const [isImageGenerating, setIsImageGenerating] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState(false);

    const handleLikeClick = (e, index, likeStatus) => {
        if (likeStatus !== 0) {
            return;
        }

        // TODO: FORM WITH REPORT MESSAGE
        e.stopPropagation();

        const copy = [...images];
        copy[index].like_status = 1;
        setImages(copy);
        // setLikeStatus(1);

        const url = images[index].image_url;
        reportPositiveImageQuality(url).then(() => {
        });
    };

    const [isReportModalOpen, setReportModalOpen] = useState(false);

    const openReportModal = () => {
        setReportModalOpen(true);
    };

    const [reportFormMessage, setReportFormMessage] = useState('');

    const handleShortDescriptionChange = (e) => {
        setReportFormMessage(e.target.value);
    }

    const [reportedNegativeImage, setReportedNegativeImage] = useState('');

    const handleDislikeClick = (e, index, likeStatus) => {
        if (likeStatus !== 0) {
            return;
        }

        e.stopPropagation();

        setReportedNegativeImage(images[index].image_url);

        openReportModal();
    };

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!isImageGenerating) {
            setProgress(0); // Reset progress when stopping image generation
            return;
        }

        setProgress(0); // Reset progress when starting a new loading
        const totalDuration = 10000; // Total duration for fake loading
        const interval = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return prevProgress + 1;
            });
        }, totalDuration / 100); // Update progress every 100 ms

        return () => {
            clearInterval(interval);
            setProgress(0); // Ensure progress is reset when exiting
        };
    }, [isImageGenerating]);

    const reportNegativeImageQuality = async (imageUrl, issueReport) => {
        let index = 0;
        for (const image of images) {
            if (image.image_url === imageUrl) {
                break;
            }
            index++;
        }
        const copy = [...images];
        copy[index].like_status = 2;
        setImages(copy.filter(image => image.like_status !== 2 && image.like_status !== 3));

        setReportModalOpen(false);

        await fetch('https://api.elyza.app/v1/report-advanced-image-quality', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                userId: await getUserID(), imageUrl, isPositive: false, issueReport,
            })
        });
    }

    const reportPositiveImageQuality = async (imageUrl) => {
        await fetch('https://api.elyza.app/v1/report-advanced-image-quality', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': await getUserAccessToken()
            }, body: JSON.stringify({
                userId: await getUserID(), imageUrl, isPositive: true, issueReport: '',
            })
        });
    }


    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    const validateIsLoggedIn = async () => {
        // Check login status
        return await userIDExists();
    };

    const handleCreateClick = async () => {
        const isLoggedIn = await validateIsLoggedIn();

        if (!isLoggedIn) {
            setIsAuthModalOpen(true); // Show login modal
            return;
        }

        if (profileData.subscription_data.tier === 'FREE') {
            setSubscriptionError(true);
            return;
        }

        setIsImageGenerating(true);
        const userId = await getUserID();

        const requestBody = {
            userId: userId,
            userPrompt: userInput,
            style: imageStyle,
            imageCount: imageCount,
            aspectRatio: aspectRatio,
            poses: lockedPoses,
            visitorId, sessionId
        };

        try {
            const response = await fetch('https://api.elyza.app/v1/advanced-gallery-generation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify(requestBody)
            });

            if (response.ok) {
                const data = await response.json();
                setImageUrl(data.url);

                const newImages = data.urls.map(url => ({
                    image_url: url,
                    image_id: data.image_id,
                    like_status: 0,
                }));

                setImages(prevImages => [...newImages, ...prevImages]);

                async function fetchData() {
                    const userId = await getUserID();
                    const accessToken = await getUserAccessToken();

                    const response = await fetch('https://api.elyza.app/v1/character-gallery', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': accessToken
                        },
                        body: JSON.stringify({
                            userId: userId,
                        })
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setImages(data.images.filter(image => image.like_status !== 2 && image.like_status !== 3));
                    } else {
                        console.error('Failed to fetch images:', response.statusText);

                    }
                }

                fetchData().catch(console.error); // Handle errors in async operations
            } else {
                console.error('Failed to generate image:', response.statusText);
                // Handle the error appropriately, e.g., show an error message to the user
            }
        } catch (error) {
            console.error('Error during image generation:', error);
            // Handle any network or other errors
        } finally {
            setIsImageGenerating(false);
        }
    };

    return (
        <>
            <HeaderMainMenu/>
            {subscriptionError && <UpgradeNotification isOpen={subscriptionError} onClose={(confirm) => {
                setSubscriptionError(false);
                if (confirm) {
                    navigate('/pricing');
                }
            }}/>}

            <div className="image-generation-page">
                <div className="image-generation-container-left">
                    <div className="normalizer-of-size-gallery">
                        <div className="prompt-generate-box">
                            {isImageGenerating ? (
                                <div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(21,21,21,0.96)', // Dark overlay
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '14px'
                                }}>
                                    <div className="loading-image-container-gallery">

                                        <div className="prompt-generate-loader-icon">
                                            <svg width="100" height="100" viewBox="0 0 100 100">
                                                <circle cx="50" cy="50" r="45" strokeWidth="10" fill="none"
                                                        stroke="#242424"/>
                                                <circle cx="50" cy="50" r="45" strokeWidth="10" fill="none"
                                                        stroke="#916DE8"
                                                        strokeDasharray={Math.PI * 2 * 45}
                                                        strokeDashoffset={Math.PI * 2 * 45 * ((100 - progress) / 100)}
                                                        style={{transition: 'stroke-dashoffset 0.3s linear'}}
                                                />
                                                <text x="50" y="55" textAnchor="middle" fill="#916DE8"
                                                      className="loading-text">{`${progress}%`}</text>
                                            </svg>
                                        </div>
                                        <div className="loading-image-text">
                                            {t('PleaseWait')}
                                        </div>

                                        <div className="loading-image-text-2">
                                            {t('YourBeingGenerated')}
                                        </div>

                                    </div>
                                </div>
                            ) : imageUrl ? (
                                <img
                                    src={`https://elyza.app/cdn-cgi/image/format=avif,width=512,height=512,compression=fast/${imageUrl}`}
                                    alt=""
                                    style={{width: '100%', height: 'auto', borderRadius: '14px'}}/>
                            ) : (
                                <div className="prompt-generate-box-general">
                                    <div className="gallery-content-create">
                                        <CreateImageGallery/>
                                        {t('EnterThePrompt')}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="image-generation-container-right">
                        <div className="image-generation-container-right">
                    <span className='char-name-header-gallery'>
                    {t('StartGeneratingImages')}
                </span>
                            <div className="image-gen-right-background">
                                <div className="main-prompt-container">
                                    <div className="textbox-prompt-gallery-general"
                                         style={{marginTop: '0px'}}
                                    >
                                        <span className="label-image-gen">{t('WhatDoYouWantToSee')}</span>
                                        <div className="input-area">
                                            <div className="locked-poses">
                                                {lockedPoses.map((pose, index) => (
                                                    <span key={index} className="locked-pose">
                                                             {pose}
                                                        <button className="locked-pose-cross"
                                                                onClick={() => removePose(pose)}>
                                                            <RemovePoseCross/>
                                                        </button>
                                                    </span>
                                                ))}
                                            </div>
                                            <textarea
                                                id="userInput"
                                                ref={inputRef}
                                                value={userInput}
                                                onChange={handleUserInputChange}
                                                rows="5"
                                                maxLength="500"
                                                placeholder={t('TypeToSearchForPosesOrEnterYourPrompt')}
                                            />
                                        </div>
                                    </div>
                                    {filteredPoses.length > 0 && (
                                        <div className="pose-suggestions">
                                            {filteredPoses.map((pose, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => selectPose(pose)}
                                                    className="pose-item"
                                                    disabled={lockedPoses.length >= 3}
                                                >
                                                    {pose.label}
                                                </button>
                                            ))}
                                        </div>
                                    )}
                                    <div className="image-generation-options">
                                        <span className="label-image-gen">{t('AspectRatio')}</span>
                                        <div className="aspect-ratio-selector">
                                            {['square', 'portrait'].map(ratio => (
                                                <AspectRatioButton
                                                    key={ratio}
                                                    ratio={ratio}
                                                    selected={aspectRatio === ratio}
                                                    onClick={setAspectRatio}
                                                />
                                            ))}
                                        </div>
                                        <span className="label-image-gen">{t('ImageStyle')}</span>
                                        <div className="style-select">
                                            <ModelSelect
                                                selected={imageStyle}
                                                onClick={setImageStyle}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="create-image-button-width"
                                     style={{
                                         display: 'flex',
                                         flexDirection: 'row',
                                         justifyContent: "space-between",
                                         gap: '10px'
                                     }}
                                >

                                    <ImageCounter
                                        imageCount={imageCount}
                                        setImageCount={setImageCount}
                                    />

                                    <button
                                        className={`click-to-generate-button ${isImageGenerating || userInput.length === 0 ? 'disabled' : ''}`}
                                        style={{
                                            width: '100%',
                                            borderRadius: '6px',
                                            backgroundColor: isImageGenerating || userInput.length === 0 ? '#808080' : '#916DE8',
                                            cursor: isImageGenerating || userInput.length === 0 ? 'not-allowed' : 'pointer',
                                            opacity: isImageGenerating || userInput.length === 0 ? '0.6' : '1'
                                        }}
                                        onClick={handleCreateClick}
                                        disabled={isImageGenerating || userInput.length === 0}
                                    >
                                        {t('CreateButton')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {
                images.filter(image => image.like_status !== 2 && image.like_status !== 3).length > 0 ? (

                    <div className="header-text-gallery-container">
                        <span className="header-text-gallery">{t('GalleryCreated')}</span>
                    </div>
                ) : null
            }

            <div className={`bottom-of-page-gallery ${images.length <= 2 ? "few-images" : "many-images"}`}>

                {images.filter(image => image.like_status !== 2 && image.like_status !== 3).length > 0 ? (

                    <div className="gallery-container-collection">
                        {images.filter(image => image.like_status !== 2 && image.like_status !== 3).map((image, index) => (
                            <div className="image-liked-gallery-container" key={index} onClick={() => openModal(image)}>
                                <LazyLoadImage
                                    src={image.image_url ? `https://elyza.app/cdn-cgi/image/format=avif,width=600,height=800,compression=fast/${image.image_url}` : ""}
                                    alt="Displayed image"
                                    className="image-liked-gallery"
                                    loading="lazy"
                                />
                                <div className="like-dislike-buttons-gallery">
                                    {image.like_status !== 2 && (
                                        <div
                                            className={`like-icon-gallery ${image.like_status === 1 ? 'icon-gallery-like-selected' : ''}`}
                                            onClick={(e) => handleLikeClick(e, index, image.like_status)}>
                                            <ThumbsUpIcon/>
                                        </div>
                                    )}

                                    {image.like_status !== 1 && (
                                        <div
                                            className={`dislike-icon-gallery ${image.like_status === 2 ? 'icon-gallery-like-selected' : ''}`}
                                            onClick={(e) => handleDislikeClick(e, index, image.like_status)}>
                                            <ThumbsDownIcon/>
                                        </div>
                                    )}

                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}

            </div>

            <ReportDislikeButton
                isOpen={isReportModalOpen}
                onCancel={() => setReportModalOpen(false)}
                imageUrl={reportedNegativeImage}
                reportNegativeImage={reportNegativeImageQuality}
                shortDescription={reportFormMessage}
                setShortDescription={setReportFormMessage}
                handleShortDescriptionChange={handleShortDescriptionChange}
            />

            {
                isModalOpen && (
                    <div className="image-modal">

                        <div className="image-modal-content">
                            <CloseIcon className="modal-close-button" onClick={closeModal}/>
                            <img
                                src={selectedImage.image_url ? `https://elyza.app/cdn-cgi/image/format=avif,width=600,height=800,compression=fast/${selectedImage.image_url}` : ""}
                                alt="Enlarged view" className="modal-image"/>
                        </div>

                        <div className="modal-backdrop" onClick={closeModal}
                             style={{backgroundColor: 'rgba(0, 0, 0, 0.9)'}}></div>

                    </div>
                )
            }

            {isAuthModalOpen && (
                <AuthModal
                    isOpen={isAuthModalOpen}
                    onClose={() => setIsAuthModalOpen(false)}
                />
            )}

            <MobileNavigation/>

        </>
    );
}
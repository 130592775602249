import React from 'react';
import '../Notifications/ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";

const OverrideModal = ({ isOpen, onClose, overrideChat }) => {

    const { t } = useTranslation();

    const handleOverrideChat = () => {
        overrideChat()
        onClose()
    }

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-label'>
                    {t('HeyThere')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {t('ChatAlreadyExists')}
                    <br></br>
                    <br></br>
                    {t('Cloning this memory will delete your current chat!')}
                    <br></br>
                    <br></br>
                    {t('Do you wish to continue?')}
                </div>
                <button onClick={handleOverrideChat} className="notification-button-main">
                    {t('Continue')}
                </button>
            </div>
        </div>
    );
};

export default OverrideModal;
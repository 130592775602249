import React, {useEffect, useState} from "react";
import {NewPricingContainer} from "../../components/MainMenuPage/PricingPage/NewPricingContainer";
import "./PricingPage.css";
import {HeaderMainMenu} from "../../components/MainMenuPage/Header";
import {ReactComponent as CreditCardIcon} from "../../icons/credit-card.svg";
import {ReactComponent as CryptoIcon} from "../../icons/cryptoIcon.svg";
import 'react-multi-carousel/lib/styles.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {getUserAccessToken, getUserEmail, userIDExists} from "../../App";
import {ReactComponent as CloseIcon} from '../../icons/cross.svg';
import AnimeGirl from "../../components/Banners/BannerIcons/anime1.png";
import GirlReal from "../../components/Banners/BannerIcons/real1.png";
import TopTimer from "../../components/Notifications/TopTimer";
import {useTimer} from "../../components/Context/TimerContext";
import {AuthModal} from "../../components/Forms/AuthModal";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {useExitIntent} from "use-exit-intent";
import ReactDOM from "react-dom";
// import ReferralMenu from "../components/Banners/Referral/ReferralMenu";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import PricingToggleSlider from "./SubComponents/PricingToggleSlider";
import {ReactComponent as FireIcon} from "../../icons/FireIcon.svg";
import {useButtonTracking, usePageTracking} from "../../components/Analytics/UserAnalyticsContext";
import './SubComponents/FaqPricing.css';
import PricingFrequentQuestions from "./SubComponents/FaqPricing";
import {MobileNavigation} from "../../components/MainMenuPage/MobileNavigation";
import MorePlansModal from "./MorePlansModal";
import UpsellElyzaDev from "../../components/Notifications/UpdatedUpsells/images/FlabberGasted.png";
import {ReactComponent as ImageIcon} from "../../components/Notifications/UpdatedUpsells/icons/ImageIcon.svg";
import {ReactComponent as VoiceIcon} from "../../components/Notifications/UpdatedUpsells/icons/VoiceIcon.svg";
import {ReactComponent as MemoryIcon} from "../../components/Notifications/UpdatedUpsells/icons/MemoryIcon.svg";
import {ReactComponent as InfinityIcon} from "../../components/Notifications/UpdatedUpsells/icons/InfinityIcon.svg";
import "../../components/Notifications/UpdatedUpsells/UpdatedUpsell.css";

const NewPricingPage = () => {

    usePageTracking('pricing_page');

    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            const loggedIn = await userIDExists();
            setIsAuthModalOpen(!loggedIn);
        };

        checkUserLoggedIn();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {t} = useTranslation();

    const additionalPricingData = [{
        heading: `${t('basicPlanHeading')}`,
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('betterMemory')}`,
            `${t('fiftyAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
            `${t('twoPersonalityProfiles')}`,
            `${t('twoMemoryFragments')}`
        ]
    }, {
        heading: `${t('ultimatePlanHeading')}`,
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('ultimateMemory')}`,
            `${t('fiveHundredAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
            `${t('earlyAccessNewFeatures')}`,
            `${t('dynamicScenario')}`,
            `${t('sixPersonalityProfiles')}`,
            `${t('sixMemoryFragments')}`
        ]
    }];

    const pricingPlans = [{
        heading: `${t('basicPlanHeading')}`,
        monthly: {
            price: "$9.99",
            originalPrice: "$12.99",
            discountLabel: "25% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_basic-USD-Monthly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/4a25bc87-5a41-4f3d-9259-bc096d440457"
        },
        annual: {
            price: "$7.99",
            originalPrice: "$12.99",
            discountLabel: "40% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_basic-USD-Yearly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/b512e29f-b267-448f-8ed4-d1678876f0a9"
        },
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('betterMemory')}`,
            `${t('fiftyAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
            `${t('twoPersonalityProfiles')}`,
            `${t('twoMemoryFragments')}`
        ]
    }, {
        heading: `${t('premiumPlanHeading')}`,
        monthly: {
            price: "$14.99",
            originalPrice: "$24.99",
            discountLabel: "40% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium-USD-Monthly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/04f3aace-d709-48dd-b5b5-28a0e8b0ab0f"
        },
        annual: {
            price: "$12.99",
            originalPrice: "$24.99",
            discountLabel: "50% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium-USD-Yearly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/a3630279-f1ec-4438-a03a-3f2065dea6b2"
        },
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('maximumMemory')}`,
            `${t('hundredAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
            `${t('earlyAccessNewFeatures')}`,
            `${t('dynamicScenario')}`,
            `${t('fourPersonalityProfiles')}`,
            `${t('fourMemoryFragments')}`
        ]
    }, {
        heading: `${t('ultimatePlanHeading')}`,
        monthly: {
            price: "$49.99",
            originalPrice: "$69.99",
            discountLabel: "30% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_gold-USD-Monthly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/795231e3-f065-46bb-9749-799e584baa79"
        },
        annual: {
            price: "$29.99",
            originalPrice: "$69.99",
            discountLabel: "60% Off!",
            redirect: "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_gold-USD-Yearly&utm_source=cb-app-copy",
            redirectCrypto: "https://checkout.loopcrypto.xyz/71660573-c2ac-44a8-a6cd-c4afef480dad/42522596-d6a0-47b1-a07f-7601d49fe828"
        },
        features: [
            `${t('unlimitedMessagesFeature')}`,
            `${t('advancedChatModel')}`,
            `${t('unlimitedMLModel')}`,
            `${t('ultimateMemory')}`,
            `${t('fiveHundredAudiosPerMonth')}`,
            `${t('nsfwImages')}`,
            `${t('refundPoorImages')}`,
            `${t('aiCharacterCreation')}`,
            `${t('earlyAccessNewFeatures')}`,
            `${t('dynamicScenario')}`,
            `${t('sixPersonalityProfiles')}`,
            `${t('sixMemoryFragments')}`
        ]
    }];

    const [yearlyPricing, setYearlyPricing] = useState(true);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [premiumPlans, setPremiumPlans] = useState([]);
    // const [isRegularButtonVisible, setIsRegularButtonVisible] = useState(true);
    //
    // useEffect(() => {
    //     const buttonContainer = document.querySelector('.buy-right-now-button');
    //
    //     if (!buttonContainer) return;
    //
    //     const observer = new IntersectionObserver(
    //         ([entry]) => {
    //             setIsRegularButtonVisible(entry.isIntersecting);
    //         },
    //         {
    //             threshold: 0.5, // Button is considered visible when 50% is in view
    //             rootMargin: '0px'
    //         }
    //     );
    //
    //     observer.observe(buttonContainer);
    //
    //     return () => {
    //         if (buttonContainer) {
    //             observer.unobserve(buttonContainer);
    //         }
    //     };
    // }, []);

    const [selectedPlanHeading, setSelectedPlanHeading] = useState('');

    useEffect(() => {
        const premiumPlanHeading = t('premiumPlanHeading');
        const premiumPlan = pricingPlans.find(plan => plan.heading === premiumPlanHeading);

        setSelectedPlan(premiumPlan);
        setPremiumPlans([premiumPlan]);
        setSelectedPlanHeading(premiumPlanHeading);
        //eslint-disable-next-line
    }, [t, i18n.language]);

    const [extraAdditionalPricingData, setExtraAdditionalPricingData] = useState(additionalPricingData);
    const {profileData} = useProfileData();

    const {timerExpired, setTimerExpired} = useTimer();

    const getFinalPrice = () => {
        if (!selectedPlan) {
            return ""; // or return a default value
        }

        if (yearlyPricing) {
            const annualPricePerMonth = parseFloat(selectedPlan.annual.price.replace('$', ''));
            let totalAnnualPrice = (annualPricePerMonth * 12).toFixed(2);

            if (totalAnnualPrice.endsWith('.88')) {
                totalAnnualPrice = totalAnnualPrice.substring(0, totalAnnualPrice.length - 2) + '99';
            }

            return `${totalAnnualPrice} ${t('perYear')}`;
        } else {
            const monthlyPrice = parseFloat(selectedPlan.monthly.price.replace('$', ''));
            const monthlyPriceFormatted = monthlyPrice.toFixed(2);
            return `${monthlyPriceFormatted} ${t('PerMonth')}`;
        }
    };

    const handlePlanSelection = (planHeading) => {
        if (planHeading === t('freePlanHeading')) return;
        const plan = pricingPlans.find(p => p.heading === planHeading);
        if (plan) {
            // Store the previously selected plan
            const previousPlan = selectedPlan;

            setSelectedPlan(plan);
            setSelectedPlanHeading(planHeading);

            // Update the premiumPlans state
            setPremiumPlans([plan]);

            // Add the previously selected plan back to the extraAdditionalPricingData array
            if (previousPlan) {
                setExtraAdditionalPricingData(prevData => [...prevData, previousPlan]);
            }

            // Remove the newly selected plan from the extraAdditionalPricingData array
            setExtraAdditionalPricingData(prevData => prevData.filter(p => p.heading !== planHeading));
        }
        setShowMorePlans(false);
        document.body.style.overflow = 'unset';
    };

    useEffect(() => {
        if (profileData.subscription_data.tier === "PREMIUM" || profileData.subscription_data.tier === "GOLD") {
            handlePlanSelection(t('ultimatePlanHeading'));
        }
        //eslint-disable-next-line
    }, [profileData.subscription_data.tier]);

    const [isWide, setIsWide] = useState(window.innerWidth > 680);
    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [showMorePlans, setShowMorePlans] = useState(false);

    const closeModal = () => {
        setShowMorePlans(false);
    };

    // Toggle body scroll based on the modal visibility
    useEffect(() => {
        if (showMorePlans) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        // Cleanup function to ensure scrolling is re-enabled
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showMorePlans]);

    // Close modal if clicked outside of it
    const handleClickOutside = (event) => {
        if (event.target.classList.contains('backdrop')) {
            closeModal();
        }
    };

    const handleViewMorePlans = () => {
        setShowMorePlans(true);
    };

    const trackButtonClick = useButtonTracking();

    const handleOpenAddons = () => {
        trackButtonClick('went_to_buy_addons', {
            source: window.location.pathname,
            timestamp: Date.now()
        });

        window.location.href = './addons';
    };

    useEffect(() => {
        // Check if the pricing page has been visited before
        const visitedPricing = localStorage.getItem('visitedPricing');

        if (!visitedPricing) {
            // If not, set visitedPricing to true and reset the timer
            localStorage.setItem('visitedPricing', 'true');
            setTimerExpired(false);
        }
    }, [setTimerExpired]);

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists(); // Check login status

        if (!loggedIn) {
            setIsAuthModalOpen(true); // Show login modal
        }

        return loggedIn;
    };

    const placeholderPlans = [
        {
            heading: `${t('premiumPlanHeading')}`,
            features: [
                `${t('unlimitedMessagesFeature')}`,
                `${t('advancedChatModel')}`,
                `${t('unlimitedMLModel')}`,
                `${t('maximumMemory')}`,
                `${t('hundredAudiosPerMonth')}`,
                `${t('nsfwImages')}`,
                `${t('refundPoorImages')}`,
                `${t('aiCharacterCreation')}`,
                `${t('earlyAccessNewFeatures')}`,
                `${t('dynamicScenario')}`,
                `${t('fourPersonalityProfiles')}`,
                `${t('fourMemoryFragments')}`
            ]
        },
        {
            heading: `${t('ultimatePlanHeading')}`,
            features: [
                `${t('unlimitedMessagesFeature')}`,
                `${t('advancedChatModel')}`,
                `${t('unlimitedMLModel')}`,
                `${t('ultimateMemory')}`,
                `${t('fiveHundredAudiosPerMonth')}`,
                `${t('nsfwImages')}`,
                `${t('refundPoorImages')}`,
                `${t('aiCharacterCreation')}`,
                `${t('earlyAccessNewFeatures')}`,
                `${t('dynamicScenario')}`,
                `${t('sixPersonalityProfiles')}`,
                `${t('sixMemoryFragments')}`
            ]
        },
        {
            heading: `${t('basicPlanHeading')}`,
            features: [
                `${t('unlimitedMessagesFeature')}`,
                `${t('advancedChatModel')}`,
                `${t('unlimitedMLModel')}`,
                `${t('betterMemory')}`,
                `${t('fiftyAudiosPerMonth')}`,
                `${t('nsfwImages')}`,
                `${t('refundPoorImages')}`,
                `${t('aiCharacterCreation')}`,
                `${t('twoPersonalityProfiles')}`,
                `${t('twoMemoryFragments')}`
            ]
        },
        {
            heading: `${t('freePlanHeading')}`,
            features: [
                `${t('unlimitedMessages')}`,
                `${t('createExclusiveCharacters')}`,
                `${t('basicMemory')}`,
                `${t('customScenariosAndSettings')}`,
                `${t('onePersonalityProfile')}`,
                `${t('oneMemoryFragment')}`,
                `${t('accessCapableModelsAndNSFW')}`
            ]
        }
    ];

    const [showExitModal, setShowExitModal] = useState(false);

    const {registerHandler} = useExitIntent({

        cookie: {
            daysToExpire: 30,
            key: "use-exit-intent"
        },
        desktop: {
            triggerOnIdle: false,
            useBeforeUnload: false,
            triggerOnMouseLeave: true,
            delayInSecondsToTrigger: 1
        },
        mobile: {
            triggerOnIdle: true,
            delayInSecondsToTrigger: 5
        }
    });

    registerHandler({
        id: 'openModal',
        handler: async () => {
            const isOfferActive = await checkOfferExpiration();
            if (isOfferActive && !offerExpired) {
                setShowExitModal(true);
            }
        }
    });

    // Additional handlers can be defined here if needed
    registerHandler({
        id: 'anotherHandler',
        // handler: () => console.log('Another handler'),
        context: ['onUnsubscribe', 'onMobile']
    });

    const handleCloseExitModal = () => {
        setShowExitModal(false);
    };


    const processQuickPayment = () => {
        window.location.href = "https://elyza.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=elyza_premium-USD-Monthly&utm_source=cb-app-copy";
    };

// Format time function
    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };


    const [isMobile, setIsMobile] = useState(window.innerWidth < 680);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 680);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [offerExpired, setOfferExpired] = useState(false);
    const [timeLeft, setTimeLeft] = useState(300);

    useEffect(() => {
        if (timeLeft <= 0) {
            setOfferExpired(true);
            setShowExitModal(false); // Close the modal when timer expires
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft(prev => prev - 1);
        }, 1000); // Add 1000ms interval

        return () => clearInterval(timer);
    }, [timeLeft]);

    const checkOfferExpiration = async () => {
        try {
            const response = await fetch('https://api.elyza.app/v1/set-instant-offer-timestamp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
            });

            const data = await response.json();
            return data.success;
        } catch (error) {
            console.error('Error checking offer expiration:', error);
            return false;
        }
    };

    const ExitIntent = ({onClose}) => {

        return ReactDOM.createPortal(
            <div className="modal-backdrop">

                <div className="updated-modal-content-notification">
                    <div className="updated-notification-left">
                        <div className='updated-notification-label'
                             style={{textAlign: "left"}}
                        >
                            {t("holdOnBonus")}

                            {isMobile && (
                                <div className="modal-close-icon-notification" onClick={() => {
                                    onClose(false);
                                }}>
                                    <CloseIcon/>
                                </div>
                            )}

                        </div>


                        <div className="gap-between-notification">

                            <div className='sublabel-notification'
                                 style={{paddingLeft: isMobile ? "20px" : "0", paddingRight: isMobile ? "20px" : "0" }}
                            >
                                {t("exclusiveOffer")}
                                <br></br>
                                <br></br>
                                {t("moreTokensPrefix")}
                                {' '}
                                <span className="colored-purple-text">{t("moreTokensHighlight")}</span>
                                {' '}
                                {t("moreTokensSuffix")}
                            </div>

                        </div>

                        <div className="offer-timer">
                            <div className="timer-label">{t('bonusExpires')}</div>
                            <div className="countdown">{formatTime(timeLeft)}</div>
                        </div>

                        <div className="features-grid">
                            <div className="feature-item-notification">
                                <MemoryIcon/>
                                <span>{t('enhancedMemory')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <InfinityIcon/>
                                <span>{t('infiniteMessages')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <ImageIcon/>
                                <span>{t('premiumImages')}</span>
                            </div>
                            <div className="feature-item-notification">
                                <VoiceIcon/>
                                <span>{t('voiceGeneration')}</span>
                            </div>
                        </div>

                        <div className="notification-buttons-container">
                            <button
                                onClick={processQuickPayment}
                                className="notification-button-main-updated"
                                style={{marginTop: "15px"}}
                            >
                                {t('upgradeNow')}
                            </button>

                            <button
                                className="notification-button-secondary-updated"
                                onClick={onClose}
                            >
                                {t('maybeLater')}
                            </button>
                        </div>
                    </div>

                    <div className="updated-notification-right">

                        <div className="updated-modal-close-icon-notification" onClick={() => {
                            onClose();
                        }}>
                            <CloseIcon/>
                        </div>
                        <img
                            src={UpsellElyzaDev}
                            alt={''}
                        />
                    </div>
                </div>
            </div>,
            document.getElementById('modal-root')
        );
    };

    async function payWithCrypto() {
        validateIsLoggedIn().then(async (loggedIn) => {
            if (loggedIn) {
                setTimeout(() => {
                    setIsAuthModalOpen(false);
                }, 100); // Add delay here

                trackButtonClick('clicked_buy_w_crypto', {
                    source: window.location.pathname,
                    timestamp: Date.now()
                });

                window.location.href = (yearlyPricing ? selectedPlan.annual.redirectCrypto : selectedPlan.monthly.redirectCrypto) + `?email=${encodeURIComponent(await getUserEmail())}`
            } else {
                setIsAuthModalOpen(true);
            }
        });
    }


    return (
        <>

            <HeaderMainMenu setSearchText={() => {
            }} searchText={''}/>

            {isAuthModalOpen && (<AuthModal
                isOpen={isAuthModalOpen}
                onClose={() => setIsAuthModalOpen(false)}
            />)}

            {showExitModal && profileData.subscription_data.tier === 'FREE' && !isAuthModalOpen &&(
                <ExitIntent isOpen={showExitModal} onClose={handleCloseExitModal}/>
            )}

            {!timerExpired ? (<div className="top-timer-container-main-pricing"
                                   style={{display: timerExpired !== false ? "none" : 'block'}}
            >
                <TopTimer/>
            </div>) : null}


            <div className="new-pricing-page-container">
                {isWide && (
                    <div className="top-pricing-row-container"
                         style={{paddingTop: timerExpired !== false ? "60px" : '100px'}}
                    >

                        <div className="new-toggle-pricing">

                            <div className="new-pricing-subtitle">
                                <h2>{t('UpgradePlanPricing')}</h2>
                                <span>{t('PremiumPlan')} </span>
                                <span className="highlight-text-price">{t('PremiumDiscount')}</span>

                                <span> {t('NextHour')}</span>

                                <span><br></br>{t('Anonym')}</span>
                            </div>

                            <div
                                className={!yearlyPricing ? "new-pricing-btn new-pricing-btn-active" : "new-pricing-btn"}
                                onClick={() => setYearlyPricing(false)}
                            >
                                <div
                                    className="discount-amount-pricing">{selectedPlan ? selectedPlan.monthly.discountLabel : "40% Off!"}</div>
                                <div className="pricing-title-text">{t('Monthly')}</div>
                                <div className="pricing-container-general">
                                    <div
                                        className="pricing-cost-text-price">{selectedPlan ? selectedPlan.monthly.price : "$14.99"}</div>
                                    <div className="pricing-cost-text-main">
                                        <div className="pricing-cost-text">
                                <span
                                    className="crossed-out">{selectedPlan ? selectedPlan.monthly.originalPrice : "$24.99"}</span>
                                            <span>{t('PerMonth')}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="pricing-subtitle-text">{t('CancelAnytime')}</div>

                            </div>

                            <div
                                className={yearlyPricing ? "new-pricing-btn new-pricing-btn-active" : "new-pricing-btn"}
                                onClick={() => setYearlyPricing(true)}
                            >

                                <div
                                    className="discount-amount-pricing">{selectedPlan ? selectedPlan.annual.discountLabel : "50% Off!"}</div>
                                <div className="most-popular-pin">
                                    <FireIcon/>{t('POPULAR')}
                                </div>
                                <div className="pricing-title-text">{t('Annually')}</div>
                                <div className="pricing-container-general">
                                    <div
                                        className="pricing-cost-text-price">{selectedPlan ? selectedPlan.annual.price : "$12.99"}</div>
                                    <div className="pricing-cost-text-main">
                                        <div className="pricing-cost-text">
                                <span
                                    className="crossed-out">{selectedPlan ? selectedPlan.annual.originalPrice : "$24.99"}</span>
                                            <span>{t('PerMonth')}</span>
                                        </div>
                                    </div>
                                </div>


                                <div className="pricing-subtitle-text">{t('CancelAnytime')}</div>
                            </div>

                        </div>

                        <div className="pricing-container-top-column">

                            {profileData.subscription_data.tier === 'GOLD' && (
                                <>
                                    {placeholderPlans
                                        .filter(plan => plan.heading === `${t('ultimatePlanHeading')}`)
                                        .map((plan, index) => (<NewPricingContainer
                                            key={index}
                                            heading={plan.heading}
                                            features={plan.features}
                                        />))}
                                </>
                            )}

                            {profileData.subscription_data.tier === 'PREMIUM' && (
                                <>
                                    {placeholderPlans
                                        .filter(plan => plan.heading === `${t('ultimatePlanHeading')}`)
                                        .map((plan, index) => (<NewPricingContainer
                                            key={index}
                                            heading={plan.heading}
                                            features={plan.features}
                                        />))}
                                </>
                            )}

                            {profileData.subscription_data.tier === 'BASIC' && (
                                <>
                                    {premiumPlans.map((plan, index) => (
                                        <NewPricingContainer
                                            key={index}
                                            heading={plan.heading}
                                            features={plan.features}
                                            isSelected={selectedPlanHeading === plan.heading}
                                            onSelect={handlePlanSelection}
                                        />))}
                                </>
                            )}

                            {profileData.subscription_data.tier === 'FREE' && (
                                <>
                                    {premiumPlans.map((plan, index) => (
                                        <NewPricingContainer
                                            key={index}
                                            heading={plan.heading}
                                            features={plan.features}
                                            isSelected={selectedPlanHeading === plan.heading}
                                            onSelect={handlePlanSelection}
                                        />))}
                                </>
                            )}

                            {profileData.subscription_data.tier === 'GOLD' && (
                                <>
                                    <div className="buy-right-now-button"
                                         style={{textAlign: 'center', fontSize: '1.25rem', cursor: 'default'}}
                                         onClick={async () => {
                                             validateIsLoggedIn().then(async (loggedIn) => {
                                                 if (loggedIn) {
                                                     setTimeout(() => {
                                                         setIsAuthModalOpen(false);
                                                     }, 100);

                                                     trackButtonClick('clicked_buy_button', {
                                                         source: window.location.pathname,
                                                         timestamp: Date.now()
                                                     });

                                                 } else {
                                                     setIsAuthModalOpen(true);
                                                 }
                                             });
                                         }}>

                                        {t('YouHaveUltimate')}
                                        <br></br>
                                        {t('ThankYou')}
                                    </div>
                                </>
                            )}

                            {profileData.subscription_data.tier !== 'GOLD' && (<>
                                    <div className="buy-right-now-button"
                                         onClick={async () => {
                                             validateIsLoggedIn().then(async (loggedIn) => {
                                                 if (loggedIn) {
                                                     setTimeout(() => {
                                                         setIsAuthModalOpen(false);
                                                     }, 100); // Add delay here

                                                     trackButtonClick('clicked_buy_button', {
                                                         source: window.location.pathname,
                                                         timestamp: Date.now()
                                                     });

                                                     window.location.href = (yearlyPricing ? selectedPlan.annual.redirect : selectedPlan.monthly.redirect) + `&customer[email]=${encodeURIComponent(await getUserEmail())}`
                                                 } else {
                                                     setIsAuthModalOpen(true);
                                                 }
                                             });
                                         }}>
                                        <CreditCardIcon/>
                                        {t('PayCard')}
                                    </div>
                                    <div
                                        className="buy-right-now-button-microtrans-crypto"
                                        style={{marginTop: '15px', maxWidth: 'unset', width: '100%'}}
                                        onClick={payWithCrypto}>
                                        <CryptoIcon/>
                                        {t('PayWithCrypto')}
                                    </div>
                                    <div className="get-pricing-final">
                                        <header>{t('YouWillCharged')}</header>
                                        {getFinalPrice()}
                                    </div>
                                </>
                            )}
                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}

                {!isWide && (

                    <div className="top-pricing-row-container"
                         style={{paddingTop: timerExpired !== false ? "60px" : '100px'}}
                    >
                        <div className="new-toggle-pricing">
                            <div className="left-padding-text-container">
                                <h2>{t("UpgradePlanPricing")}</h2>
                                <div className="new-pricing-subtitle">
                                    <span>{t('PremiumPlan')}</span>
                                    <span className="highlight-text-price">{t('PremiumDiscount')}</span>
                                    <span>{t('NextHour')}</span>
                                    <span><br></br>{t('Anonym')}</span>
                                </div>
                            </div>

                            <div className="card-container-main-premium">
                                {premiumPlans.map((plan, index) => (<NewPricingContainer
                                    key={index}
                                    heading={plan.heading}
                                    features={plan.features}
                                    isSelected={selectedPlanHeading === plan.heading}
                                    onSelect={handlePlanSelection}
                                />))}

                                <div className="pricing-swiper-component">
                                    <PricingToggleSlider
                                        yearlyPricing={yearlyPricing}
                                        setYearlyPricing={setYearlyPricing}
                                        selectedPlan={selectedPlan}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="pricing-container-top-column">
                            <div className="buy-right-now-button"
                                 onClick={async () => {
                                     validateIsLoggedIn().then(async (loggedIn) => {
                                         if (loggedIn) {
                                             setTimeout(() => {
                                                 setIsAuthModalOpen(false);
                                             }, 100); // Add delay here
                                             trackButtonClick('clicked_buy_button', {
                                                 source: window.location.pathname,
                                                 timestamp: Date.now()
                                             });
                                             window.location.href = (yearlyPricing ? selectedPlan.annual.redirect : selectedPlan.monthly.redirect) + `&customer[email]=${encodeURIComponent(await getUserEmail())}`
                                         } else {
                                             setIsAuthModalOpen(true);
                                         }
                                     });
                                 }}>
                                <CreditCardIcon/>
                                {t('PayCard')}
                            </div>
                            <div
                                className="buy-right-now-button-crypto"
                                onClick={payWithCrypto}>
                                <CryptoIcon/>
                                {t('PayWithCrypto')}
                            </div>
                            <div className="get-pricing-final">
                                <header>{t('YouWillCharged')}</header>
                                {getFinalPrice()}
                            </div>
                        </div>
                        <img src={GirlReal} alt="Girl Real" className="girl-real" draggable="false"/>
                    </div>
                )}

                <div className="bottom-of-the-page-container">

                    <div className="left-padding-text-container">
                        <h2>{t('ThisCurrentPlan')}</h2>

                        {profileData.subscription_data.tier !== 'GOLD' && (

                            <>
                                <div className="new-pricing-subtitle">
                                <span>{t('ItsNeat1')}<br/>{t('ItsNeat2')} <span

                                    className="highlight-text-price">{t('onBunchStuff')}</span></span>

                                    {profileData.subscription_data.tier !== 'PREMIUM' && (
                                        <>
                                            {!showMorePlans && (<div className="buy-right-now-button-credit-addons"
                                                                     onClick={handleViewMorePlans}>
                                                {t('ViewOtherPlan')}
                                            </div>)}
                                        </>
                                    )}
                                </div>
                            </>
                        )}

                        {profileData.subscription_data.tier === 'GOLD' && (
                            <>
                                <div className="new-pricing-subtitle">
                                <span>{t('MaxxedOut')} <br/>{t('ThanksForUsing')}
                                    <span className="highlight-text-price">Elyza!
                                    </span></span>
                                </div>
                            </>
                        )}

                        <div className='buy-right-now-button-credit-addons'
                             onClick={handleOpenAddons}>

                            {t('GetMoreTokens')}
                        </div>


                        {profileData.subscription_data.tier === 'PREMIUM' && (
                            <div className='buy-right-now-button-credit-addons'
                                 onClick={handleViewMorePlans}>
                                {t('ViewOtherPlans')}
                            </div>
                        )}

                    </div>

                    {profileData.subscription_data.tier === 'GOLD' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === `${t('ultimatePlanHeading')}`)
                                .map((plan, index) => (<NewPricingContainer
                                    key={index}
                                    heading={plan.heading}
                                    features={plan.features}
                                />))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'PREMIUM' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === `${t('premiumPlanHeading')}`)
                                .map((plan, index) => (<NewPricingContainer
                                    key={index}
                                    heading={plan.heading}
                                    features={plan.features}
                                />))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'BASIC' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === `${t('basicPlanHeading')}`)
                                .map((plan, index) => (<NewPricingContainer
                                    key={index}
                                    heading={plan.heading}
                                    features={plan.features}
                                />))}
                        </>
                    )}

                    {profileData.subscription_data.tier === 'FREE' && (
                        <>
                            {placeholderPlans
                                .filter(plan => plan.heading === `${t('freePlanHeading')}`)
                                .map((plan, index) => (<NewPricingContainer
                                    key={index}
                                    heading={plan.heading}
                                    features={plan.features}
                                />))}
                        </>
                    )}

                    <img src={AnimeGirl} alt="Anime Girl" className="anime-girl" draggable="false"/>

                </div>

                <div className="faq-main-component">
                    <PricingFrequentQuestions/>
                </div>
                <span className="still-not-sure">
                      <a href="https://discord.gg/V4YUYRBc5M"
                         target="_blank"
                         rel="noreferrer"
                         style={{color: "inherit", textDecoration: "none"}}>
                         {t('footerText')}
                     </a>
                </span>

            </div>


            <MorePlansModal
                showMorePlans={showMorePlans}
                handleClickOutside={handleClickOutside}
                extraAdditionalPricingData={extraAdditionalPricingData}
                selectedPlanHeading={selectedPlanHeading}
                handlePlanSelection={handlePlanSelection}
                isWide={isWide}
            />

            <MobileNavigation/>

            {/*{!isRegularButtonVisible &&*/}
            {/*    <div className={'buy-right-now-button-mobile'}*/}
            {/*         onClick={async () => {*/}
            {/*             validateIsLoggedIn().then(async (loggedIn) => {*/}
            {/*                 if (loggedIn) {*/}
            {/*                     setTimeout(() => {*/}
            {/*                         setIsAuthModalOpen(false);*/}
            {/*                     }, 100); // Add delay here*/}

            {/*                     trackButtonClick('clicked_buy_button', {*/}
            {/*                         source: window.location.pathname,*/}
            {/*                         timestamp: Date.now()*/}
            {/*                     });*/}

            {/*                     window.location.href = (yearlyPricing ? selectedPlan.annual.redirect : selectedPlan.monthly.redirect) + `&customer[email]=${encodeURIComponent(await getUserEmail())}`*/}
            {/*                 } else {*/}
            {/*                     setIsAuthModalOpen(true);*/}
            {/*                 }*/}
            {/*             });*/}
            {/*         }}*/}
            {/*    >*/}
            {/*        <CreditCardIcon/>*/}
            {/*        {t('PayWithCard')}*/}
            {/*    </div>*/}
            {/*}*/}
        </>
    );
};

export default NewPricingPage;
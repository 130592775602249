import React, {useEffect, useRef, useState} from 'react';
import './TagSystem.css';
import { useTags } from '../Context/TagContext';
import {useTranslation} from "react-i18next";

const TagItem = ({ tag, selected, onTagClick }) => {
    const [isActive, setIsActive] = useState(false);

    const handleMouseDown = () => {
        if (!selected) {
            setIsActive(true);
        }
    };

    const handleMouseUp = () => {
        setIsActive(false);
    };

    const handleMouseLeave = () => {
        setIsActive(false);
    };

    const {t} = useTranslation();

    return (
        <div
            className={`tag-item ${selected ? 'selected' : ''} ${isActive && !selected ? 'active' : ''}`}
            onClick={() => onTagClick(tag)}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
        >
            {t(tag)}
        </div>
    );
};


const TagList = ({tags, selectedTags, onSelectTag}) => {
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const tagListRef = useRef(null);
    const requestRef = useRef();
    const [isScrolling, setIsScrolling] = useState(false);

    const animateScroll = (direction) => {
        if (tagListRef.current) {
            const scrollAmount = direction === 'left' ? -5 : 5; // Smaller increment for smoother scroll
            tagListRef.current.scrollLeft += scrollAmount;
            requestRef.current = requestAnimationFrame(() => animateScroll(direction));
        }
    };

    const startScrolling = (direction) => {
        if (!isScrolling) {
            setIsScrolling(true);
            animateScroll(direction);
        }
    };

    let stopScrolling;
    stopScrolling = () => {
        if (isScrolling) {
            setIsScrolling(false);
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        }
    };

    // const scrollIntervalRef = useRef(null);
    const handleMouseLeave = () => {
        stopScrolling();
    };

    const handleMouseMove = (e) => {
        const tagListContainer = e.currentTarget.getBoundingClientRect();
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const hoverMargin = 150; // Pixels from edge to trigger scroll

        // Check if cursor is within the bounds of the tag list container
        if (mouseX < tagListContainer.left || mouseX > tagListContainer.right || mouseY < tagListContainer.top || mouseY > tagListContainer.bottom) {
            stopScrolling();
            return;
        }

        if (mouseX - tagListContainer.left < hoverMargin) {
            startScrolling('left');
        } else if (tagListContainer.right - mouseX < hoverMargin) {
            startScrolling('right');
        } else {
            stopScrolling();
        }
    };


    useEffect(() => {
        return () => stopScrolling();
    }, [stopScrolling]);



    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };



    const checkScroll = () => {
        const node = tagListRef.current;
        if (node) {
            const atStart = node.scrollLeft === 0;
            const atEnd = node.scrollLeft + node.offsetWidth >= node.scrollWidth - 1;

            setCanScrollLeft(node.scrollLeft > 0);
            setCanScrollRight(!atEnd);

            if (atStart) {
                node.classList.add('no-blur-left');
            } else {
                node.classList.remove('no-blur-left');
            }

            if (atEnd) {
                node.classList.add('no-blur-right');
            } else {
                node.classList.remove('no-blur-right');
            }
        }
    };


    useEffect(() => {
        const node = tagListRef.current;
        if (node) {
            node.addEventListener('scroll', checkScroll);

            // Apply the no-blur-left class initially
            checkScroll();
        }

        // Cleanup
        return () => {
            if (node) {
                node.removeEventListener('scroll', checkScroll);
            }
        };
    }, []);


    return (
        <div
            className="tag-list-container"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
        >
            <div className={`tag-list ${canScrollLeft ? 'no-blur-left' : ''} ${canScrollRight ? 'no-blur-right' : ''}`}>
                <div className="tag-list-gradient" ref={tagListRef}>
                    {tags.map((tag, index) => (
                        <TagItem
                            key={tag + index}
                            tag={tag}
                            selected={selectedTags.includes(tag)}
                            onTagClick={onSelectTag}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export const TagSystem = ({search, onSelectedTagsChange, reverseOverride = []}) => {
    const {tags, selectedTags, setSelectedTags, handleTagClick} = useTags();

    useEffect(() => {
        onSelectedTagsChange(selectedTags);
        //eslint-disable-next-line
    }, [selectedTags]);

    useEffect(() => {
        if(reverseOverride.length > 0) {
            setSelectedTags(reverseOverride);
        }
        //eslint-disable-next-line
    }, [reverseOverride]);

    useEffect(() => {
        if(search.length > 0) {
            setSelectedTags([]);
        }
        //eslint-disable-next-line
    }, [search]);

    return (
        <TagList
            tags={tags}
            selectedTags={selectedTags}
            onSelectTag={handleTagClick}
        />
    );
};
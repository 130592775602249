import React, {useContext, useEffect, useRef, useState} from 'react';
import Tooltip from '@mui/material/Tooltip';
import './Header.css';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ReactComponent as ElyzaIcon} from '../../icons/ElyzaText.svg';
import SearchBarNew from './Searchbar';
import {ReactComponent as HomeIcon} from './icons/HomeIcon.svg';
import {ReactComponent as ChatIcon} from './icons/ChatIcon.svg';
import {ReactComponent as CreateCharIcon} from './icons/CreateCharIcon.svg';
import {ReactComponent as GlobeIcon} from './icons/GlobeIcon.svg';
import {ReactComponent as UserProfileIcon} from './icons/UserProfileIcon.svg';
import {ReactComponent as MobileMenuIcon} from './icons/MobileMenuIcon.svg'
import {ReactComponent as SearchIcon} from "../../icons/search.svg";
import {CharacterContext} from "../Context/CharacterContext";
import ProfileEdit from "../UserSettings/ProfileEdit";
import {userIDExists} from "../../App";
import {AuthModal} from "../Forms/AuthModal";
import {useTranslation} from "react-i18next";
import {useUserSettings} from "../UserSettings/UserSettingContext";
import MainRewardModal from "../RewardScreen/MainRewardModal";
import ProfileControlMenu from "../UserSettings/ProfileControlMenu/ProfileControlMenu";
import TempLanguageDropdown from "./TempLanguageDropdown";
import i18n from 'i18next';
import SupportModal from "../Modals/SupportModal";
import {useButtonTracking} from "../Analytics/UserAnalyticsContext";
import MobileSlideMenu from "./MobileNavigation/MobileSlideMenu";

export const HeaderMainMenu = ({searchText, setSearchText, setSearchCharacters}) => {
    const location = useLocation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedMenu, setSelectedMenu] = useState(location.pathname);
    const searchbarRef = useRef(null);
    const {realChars, isSearchBarVisible, setIsSearchBarVisible} = useContext(CharacterContext);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
    const headerRef = useRef(null);
    const trackButtonClick = useButtonTracking();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMobileMenuClick = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('userLanguage') || i18n.language;
    });

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            try {
                const loggedIn = await userIDExists();
                setIsLoggedIn(loggedIn);
            } finally {
                setIsLoading(false);
            }
        };

        checkUserLoggedIn();
    }, []);

    const hideSearchBar = location.pathname.includes('/chat') || location.pathname.includes('/generate') || location.pathname.includes('/create-character') || location.pathname.includes('/advanced-creator') || location.pathname.includes('/pricing') || location.pathname.includes('/addon') || location.pathname.includes('/legal') || location.pathname.includes('/gallery') || location.pathname.includes('/c') || location.pathname.includes('/user');

    const navigate = useNavigate();
    const [showCreatorSwitch, setShowCreatorSwitch] = useState(false);
    const isSimpleSelected = location.pathname === '/create-character';
    const isAdvancedSelected = location.pathname === '/advanced-creator';

    const handleOptionClick = (option) => {
        if (option === 'Simple') {
            navigate('/create-character');
        } else if (option === 'Advanced') {
            navigate('/advanced-creator');
        }
    };

    useEffect (() => {
        if (location.pathname === '/create-character' || location.pathname === '/advanced-creator') {
            setShowCreatorSwitch(true);
        } else {
            setShowCreatorSwitch(false);
        }
    } , [location.pathname]);


    const {t} = useTranslation();

    const toggleSearchBarVisibility = () => {
        setIsSearchBarVisible(!isSearchBarVisible);
    };

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists(); // Check login status

        if (!loggedIn) {
            setIsAuthModalOpen(true); // Show login modal
        }

        return loggedIn;
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        let path;

        if (location.pathname === '/') {
            path = '/';
        } else if (location.pathname.startsWith('/chat')) {
            path = '/chat';
        } else if (location.pathname === '/create-character' || location.pathname === '/advanced-creator') {
            path = '/create-character';
        } else if (location.pathname.startsWith('/c')) {
            path = '/';
        } else if (location.pathname.startsWith('/user')) {
            path = '/';
        } else {
            path = location.pathname; // For any other path
        }

        setSelectedMenu(path);
    }, [location]);

    const {
        isProfileEditModalOpen,
        toggleProfileEditModal,
        isMainRewardModalOpen,
        toggleMainRewardModal,
        isSupportModalOpen,
        toggleSupportModal,
    } = useUserSettings();

    const getMenuItems = () => {
        if (isLoading) return [];

        const baseMenuItems = [
            ...(windowWidth >= 680 ? [{name: <HomeIcon/>, tooltip: `${t('Home')}`, path: '/', key: 'home'}] : []),
            ...(windowWidth >= 680 ? [{
                name: <ChatIcon/>,
                tooltip: `${t('Chat')}`,
                path: '/chat',
                requiresLogin: true,
                key: 'chat'
            }] : []),
            ...(windowWidth >= 680 ? [{
                name: <CreateCharIcon/>,
                tooltip: `${t('CreateCharacter')}`,
                path: '/create-character',
                requiresLogin: false,
                key: 'create'
            }] : []),
            ...(windowWidth >= 680 ? [{
                name: <UserProfileIcon/>,
                tooltip: `${t('UserProfile')}`,
                path: '/',
                requiresLogin: true,
                key: 'profile'
            }] : []),
        ];

        let modifiedItems = [...baseMenuItems];

        if (windowWidth < 680 && !hideSearchBar) {
            modifiedItems.push({
                name: <SearchIcon/>,
                tooltip: `${t('SearchNoDot')}`,
                path: '#',
                customAction: toggleSearchBarVisibility,
                key: 'search'
            });
        }

        const mobileMenuItems = windowWidth <= 680 ? [
            isLoggedIn ? {
                name: <MobileMenuIcon/>,
                path: '#',
                customAction: true,
                key: 'mobile-menu'
            } : {
                name: <button className="sign-up-button">{t('Sign Up')}</button>,
                path: '#',
                customAction: true,
                key: 'sign-up',
                onClick: () => setIsAuthModalOpen(true)
            }
        ] : [];

        return [...modifiedItems, ...mobileMenuItems];
    };

    // Get menu items only when loading is complete
    const menuItems = getMenuItems();

    useEffect(() => {
        if (isProfileEditModalOpen || isMainRewardModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isProfileEditModalOpen, isMainRewardModalOpen]);

    const isChatRoute = location.pathname.startsWith('/chat');
    const headerContainerClass = location.pathname.includes('/chat') ? 'header-container-chat' : 'header-container';

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const profileIconRef = useRef(null);

    const handleProfileIconClick = () => {
        validateIsLoggedIn().then(loggedIn => {
            if (loggedIn) {
                setIsDropdownOpen(!isDropdownOpen);
            } else {
                setIsAuthModalOpen(true);
            }
        });

    };

    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

    const openLanguageDropdown = () => {
        trackButtonClick('language_selector_click', {
            current_language: language,
            is_logged_in: isLoggedIn,
            source: location.pathname
        });
        setLanguageDropdownOpen(!languageDropdownOpen);
    }

    const globeIconRef = useRef(null);

    return (
        <>
            <div
                className={`main-header-container ${isSearchBarVisible ? 'expanded' : ''} ${isChatRoute ? 'main-header-container-chat' : ''}`}
                ref={headerRef}
                style={{borderBottom: (location.pathname.includes('/c/') || location.pathname.includes('/user')) ? 'none' : '2px solid #242424'}}
            >

                <div className={headerContainerClass}>

                    <div className="header-icon">
                        <Link to="/">
                            <ElyzaIcon/>
                        </Link>
                    </div>


                    {showCreatorSwitch && windowWidth >= 680 && (
                        <div className="header-char-creator-switch">
                            <div
                                className={`header-switch-option ${isSimpleSelected ? 'selected' : ''}`}
                                onClick={() => handleOptionClick('Simple')}
                            >
                                {t('Simple')}

                            </div>
                            <div
                                className={`header-switch-option ${isAdvancedSelected ? 'selected' : ''}`}
                                onClick={() => handleOptionClick('Advanced')}
                            >
                                {t('Advanced')}

                            </div>

                        </div>
                    )}

                    {!hideSearchBar && windowWidth >= 680 && (
                        <div className="header-searchbar">
                            <SearchBarNew
                                setSearchText={setSearchText}
                                searchText={searchText}
                                characters={null}
                                searchbarRef={null}
                                setSearchCharacters={setSearchCharacters}
                            />
                        </div>
                    )}

                    <div className="header-menu">
                        {!isLoading && ( // Only render menu items after loading
                            <>
                                <div className="menu-item-container" ref={globeIconRef}>
                                    <Tooltip className="tooltip-for-header" title={t('Language')} placement="bottom-end"
                                             arrow>
                                        <div className="header-menu-item" onClick={openLanguageDropdown}>
                                            <GlobeIcon/>
                                        </div>
                                    </Tooltip>
                                    <TempLanguageDropdown
                                        isOpen={languageDropdownOpen}
                                        anchorRef={globeIconRef}
                                        language={language}
                                        onLanguageChange={(newLang) => {
                                            localStorage.setItem('userLanguage', newLang);
                                            setLanguage(newLang);
                                            i18n.changeLanguage(newLang);
                                            setLanguageDropdownOpen(false);
                                        }}
                                        isLoggedIn={isLoggedIn}
                                    />
                                </div>


                                {menuItems.map((menu, index) => (
                                    <div key={index} className="menu-item-container">
                                        {menu.key === 'mobile-menu' ? (
                                            <div
                                                className="header-menu-item"
                                                onClick={handleMobileMenuClick}
                                            >
                                                {menu.name}
                                            </div>
                                        ) : menu.key === 'sign-up' ? (
                                            <div
                                                className="header-menu-item"
                                                onClick={menu.onClick}
                                            >
                                                {menu.name}
                                            </div>
                                        ) : menu.key === 'search' ? (
                                            <Tooltip className="tooltip-for-header" title={menu.tooltip}
                                                     placement="bottom-end"
                                                     arrow>
                                                <div
                                                    className="header-menu-item"
                                                    onClick={menu.customAction}
                                                >
                                                    {menu.name}
                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip className="tooltip-for-header" title={menu.tooltip}
                                                     placement="bottom-end"
                                                     arrow>
                                                {menu.tooltip === `${t('UserProfile')}` ? (
                                                    <div ref={profileIconRef}>
                                                        <div
                                                            className={`header-menu-item ${selectedMenu === menu.path ? 'selected' : ''}`}
                                                            onClick={handleProfileIconClick}
                                                        >
                                                            {menu.name}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <Link
                                                        to={menu.path}
                                                        className={`header-menu-item ${selectedMenu === menu.path ? 'selected' : ''}`}
                                                        onClick={(e) => {
                                                            const isSelected = selectedMenu === menu.path;
                                                            if (menu.requiresLogin && !isSelected) {
                                                                e.preventDefault();
                                                                validateIsLoggedIn().then(loggedIn => {
                                                                    if (loggedIn) {
                                                                        window.location.href = menu.path;
                                                                    } else {
                                                                        setIsAuthModalOpen(true);
                                                                    }
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        {menu.name}
                                                    </Link>
                                                )}
                                            </Tooltip>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>

                </div>

                {isSearchBarVisible && (
                    <div className="header-searchbar-visible">
                        <SearchBarNew
                            setSearchText={setSearchText}
                            searchText={searchText}
                            characters={realChars}
                            searchbarRef={searchbarRef}
                            setSearchCharacters={setSearchCharacters}
                        />
                    </div>
                )}
            </div>


            <ProfileControlMenu
                isOpen={isDropdownOpen}
                onClose={() => setIsDropdownOpen(false)}
            />

            {isProfileEditModalOpen && (
                <ProfileEdit
                    onClose={toggleProfileEditModal}
                    loginError={false} // TODO: Add login error logic

                />
            )}

            <MainRewardModal
                isOpen={isMainRewardModalOpen}
                onClose={toggleMainRewardModal}
            />

            <SupportModal
                isOpen={isSupportModalOpen}
                onClose={toggleSupportModal}
            />

            {isAuthModalOpen && (
                <AuthModal
                    isOpen={isAuthModalOpen}
                    onClose={() => setIsAuthModalOpen(false)}
                />
            )}

            <MobileSlideMenu
                isOpen={isMobileMenuOpen}
                onClose={() => setIsMobileMenuOpen(false)}
                isLoggedIn={isLoggedIn}
                isAuthModalOpen={isAuthModalOpen}
                setIsAuthModalOpen={setIsAuthModalOpen}
            />
        </>
    );
};
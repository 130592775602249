import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from './icons/cross.svg'; // Import the CloseIcon
import {useChat} from "../../Context/ChatContext";
import {getUserAccessToken} from "../../../App";
import {fetchCharacterMemories} from "../../../pages/CharacterCard/CharacterCardFunctions";
import {ReactComponent as CirclePlusIcon} from "./icons/CirclePlusIcon.svg";
import './DropDownShare.css';
import './ShareChatModal.css'
import {ReactComponent as PublicIcon} from "./icons/publicIcon.svg";
import {ReactComponent as ProtectedIcon} from "./icons/protectedIcon.svg";
import {ReactComponent as PrivateIcon} from "./icons/privateIcon.svg";
import {
    ReactComponent as OpenMenuButton
} from "../../ChatComponents/CustomizationScreen/CustomizationIcons/OpenMenuButton.svg";
import {ReactComponent as DeleteIcon} from "../../ChatComponents/CustomizationScreen/CustomizationIcons/DeleteIcon.svg";
import {ReactComponent as EditIcon} from "../../ChatComponents/CustomizationScreen/CustomizationIcons/EditIcon.svg";
import {ReactComponent as ViewIcon} from "../../ChatComponents/CustomizationScreen/CustomizationIcons/ViewIcon.svg";
import {useTranslation} from "react-i18next";
import DeleteShareMemoryModal from "./Modal/DeleteShareMemoryModal";
import EditNameShareMemoryModal from "./Modal/EditNameShareMemoryModal";
import ViewMemoryModal from "../../../pages/CharacterCard/ViewMemoryModal";
import LoadingModal from "../ResponseSettings/LoadingModal";
import {AuthModal} from "../../Forms/AuthModal";

const ShareChatSettingPopup = ({isOpen, onClose, onEdit, onDelete, onView}) => {
    const modalRef = useRef(null);

    const {t} = useTranslation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="smaller-modal-content" ref={modalRef}>
            <div className="edit-delete-container">

                <button onClick={onView} className="custom-button edit-icon-bt">
                    <div className='custom-button-icon'>
                        <ViewIcon/>
                    </div>
                    {t('View')}
                </button>
                <div className="button-separator"/>
                <button onClick={onEdit} className="custom-button middle-icon-bt">
                    <div className='custom-button-icon'>
                        <EditIcon/>
                    </div>
                    {t('Edit')}
                </button>
                <div className="button-separator"/>
                <button onClick={onDelete} className="custom-button delete-icon-bt">
                    <div className='custom-button-icon'>
                        <DeleteIcon/>
                    </div>
                    {t('Delete')}
                </button>
            </div>
        </div>
    );
};

const DropDownShare = ({value, setValue, isDisabled, onDisabledClick, isShaking}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const {t} = useTranslation();

    const options = [
        {
            value: 'public',
            label: `${t('Public')}`,
            subLabel: `${t('Everybody can view, like and share your public chat.')}`,
            Icon: PublicIcon
        },
        {
            value: 'protected',
            label: `${t('Protected')}`,
            subLabel: `${t('Only people with the link can view and copy your chat.')}`,
            Icon: ProtectedIcon
        },
        {
            value: 'private',
            label: `${t('Private')}`,
            subLabel: `${t('Only you can view this chat.')}`,
            Icon: PrivateIcon
        }
    ];


    const handleToggle = () => {
        if (isDisabled) {
            onDisabledClick();
        } else {
            setIsOpen(!isOpen);
        }
    };

    const handleOptionClick = (optionValue) => {
        setValue(optionValue);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const selectedOption = options.find(option => option.value === value);

    return (
        <div
            className={`share-dropdown ${isOpen ? 'is-open' : ''} ${isDisabled ? 'disabled' : ''} ${isShaking ? 'shake' : ''}`}
            ref={dropdownRef}>
            <div className="share-dropdown-control" onClick={handleToggle}>
                {selectedOption && (
                    <>
                        <selectedOption.Icon className="option-icon"/>
                        <div className="option-text">
                            <div className="option-label">{selectedOption.label}</div>
                            <div className="option-sublabel">{selectedOption.subLabel}</div>
                        </div>
                    </>
                )}
                <span className="share-dropdown-arrow"></span>
            </div>
            {isOpen && !isDisabled && (
                <div className="share-dropdown-menu">
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={`Dropdown-option ${option.value === value ? 'is-selected' : ''}`}
                            onClick={() => handleOptionClick(option.value)}
                        >
                            <option.Icon className="option-icon"/>
                            <div className="option-text">
                                <div className="option-label">{option.label}</div>
                                <div className="option-sublabel">{option.subLabel}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const ShareChatModal = ({isOpen, onClose}) => {
    const identityName = window.location.pathname.substring(6);
    const [savedMemories, setSavedMemories] = useState([]);
    const [selectedMemoryId, setSelectedMemoryId] = useState(null);
    const [selectedPrivacyStatus, setSelectedPrivacyStatus] = useState('private');
    const [isShaking, setIsShaking] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [changeMemoryNameModalOpen, setChangeMemoryNameModalOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const [deleteMemoryModalOpen, setDeleteMemoryModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [viewMemoryModalOpen, setViewMemoryModalOpen] = useState(false);
    const [selectedMemory, setSelectedMemory] = useState(null);

    const handleMemorySelection = (memoryId, privacyStatus) => {
        setSelectedMemoryId(memoryId);
        setSelectedPrivacyStatus(privacyStatus);
        const memory = savedMemories.find(mem => mem.id === memoryId);
        setSelectedMemory(memory);
    };

    const {
        setSelectMessageMode,
        reloadShareMemories,
        setReloadShareMemories
    } = useChat();

    const prevIdentityNameRef = useRef(identityName);

    //TODO: pray we didnt make an infinite loop here :D
    useEffect(() => {
        async function fetchPublicMemories() {
            setIsLoading(true);
            try {
                const response = await fetchCharacterMemories(identityName);
                if (response.success && Array.isArray(response.data)) {
                    setSavedMemories(response.data);
                } else {
                    setSavedMemories([]);
                }
            } catch (err) {
                setSavedMemories([]);
            } finally {
                setIsLoading(false);
            }
        }

        if (prevIdentityNameRef.current !== identityName || reloadShareMemories) {
            fetchPublicMemories();

            // Reset reloadShareMemories after fetching
            if (reloadShareMemories) {
                setReloadShareMemories(false);
            }
        }

        if (prevIdentityNameRef.current !== identityName) {
            setSelectedMemoryId(null);
            setSelectedPrivacyStatus('private');
            setOpenMenuId(null);
        }

        fetchPublicMemories();
        prevIdentityNameRef.current = identityName;
    }, [identityName, reloadShareMemories, setReloadShareMemories]);

    const onAddMemory = () => {
        setSelectMessageMode(true);
        onClose();
    }

    const changeShareSettings = async (memoryId, privacyStatus) => {
        setIsLoading(true)
        try {
            const userAccessToken = await getUserAccessToken();

            const response = await fetch('https://api.elyza.app/v1/change-chat-memory-privacy-status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userAccessToken
                },
                body: JSON.stringify({
                    memoryId: memoryId,
                    privacyStatus: privacyStatus,
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update settings');
            }

            setSavedMemories(prevMemories =>
                prevMemories.map(memory =>
                    memory.id === memoryId ? {...memory, privacy_status: privacyStatus} : memory
                )
            );
            await fetchCharacterMemories(identityName)
        } catch (error) {
            console.error('Error updating privacy settings:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const handleShareModeChange = async (newMode) => {
        if (selectedMemoryId) {
            await changeShareSettings(selectedMemoryId, newMode);
            setSelectedPrivacyStatus(newMode);
        } else {
            handleDisabledClick();
        }
    };

    const onInternalCancel = () => {
        onClose();
    }

    const handleDisabledClick = () => {
        setIsShaking(true);
        setShowPopup(true);
        setTimeout(() => setIsShaking(false), 820);
        setTimeout(() => setShowPopup(false), 3000);
    };

    const [openMenuId, setOpenMenuId] = useState(null);

    const handleOpenMenuClick = (event, memoryId) => {
        event.stopPropagation();
        setOpenMenuId(openMenuId === memoryId ? null : memoryId);
    };

    const onItemView = (memoryId) => {
        setSelectedMemoryId(memoryId);
        const memory = savedMemories.find(mem => mem.id === memoryId);
        setSelectedMemory(memory);
        setViewMemoryModalOpen(true);
    }

    //TODO: WHAT THE FUCK DOES THIS SHIT EVEN DO
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const onItemEdit = (memoryId) => {
        setSelectedMemoryId(memoryId);
        const selectedMemory = savedMemories.find(memory => memory.id === memoryId);
        setNewName(selectedMemory ? selectedMemory.memory_name : '');
        setChangeMemoryNameModalOpen(true);
    }

    const onItemDelete = (memoryId) => {
        setSelectedMemoryId(memoryId);
        setDeleteMemoryModalOpen(true);
    };

    const handleDeleteShareMemory = async () => {
        if (!selectedMemoryId) return;
        setIsLoading(true)
        try {
            const userAccessToken = await getUserAccessToken();
            const response = await fetch('https://api.elyza.app/v1/delete-chat-memory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userAccessToken
                },
                body: JSON.stringify({memoryId: selectedMemoryId})
            });

            if (!response.ok) throw new Error('Failed to delete memory');

            setSavedMemories(prevMemories => prevMemories.filter(memory => memory.id !== selectedMemoryId));
            setSelectedMemoryId(null);
            setSelectedPrivacyStatus('private');
            setDeleteMemoryModalOpen(false);
        } catch (error) {
            console.error('Error deleting memory:', error);
        } finally {
            setIsLoading(false)
        }
    }

    const handleEditShareMemory = async () => {
        if (!selectedMemoryId || !newName) return;
        setIsLoading(true)
        try {
            const userAccessToken = await getUserAccessToken();
            const response = await fetch('https://api.elyza.app/v1/edit-chat-memory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userAccessToken
                },
                body: JSON.stringify({memoryId: selectedMemoryId, newName: newName})
            });

            if (!response.ok) throw new Error('Failed to edit memory');

            setSavedMemories(prevMemories =>
                prevMemories.map(memory =>
                    memory.id === selectedMemoryId ? {...memory, memory_name: newName} : memory
                )
            );
            setChangeMemoryNameModalOpen(false);
        } catch (error) {
            console.error('Error editing memory:', error);
        } finally {
            setIsLoading(false)
        }
    }

    const {t} = useTranslation();

    const [showSharePopup, setShowSharePopup] = useState(false);

    const copyLink = async () => {
        // Get the current URL's origin
        const origin = window.location.origin;

        // Construct the new URL with /c/identity_name format
        const newUrl = new URL(`${origin}/c/${identityName}`);

        // Add the memoryId and shareCode as query parameters
        newUrl.searchParams.set('memoryId', selectedMemoryId);
        newUrl.searchParams.set('shareCode', selectedMemory.share_code);

        const shareableLink = newUrl.toString();

        navigator.clipboard.writeText(shareableLink)
            .then(() => {
                setShowSharePopup(true);
                setTimeout(() => setShowSharePopup(false), 2000);
            })
            .catch(err => {
                console.error('Failed to copy link: ', err);
            });
    }

    const SharePopup = ({show}) => (
        <div className={`share-popup ${show ? 'show' : ''}`}>
            {t('urlCopied')}
        </div>
    );

    if (!isOpen) return null;

    const modalContent = (
        <>

            {isUserLoggedIn && (
                <AuthModal
                    isOpen={isUserLoggedIn}
                    onClose={() => setIsUserLoggedIn(false)}
                />
            )}

            <LoadingModal isOpen={isLoading}/>

            <div className="main-space-finder-modal"
                 // style={{backgroundColor: window.innerWidth < 680 ? '#131313' : ''}}
            >
                <div className="app-modal-content-response-settings" onClick={(e) => e.stopPropagation()}>
                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">{t('shareThisChat')}</div>
                        <div className="close-modal-icon" onClick={onInternalCancel}><CloseIcon/></div>
                    </div>
                    <div
                        className="main-elyza-text-response-settings">{t('shareInstructions')}</div>

                    <div className="line-container"
                         style={{marginTop: '10px'}}
                    >
                        <span className="text-line-container">{t('shareSettings')}</span>
                        <div className="line"></div>
                    </div>

                    <div className="main-elyza-text-response-settings">
                        {t('memoryPrivateDescription')}
                    </div>

                    <div className="drop-down-menu"
                         style={{marginBottom: '5px', marginTop: '10px'}}
                    >
                        <DropDownShare
                            value={selectedPrivacyStatus}
                            setValue={handleShareModeChange}
                            isDisabled={!selectedMemoryId}
                            onDisabledClick={handleDisabledClick}
                            isShaking={isShaking}
                        />
                    </div>

                    <div className="line-container">
                        <span className="text-line-container">{t('selectedMemory')}</span>
                        <div className="line"></div>
                    </div>

                    <div className="main-elyza-text-response-settings">
                        {t('memoryStatusUpdate')}
                    </div>

                    <div className="personality-selection-container" style={{marginTop: '10px'}}>
                        <div className="user-profile-grid">
                            {savedMemories.map((memory) => (
                                <div
                                    key={memory.id}
                                    className={`profile-box ${selectedMemoryId === memory.id ? 'selected' : ''}`}
                                    onClick={() => handleMemorySelection(memory.id, memory.privacy_status)}
                                    style={{height: '100px'}}
                                >
                                    <div className='profile-head-header'>
                                        <h2 className='profile-head-header'>{memory.memory_name}</h2>
                                        <OpenMenuButton
                                            className="open-menu-button"
                                            onClick={(event) => handleOpenMenuClick(event, memory.id)}
                                        />
                                        {openMenuId === memory.id && (
                                            <ShareChatSettingPopup
                                                isOpen={true}
                                                onClose={() => setOpenMenuId(null)}
                                                onView={(event) => {
                                                    event.stopPropagation();
                                                    onItemView(memory.id);
                                                }}
                                                onEdit={(event) => {
                                                    event.stopPropagation();
                                                    onItemEdit(memory.id);
                                                }}
                                                onDelete={(event) => {
                                                    event.stopPropagation();
                                                    onItemDelete(memory.id);
                                                }}
                                            />
                                        )}
                                    </div>
                                    <p className='personality-description-creator'>
                                        {memory.privacy_status.charAt(0).toUpperCase() + memory.privacy_status.slice(1)}
                                    </p>
                                </div>
                            ))}

                            {savedMemories.length < 6 && (
                                <div className="profile-box new-profile-box" onClick={onAddMemory}
                                     style={{height: '100px'}}>
                                    <div className="new-profile-content">
                                    <span className="plus-icon">
                                        <CirclePlusIcon/>
                                    </span>
                                        <p>{t('saveMemory')}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="a-straight-line-3" style={{marginTop: '10px', marginBottom: '10px'}}></div>

                    <div className="footer-button-container">
                        {selectedPrivacyStatus !== 'private' && (
                            <div className="share-button-container"
                                 style={{width: '35%'}}
                            >
                                <SharePopup show={showSharePopup}/>
                                <button className="need-help-button"
                                        style={{width: '100%'}}
                                        onClick={copyLink}>
                                    {t('copyLink')}
                                </button>
                            </div>
                        )}
                        <button className="save-settings-button" onClick={onInternalCancel}
                                style={{width: selectedPrivacyStatus !== 'private' ? '65%' : '100%'}}>
                            {t('done')}
                        </button>
                    </div>

                    {showPopup && (
                        <div className="floating-popup">
                            {t('pleaseSelectMemory')}
                        </div>
                    )}
                </div>
            </div>

            <DeleteShareMemoryModal
                isOpen={deleteMemoryModalOpen}
                onClose={() => setDeleteMemoryModalOpen(false)}
                onDeleteConfirm={handleDeleteShareMemory}
            />

            <EditNameShareMemoryModal
                isOpen={changeMemoryNameModalOpen}
                onClose={() => setChangeMemoryNameModalOpen(false)}
                onChangeConfirm={handleEditShareMemory}
                newName={newName}
                setNewName={setNewName}
            />

            <ViewMemoryModal
                isOpen={viewMemoryModalOpen}
                onClose={() => setViewMemoryModalOpen(false)}
                title={selectedMemory ? selectedMemory.memory_name : ''}
                memoryId={selectedMemoryId}
                setIsUserLoggedIn={setIsUserLoggedIn}
                shareCode={selectedMemory ? selectedMemory.share_code : ''}
                privacyStatus={selectedMemory ? selectedMemory.privacy_status : 'private'}
            />
        </>
    );

    return (
        <>
            {ReactDOM.createPortal(modalContent, document.body)}
        </>
    );
};

export default ShareChatModal;


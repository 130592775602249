// MobileRecentChatList.js
import React, { useState, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as GroupChatIcon } from "../../../icons/multiplepeople.svg";
import { useTranslation } from "react-i18next";
import './ChatMainMenu.css';

const MobileRecentChatItem = ({
                                  chat,
                                  isSelected,
                                  onDelete,
                                  onSelect,
                                  formatTimeAgo,
                                  truncateMessage,
                                  profileUsername,
                                  isLastItem
                              }) => {
    const [swipeOffset, setSwipeOffset] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const touchStartX = useRef(0);
    const touchStartTime = useRef(0);
    const DELETE_THRESHOLD = -80;
    const { t } = useTranslation();

    const handleTouchStart = (e) => {
        touchStartX.current = e.touches[0].clientX;
        touchStartTime.current = Date.now();
        setIsDragging(true);
    };

    const handleTouchMove = (e) => {
        if (!isDragging) return;
        const currentX = e.touches[0].clientX;
        const diff = currentX - touchStartX.current;
        if (diff < 0) {
            setSwipeOffset(Math.max(diff, -120));
        } else {
            setSwipeOffset(Math.min(diff, 0));
        }
    };

    const handleTouchEnd = () => {
        setIsDragging(false);
        if (swipeOffset < DELETE_THRESHOLD) {
            setSwipeOffset(-120);
        } else {
            setSwipeOffset(0);
        }
    };

    const confirmDelete = () => {
        onDelete(chat);
        setSwipeOffset(0);
    };

    return (
        <div className={`chat-item-container ${isLastItem ? 'last-item' : ''}`}>
            <div
                className={`sidebar-menu-item ${isSelected ? 'selected' : ''}`}
                style={{
                    transform: `translateX(${swipeOffset}px)`,
                    transition: isDragging ? 'none' : 'transform 0.3s ease',
                }}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onClick={() => swipeOffset === 0 && onSelect(chat)}
            >
                <div className="recent-chat-info">
                    {chat.imageSrc ? (
                        <div className="chat-image-container">
                            <LazyLoadImage
                                className="recent-chat-image"
                                src={`https://elyza.app/cdn-cgi/image/format=avif,width=128,height=128,compression=fast/${chat.imageSrc}`}
                                alt="Chat Image"
                                effect="blur"
                                loading="lazy"
                                draggable="false"
                            />
                        </div>
                    ) : (
                        <GroupChatIcon className="group-chat-icon" />
                    )}
                    <div className="chat-details">
                        <div className="recent-chat-label">{chat.name}</div>
                        <div className="recent-chat-last-message">
                            <span>
                                {truncateMessage(chat.lastMessageContent, 40, chat.name, chat.identityName)}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="recent-chat-time-mobile">
                    {formatTimeAgo(chat.lastMessageTime)}
                </div>
            </div>

            {swipeOffset < DELETE_THRESHOLD && (
                <div className="delete-confirmation-overlay">
                    <button
                        onClick={confirmDelete}
                        className="confirm-delete-btn"
                    >
                        {t('Delete')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default MobileRecentChatItem;
import React, {useState} from 'react';
import realFemale from "../images/styles/realFemale.jpg";
import animeFemale from "../images/styles/animeFemale.jpg";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useTranslation} from "react-i18next";
import './CharCreatorSteps.css';
import {useCharacterCreation} from "../CharacterCreationContext";

const SimpleStepZero = () => {
    const {characterData, updateCharacterData} = useCharacterCreation();
    const [selectedStyle, setSelectedStyle] = useState(characterData.style || 'Anime');
    const {t} = useTranslation();

    const styleToImageMap = {
        'Anime': animeFemale,
        'Real': realFemale,
        // 'Furry': furryFemale,
    };

    const handleStyleSelect = (style) => {
        setSelectedStyle(style);
        updateCharacterData({style});
    };

    // const toggleFurryStyle = () => {
    //     setShowFurry(!showFurry);
    // };

    return (
        <>
            <div className="general-options-container">
                <div className="general-option-wrapper">
                    <div
                        className={`general-option-creator ${selectedStyle === 'Anime' ? 'general-option-creator-selected' : ''}`}
                        onClick={() => handleStyleSelect('Anime')}
                    >
                        <img
                            src={styleToImageMap['Anime']}
                            alt="Anime"
                            className={`general-image-creator ${selectedStyle === 'Anime' ? 'selected' : ''}`}
                            style={{pointerEvents: 'none'}}
                        />
                        <span className='enforced-display-char-creator'>{t('Anime')}</span>
                    </div>
                </div>
                <div className="general-option-wrapper">
                    <div
                        className={`general-option-creator ${selectedStyle === 'Real' ? 'general-option-creator-selected' : ''}`}
                        onClick={() => handleStyleSelect('Real')}
                    >
                        <img
                            src={styleToImageMap['Real']}
                            alt="Real"
                            className={`general-image-creator ${selectedStyle === 'Real' ? 'selected' : ''}`}
                            style={{pointerEvents: 'none'}}
                        />
                        <span className='enforced-display-char-creator'>{t('Realistic')}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SimpleStepZero;
import React, { createContext, useContext, useState} from 'react';

const UserSettingContext = createContext();

export const useUserSettings = () => useContext(UserSettingContext);

export const UserSettingProvider = ({ children }) => {
    const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);
    const [isMainRewardModalOpen, setIsMainRewardModalOpen] = useState(false);
    const [isSupportModalOpen, setIsSupportModalOpen] = useState(false)

    const toggleProfileEditModal = () => {
        setIsProfileEditModalOpen(!isProfileEditModalOpen);
    };

    const toggleMainRewardModal = () => {
        setIsMainRewardModalOpen(!isMainRewardModalOpen);
    };

    const toggleSupportModal = () => {
        setIsSupportModalOpen(!isSupportModalOpen);
    };

    return (
        <UserSettingContext.Provider value={{
            isProfileEditModalOpen,
            toggleProfileEditModal,
            isMainRewardModalOpen,
            toggleMainRewardModal,
            isSupportModalOpen,
            toggleSupportModal
        }}>
            {children}
        </UserSettingContext.Provider>
    );
};
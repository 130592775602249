import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";

const MaintenanceModal = ({ isOpen, onClose, children }) => {

    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-label'>
                    ✋ {t('headsUp')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {t('scheduledMaintenance')} <br></br>
                    <span className="bolder-white-text-notification"> 9 PM PST.</span>
                </div>

                <button onClick={onClose} className="notification-button-main">{t('okay')}</button>
            </div>
        </div>
    );
};

export default MaintenanceModal;
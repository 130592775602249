import React from 'react';
import './UnifiedScreensModals.css';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from "../icons/cross.svg";
import {useTranslation} from "react-i18next";

const AdvancedSuccessModal = ({isOpen, onClose, characterName, startChatting, clearAdvancedOptions}) => {

    const handleCloseModal = () => {
        onClose();
        clearAdvancedOptions();
        window.location.reload();
    }

    const handleStartChatting = () => {
        startChatting();
    }

    const { t } = useTranslation();

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop"
                 style={{alignItems: 'center'}}
            >
                <div className="modal-content-notification"
                     style={{
                         borderRadius: '16px'
                     }}
                >
                    <div className='notification-label'>
                        🎉 {t('SUCCESS')}
                        <div className="modal-close-icon-notification" onClick={handleCloseModal}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            {t('CONGRATULATIONS')} {characterName} {t('CONGRATULATIONS2')}
                            <br></br>
                            <br></br>
                            {t('START_CHATTING')}
                        </div>
                    </div>

                    <button
                        className="notification-button-main"
                        onClick={handleStartChatting}
                    >
                        {t('START_BUTTON')}
                    </button>
                </div>
            </div>
        ),
        document.body
    );
};

export default AdvancedSuccessModal
import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from "./IconsSub/cross.svg";
import {getUserAccessToken, userIDExists} from "../../App";
import './ViewMemoryModal.css';
import {ReactComponent as FavoriteIcon} from "./IconsSub/favorite.svg";
import {ReactComponent as SelectedFavoriteIcon} from "./IconsSub/selectedFavorite.svg";
import {ReactComponent as ShareIcon} from "./IconsSub/share.svg";
import {useProfileData} from "../../components/Context/ProfileDataContext";
import {useNavigate} from "react-router-dom";
import OverrideModal from "../../components/Modals/OverrideModal";
import {useTranslation} from "react-i18next";
import LoadingModal from "../../components/ChatComponents/ResponseSettings/LoadingModal";

const ViewMemoryModal = ({isOpen, onClose, title, memoryId, setIsUserLoggedIn, shareCode = '', privacyStatus = 'private', identityName = '', isUserPage = false}) => {
    const [memoryContent, setMemoryContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFavoriteMemory, setIsFavoriteMemory] = useState(false);
    const [showSharePopup, setShowSharePopup] = useState(false);
    const {profileData} = useProfileData();
    const [showOverrideModal, setShowOverrideModal] = useState(false);
    let willOverride = true;

    const {t} = useTranslation();

    const SharePopup = ({show}) => (
        <div className={`share-popup ${show ? 'show' : ''}`}>
            {t('urlCopied')}
        </div>
    );

    const handleShowOverrideModal = () => {
        if (willOverride === true) {
            setShowOverrideModal(true);
        } else {
            handleCloneMemory()
        }
    };

    const fetchCharacterMemoriesByMemoryId = async (memoryId, shareCode = '') => {
        const response = await fetch('https://api.elyza.app/v1/render-chat-memory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({memoryId, shareCode})
        });

        return response.json();
    };

    useEffect(() => {
        if (isOpen && memoryId && memoryContent === null) {
            setIsLoading(true);
            fetchCharacterMemoriesByMemoryId(memoryId, shareCode)
                .then(data => {
                    setMemoryContent(data.messages.reverse());
                    setIsLoading(false);
                })
                .catch(err => {
                    console.error('Error fetching memory:', err);
                    setIsLoading(false);
                });
        }
    }, [isOpen, memoryContent, memoryId, shareCode]);

    useEffect(() => {
        if (profileData && profileData.liked_memories) {
            setIsFavoriteMemory(profileData.liked_memories.includes(`${memoryId}`));
        }
        //eslint-disable-next-line
    }, [memoryId, profileData]);

    const [cloningMemory, setCloningMemory] = useState(false);

    const handleCloneMemory = async (override = false) => {
        setCloningMemory(true);
        const response = await fetch('https://api.elyza.app/v1/clone-chat-memory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({
                memoryId: memoryId,
                override: override,
            }),
        });

        const json = await response.json();
        if (json.success) {
            navigate(`/chat/${json.identity_name}`, { state: { autoSelect: true } });
        } else {
            // TODO: Error message for cloning memory
        }
        // TODO: -> Close loading screen
        setCloningMemory(false);
    }

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const validateIsLoggedIn = async () => {
        const loggedIn = await userIDExists();
        if (!loggedIn) setIsUserLoggedIn(true);
        return loggedIn;
    };

    const handleFavoriteClick = async () => {
        if (await validateIsLoggedIn()) { // broken z index TODO BICH
            if (isFavoriteMemory) {
                setIsFavoriteMemory(false);
                await fetch('https://api.elyza.app/v1/remove-like-memory', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken()
                    },
                    body: JSON.stringify({memoryId: `${memoryId}`})
                });
            } else {
                setIsFavoriteMemory(true);
                await fetch('https://api.elyza.app/v1/like-memory', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken()
                    },
                    body: JSON.stringify({memoryId: memoryId})
                });
            }
            //TODO: favorite? memory -> NEED API AND SORTING DEN
        }
    };

    const handleShareClick = () => {
        const currentUrl = new URL(window.location.href);

        if(isUserPage) {
            currentUrl.pathname = currentUrl.pathname.replace(/\/[^/]*$/, '/');
            currentUrl.pathname = currentUrl.pathname.replace(/\/user\//, '/');
            currentUrl.pathname += 'c/' + identityName;
        }

        currentUrl.search = '';

        currentUrl.searchParams.set('memoryId', memoryId);
        currentUrl.searchParams.set('shareCode', shareCode);

        const shareableLink = currentUrl.toString();

        navigator.clipboard.writeText(shareableLink)
            .then(() => {
                setShowSharePopup(true);
                setTimeout(() => setShowSharePopup(false), 2000);
            })
            .catch(err => {
                console.log('Failed to copy link: ', err);
            });
    };

    const renderMessages = (messages) => {
        let botFirst = messages[messages.length - 1].isUser ? false : true;
        return messages.map((message, index) => {
            const isBot = (index % 2 === 0) === botFirst;
            return (
                <div
                    key={message.id || index}
                    className={`memory-message ${isBot ? 'memory-message-bot' : 'memory-message-user'}`}
                >
                    {message.content}
                </div>
            );
        });
    };

    const navigate = useNavigate();

    const handleUsernameClick = (username) => {
        navigate('/user/' + username)
    }

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <>
                <LoadingModal isOpen={cloningMemory}/>

                <div className="modal-backdrop">

                    <div className="view-memory-modal-main">
                        <div className="public-chat-upper"
                             style={{
                                 paddingLeft: '20px',
                                 paddingRight: '20px',
                                 paddingTop: '15px',
                                 width: '100%',
                                 marginBottom: '0px',
                                 cursor: 'unset'

                             }}
                        >
                            <img
                                src={'profileUrl'}
                                alt={''}
                                className='user-avatar'
                                style={{height: '46px', width: '46px'}}
                            />

                            <div className="public-chat-author">
                            <span className="public-chat-by-author">
                            By
                            </span>
                                <br/>
                                <button
                                    style={{
                                        paddingLeft: '0px',
                                        color: '#AEAEAE',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        fontSize: '0.90em'
                                    }}
                                    onClick={() => handleUsernameClick(profileData.tagname)}>
                                    @{profileData.tagname}

                                </button>
                            </div>
                            <div className="modal-close-icon-notification"
                                 style={{marginLeft: 'auto'}}
                                 onClick={onClose}>
                                <CloseIcon/>
                            </div>
                        </div>

                        <div className='modal-header-memory'>
                            <h2>{title}</h2>
                        </div>

                        <div className="modal-content-memory">
                            {isLoading ? (
                                <div className="loading-message">
                                    <div className="loading-message-bot"></div>
                                    <div className="loading-message-user"></div>
                                </div>
                            ) : memoryContent ? (
                                <div className='memory-content'>
                                    {renderMessages(memoryContent)}
                                </div>
                            ) : (
                                <div className='no-content-message'>
                                    {t('No memory content available.')}
                                </div>
                            )}
                        </div>
                        <div className="character-card-button-container"
                             style={{
                                 marginTop: '0px',
                                 flexDirection: 'row',
                                 paddingLeft: '24px',
                                 paddingRight: '24px',
                                 marginBottom: '20px',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                                 width: '100%'
                             }}
                        >
                            <button className="ai-girlfriend-button"
                                    style={{backgroundColor: '#916DE8'}}
                                    onClick={handleShowOverrideModal}
                            >
                                {t('Load Memory')}
                            </button>

                            <div className="share-favorite-button-container"
                                 style={{width: 'unset'}}
                            >
                                <button
                                    onClick={() => handleFavoriteClick()}
                                    className='share-favorite-button'
                                    style={{height: '50px', width: '65px'}}
                                >
                                    {isFavoriteMemory ? <SelectedFavoriteIcon/> : <FavoriteIcon/>}
                                </button>
                                {privacyStatus !== 'private' ? <div className="share-button-container">
                                    <SharePopup show={showSharePopup}/>
                                    <button
                                        onClick={handleShareClick}
                                        className='share-favorite-button'
                                        style={{height: '50px', width: '65px'}}
                                    >
                                        <ShareIcon/>
                                    </button>
                                </div> : null}
                            </div>
                        </div>
                    </div>

                    <OverrideModal
                        isOpen={showOverrideModal}
                        onClose={() => setShowOverrideModal(false)}
                        overrideChat={() => handleCloneMemory(true)}
                    />
                </div>
            </>
        ),
        document.body
    );
};

export default ViewMemoryModal;
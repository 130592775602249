import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 720);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 720);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Try to get the main scrollable container
        const mainContainer = document.querySelector('.character-card-page') || document.documentElement;

        const toggleVisibility = () => {
            const scrollTop = mainContainer.scrollTop || document.documentElement.scrollTop || document.body.scrollTop;

            if (scrollTop > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        // Listen to scroll events on both the container and document
        mainContainer.addEventListener('scroll', toggleVisibility);
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            mainContainer.removeEventListener('scroll', toggleVisibility);
            window.addEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        // Try to get the main scrollable container
        const mainContainer = document.querySelector('.character-card-page') || document.documentElement;

        // Scroll the container if it exists
        if (mainContainer) {
            mainContainer.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        // Also scroll the window as a fallback
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Only render on mobile
    if (!isMobile) return null;

    return (
        <button
            onClick={scrollToTop}
            style={{
                display: isVisible ? 'flex' : 'none',
                position: 'fixed',
                bottom: '120px',
                right: '20px',
                zIndex: 1000,
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#916DE8',
                border: 'none',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'opacity 0.3s, transform 0.3s',
                opacity: isVisible ? 1 : 0,
                transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
            }}
            aria-label="Scroll to top"
        >
            <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M12 19V5M5 12l7-7 7 7" />
            </svg>
        </button>
    );
};

export default ScrollToTop;
import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import { useTranslation } from 'react-i18next';

const PlanSuccess = ({isOpen, onClose, plan}) => {
    const { t } = useTranslation();

    //TODO: make sure logic on plan purchase doesnt apply here anymore lol
    if (!isOpen) return null;
    const displayPlan = plan === 'Gold' ? 'Ultimate' : plan;

    const planBenefits = {
        'Basic': {tokens: 60},
        'Premium': {tokens: 125},
        'Gold': {tokens: 600},
    };

    const benefits = planBenefits[plan] || {tokens: 0}; // provide a default value if plan is not a valid key
    return (
        <div className="modal-backdrop"
             style={{alignItems: 'center'}}
        >
            <div className="modal-content-notification"
                 style={{
                     borderRadius: '16px'
                 }}
            >
                <div className='notification-label'>
                    🎉 {t('woohoo')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">
                    <span className='sublabel-notification'>
                        {t('WelcomeToThe')}
                        <span className="colored-purple-text"> Elyza {t(displayPlan)}</span>
                    </span>

                    <div className='sublabel-notification'>
                        {t('YourPlanComesWith')}
                        <span className="colored-purple-text"> {benefits.tokens} {t('tokens')}</span>
                        , {t('WhichCanBeSpentOn')}
                    </div>

                </div>

                <button onClick={onClose} className="notification-button-main">{t('Okay')}</button>

            </div>
        </div>
    );
};

export default PlanSuccess;

import React, {useEffect, useRef, useState} from 'react';
import './ProfileEdit.css';
import {getUserAccessToken, getUserEmail, getUserID, supabase} from "../../App";
// import {AuthModal} from "../Forms/AuthModal";
import {Link, useNavigate} from "react-router-dom";
import {ReactComponent as EditIcon} from "./ProfileIcons/Edit.svg";
import {ReactComponent as Visa} from "./ProfileIcons/Visa.svg"
import {ReactComponent as Card} from "./ProfileIcons/Card.svg"
import {ReactComponent as Crypto} from "./ProfileIcons/Crypto.svg"
import {ReactComponent as FreeCard} from "./ProfileIcons/FreeCard.svg"
import {ReactComponent as CloseIcon} from "./ProfileIcons/Close.svg"
import Tooltip from '@mui/material/Tooltip';
import {ReactComponent as VisaGray} from "./ProfileIcons/FreeMoney.svg";
// import ReferralMenu from "../Banners/Referral/ReferralMenu";
import {ReactComponent as DefaultProfile} from "./ProfileIcons/default-profile.svg";
import {useProfileData} from "../Context/ProfileDataContext";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {useTranslation} from 'react-i18next';
import NameChangePopup from "./NameChangePopup";
import EmailChangePopup from "./EmailChangePopup";
import 'react-dropdown/style.css';
import Dropdown from 'react-dropdown';

const ProfileEdit = ({onSave, onClose}) => {

    const profileEditRef = useRef(null);
    const navigate = useNavigate();
    const [showNameChangePopup, setShowNameChangePopup] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [email, setEmail] = useState('');
    const displayEmail = email.length > 20 ? `${email.substring(0, 16)}...` : email;
    const [usernameChanged, setUsernameChanged] = useState('');
    const [showEmailChangePopup, setShowEmailChangePopup] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [emailChangeStatus, setEmailChangeStatus] = useState('');
    const dropdownRef = useRef(null);
    const {i18n} = useTranslation();

    const {profileData, language, setLanguage} = useProfileData();

    const languageCodeToEnglishName = {
        'en': 'English',
        'es': 'Spanish',
        'fr': 'French',
        'ru': 'Russian',
        'de': 'German',
        'it': 'Italian',
        'pl': 'Polish',
        'pt': 'Portuguese',
        'ja': 'Japanese',
        'ko': 'Korean',
        'zh': 'Chinese'
    };

    const mapLanguageCodeToEnglishName = (code) => {
        return languageCodeToEnglishName[code] || code;
    };

    const languages = [
        {code: 'en', name: '🇺🇸 English'},
        {code: 'es', name: '🇪🇸 Español'},
        {code: 'fr', name: '🇫🇷 Français'},
        {code: 'ru', name: '🇷🇺 Русский'},
        {code: 'de', name: '🇩🇪 Deutsch'},
        {code: 'it', name: '🇮🇹 Italiano'},
        {code: 'pl', name: '🇵🇱 Polski'},
        {code: 'pt', name: '🇵🇹 Portuguese'},
        {code: 'ja', name: '🇯🇵 日本語'},
        {code: 'ko', name: '🇰🇷 한국어'},
        {code: 'zh', name: '🇨🇳 中文 (简体)'}
    ];


    const handleLanguageChange = async (e) => {
        const newLang = e.target.value;
        setLanguage(newLang);
        i18n.changeLanguage(newLang);

        try {
            const response = await fetch('https://api.elyza.app/v1/change-language', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': await getUserAccessToken()
                },
                body: JSON.stringify({language: mapLanguageCodeToEnglishName(newLang)}),
            });

            if (!response.ok) {
                throw new Error('Failed to update language on server');
            }
        } catch (error) {
            console.error('Error updating language:', error);
        }
    };

    useEffect(() => {
        setLanguage(i18n.language);
        //eslint-disable-next-line
    }, [i18n.language]);

    const options = languages.map(lang => ({
        value: lang.code,
        label: lang.name
    }));

    const setCurrentName = (newUsername) => {
        profileData.username = newUsername;
    }
    let subscriptionType = profileData.subscription_data.type;
    subscriptionType = subscriptionType === 'MONTH' ? 'Monthly' : subscriptionType === 'ANNUAL' ? 'Annually' : subscriptionType;

    let lastFourDigits = profileData.subscription_data.lastFourDigits;
    let customerId = profileData.subscription_data.customerId;
    let lastPayment = profileData.subscription_data.lastPaymentTimestamp;
    let nextPayment = profileData.subscription_data.nextRenewalTimestamp;
    const [profileUrl, setProfileUrl] = useState(profileData.profile);

    const handleClosePopup = () => {
        setShowNameChangePopup(false);
        setShowEmailChangePopup(false);
    };

    const updateUserData = async (base64String = "") => {
        try {
            const userId = await getUserID();
            const body = {
                userId: userId, imageBase64: base64String, // Handle image data as needed
                newUsername: newUsername, // Use the state variable directly
                // newBio: "",
                justRegistered: false, genderPreference: '',
                language: languageCodeToEnglishName[i18n.language] ?? "English",
            };
            const response = await fetch('https://api.elyza.app/v1/update-profile', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                }, body: JSON.stringify(body),
            });

            if (response.ok) {
                setUsernameChanged('success'); // Set success message
                setCurrentName(newUsername); // Update the current name state
            } else {
                setUsernameChanged('error'); // Set error message
            }
        } catch (error) {
            console.error('Error updating user data:', error);
            setUsernameChanged('error'); // Set error message
        }
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const month = t(`month${date.getMonth() + 1}`); // Correct way to use t function
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month} ${day}${getDaySuffix(day)}, ${year}`;
    }

    function getDaySuffix(day) {
        const lastDigit = day % 10;
        const lastTwoDigits = day % 100;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
            return "th";
        }

        switch (lastDigit) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    useEffect(() => {
        if (profileData.username.length === 0) {
            setCurrentName('Loading...');
        }

        const fetchUserData = async () => {
            const userID = await getUserID();
            if (!userID) return;

            const response = await fetch('https://api.elyza.app/v1/profile-data', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', 'Authorization': await getUserAccessToken(),
                }, body: JSON.stringify({userId: userID}),
            });

            const data = await response.json();

            setCurrentName(data.username);

        }

        fetchUserData();
        //eslint-disable-next-line
    }, [profileData.username]);

    const internalOnSave = async () => {
        try {
            await updateUserData();
            setShowNameChangePopup(false); // Hide the popup after saving
            onSave();
        } catch (error) {
            console.error('Error saving user data:', error);
            setUsernameChanged('error'); // Set error message
        }
    };

    const handleNameChangeClick = () => {
        setNewUsername(profileData.username); // Initialize with current name
        setShowNameChangePopup(true);
    };

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (profileEditRef.current && !profileEditRef.current.contains(event.target)) {
                setShowNameChangePopup(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [profileEditRef]);

    useEffect(() => {
        const fetchAndSetUserEmail = async (setEmailFunction) => {
            try {
                const userEmail = await getUserEmail(); // Function to get the user's email
                setEmailFunction(userEmail); // Callback function to handle the fetched email
            } catch (error) {
                console.error('Error fetching user email:', error);
            }
        };

        fetchAndSetUserEmail(setNewEmail); // Assuming you want to fetch and set the current email as the initial state
    }, []);

    const fetchAndSetUserEmail = async (setEmailFunction) => {
        try {
            const userEmail = await getUserEmail(); // Function to get the user's email
            setEmailFunction(userEmail); // Callback function to handle the fetched email
        } catch (error) {
            console.error('Error fetching user email:', error);
        }
    };

    useEffect(() => {
        // Assuming fetchAndSetUserEmail is defined elsewhere and imported
        fetchAndSetUserEmail(setEmail);
    }, []);

    const handleNewEmailChange = (e) => {
        setShowEmailChangePopup(true);
        setNewEmail(e.target.value);
    };

    const updateEmail = async () => {
        const userId = await getUserID();
        if (!userId) {
            setEmailChangeStatus('error');
            return;
        }
        //eslint-disable-next-line
        const {data, error} = await supabase.auth.updateUser({
            email: newEmail
        })

        if (error) {
            setEmailChangeStatus('error');

        } else {
            setEmailChangeStatus('success');

        }
    };

    const [isWide, setIsWide] = useState(window.innerWidth > 680);
    useEffect(() => {
        const handleResize = () => {
            setIsWide(window.innerWidth > 680);
        };


        window.addEventListener('resize', handleResize);


        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [theme, setTheme] = useState('Dark');
    const themeSelectRef = useRef(null);

    const themes = ['Dark'];

    const handleThemeChange = (e) => {
        setTheme(e.target.value);
    };

    const focusSelect = (selectRef) => {
        selectRef.current?.focus();
    };

    const {t} = useTranslation();

    return (
        <>
            <div className="profile-edit-overlay">
                <div className="profile-container-menu" ref={profileEditRef}>
                    {!isWide && (
                        <>
                            <div className="profile-cont-t1">
                                <div className="profile-setting-label">{t('YourProfile')}
                                    <div className="close-modal-icon-profile"
                                         onClick={onClose}
                                    >
                                        <CloseIcon/>
                                    </div>
                                </div>
                                <div className="main-elyza-text-response-settings">{t('ProfileDetails')}
                                </div>
                            </div>
                            <div className="line-container">
                                <span className="text-line-container">GENERAL</span>
                                <div className="line"></div>
                            </div>
                            <div className="profile-cont-t2">

                                <Tooltip title='Upload Profile' arrow>
                                    <div className="profile-circle">
                                        <label htmlFor="image-upload" className="image-upload-label">
                                            {profileUrl ? (<LazyLoadImage
                                                alt={""}
                                                className="profile-image"
                                                placeholderSrc={DefaultProfile}
                                                src={profileUrl}
                                            />) : (<div className="default-avatar"></div>)}
                                            <input
                                                type="file"
                                                id="image-upload"
                                                accept="image/*"
                                                onChange={async (event) => {
                                                    const file = event.target.files[0];
                                                    if (file) {
                                                        const reader = new FileReader();
                                                        reader.onloadend = () => {
                                                            const base64String = reader.result;
                                                            setProfileUrl(base64String);

                                                            // const base64Data = base64String.split(',')[1]; // This removes the data URL prefix
                                                            updateUserData(base64String);
                                                            // const base64String = reader.result;
                                                            // const base64Data = base64String.split(',')[1];

                                                        };
                                                        reader.readAsDataURL(file);
                                                    }
                                                }}
                                                style={{display: 'none'}}
                                            />
                                        </label>
                                    </div>
                                </Tooltip>

                                <div className="grid-4-profile">

                                    <div className="grid-profile-item">
                                        <label className="info-label">{t('Username')}
                                            <EditIcon className="edit-settings-icon" onClick={handleNameChangeClick}/>
                                        </label>
                                        <Tooltip title={profileData.username} arrow>
                                            <div className="info-content">
                                                {profileData.username.length > 15
                                                    ? `${profileData.username.substring(0, 15)}...`
                                                    : profileData.username
                                                }
                                            </div>
                                        </Tooltip>
                                    </div>

                                    <div className="grid-profile-item">
                                        <label className="info-label">{t('Email')}
                                            {/*<EditIcon className="edit-settings-icon" onClick={handleNewEmailChange}/>*/}
                                        </label>
                                        <div className="info-content">
                                            <Tooltip title={email} arrow>
                                                <span>{displayEmail}</span>
                                            </Tooltip>
                                        </div>
                                    </div>


                                    <div className="grid-profile-item">
                                        <label className="info-label">
                                            {t('Language')}
                                            <EditIcon
                                                className="edit-settings-icon"
                                            />
                                        </label>
                                        <Dropdown
                                            ref={dropdownRef}
                                            options={options}
                                            value={language}
                                            onChange={(option) => handleLanguageChange({target: {value: option.value}})}
                                            className="info-content-dropdown"
                                            controlClassName="info-content-dropdown-control"
                                            menuClassName="info-content-dropdown-menu"
                                            arrowClassName="info-content-dropdown-arrow"
                                        />
                                    </div>


                                    <div className="grid-profile-item">
                                        <label className="info-label">
                                            {t('Theme')}
                                            <EditIcon
                                                className="edit-settings-icon"
                                                onClick={focusSelect}
                                            />
                                        </label>
                                        <Dropdown
                                            ref={themeSelectRef}
                                            options={themes}
                                            value={theme}
                                            onChange={(option) => handleThemeChange({target: {value: option.value}})}
                                            className="info-content-dropdown"
                                            controlClassName="info-content-dropdown-control"
                                            menuClassName="info-content-dropdown-menu"
                                            arrowClassName="info-content-dropdown-arrow"
                                        />
                                    </div>

                                </div>
                            </div>
                        </>)}


                    {isWide && (<>
                        <div className="profile-cont-t1">
                            <div className="profile-setting-label">{t('YourProfile')}
                                <div className="close-modal-icon-profile"
                                     onClick={onClose}
                                >
                                    <CloseIcon/>
                                </div>

                            </div>
                            <div className="main-elyza-text-response-settings">{t('ProfileDetails')}</div>
                        </div>

                        <div className="line-container">
                            <span className="text-line-container">GENERAL</span>
                            <div className="line"></div>
                        </div>

                        <div className="profile-cont-t2">

                            <Tooltip title='Upload Profile' arrow>
                                <div className="profile-circle">
                                    <label htmlFor="image-upload" className="image-upload-label">
                                        {profileUrl ? (
                                            <img
                                                alt={""}
                                                className="profile-image"
                                                src={profileUrl}
                                            />) : (<div className="default-avatar"></div>)}
                                        <input
                                            type="file"
                                            id="image-upload"
                                            accept="image/*"
                                            onChange={async (event) => {
                                                const file = event.target.files[0];
                                                if (file) {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => {
                                                        const base64String = reader.result;
                                                        setProfileUrl(base64String);

                                                        // const base64Data = base64String.split(',')[1]; // This removes the data URL prefix
                                                        updateUserData(base64String);
                                                        // const base64String = reader.result;
                                                        // const base64Data = base64String.split(',')[1];

                                                    };
                                                    reader.readAsDataURL(file);
                                                }
                                            }}
                                            style={{display: 'none'}}
                                        />
                                    </label>
                                </div>
                            </Tooltip>


                            <div className="grid-4-profile">

                                <div className="grid-profile-item">
                                    <label className="info-label">{t('Username')}
                                        <EditIcon className="edit-settings-icon" onClick={handleNameChangeClick}/>
                                    </label>
                                    <Tooltip title={profileData.username} arrow>
                                        <div className="info-content">
                                            {profileData.username.length > 15
                                                ? `${profileData.username.substring(0, 15)}...`
                                                : profileData.username
                                            }
                                        </div>
                                    </Tooltip></div>

                                <div className="grid-profile-item">
                                    <label className="info-label">{t('Email')}
                                        {/*<EditIcon className="edit-settings-icon" onClick={handleNewEmailChange}/>*/}
                                    </label>
                                    <div className="info-content">
                                        <Tooltip title={email} arrow>
                                            <span>{displayEmail}</span>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="grid-profile-item">
                                    <label className="info-label">
                                        {t('Language')}
                                        <EditIcon
                                            className="edit-settings-icon"
                                            // onClick={focusDropdown}
                                        />
                                    </label>
                                    <Dropdown
                                        ref={dropdownRef}
                                        options={options}
                                        value={language}
                                        onChange={(option) => handleLanguageChange({target: {value: option.value}})}
                                        className="info-content-dropdown"
                                        controlClassName="info-content-dropdown-control"
                                        menuClassName="info-content-dropdown-menu"
                                        arrowClassName="info-content-dropdown-arrow"
                                    />
                                </div>

                                <div className="grid-profile-item">
                                    <label className="info-label">
                                        {t('Theme')}
                                        <EditIcon
                                            className="edit-settings-icon"
                                            onClick={focusSelect}
                                        />
                                    </label>
                                    <Dropdown
                                        ref={themeSelectRef}
                                        options={themes}
                                        value={theme}
                                        onChange={(option) => handleThemeChange({target: {value: option.value}})}
                                        className="info-content-dropdown"
                                        controlClassName="info-content-dropdown-control"
                                        menuClassName="info-content-dropdown-menu"
                                        arrowClassName="info-content-dropdown-arrow"
                                    />
                                </div>

                            </div>
                        </div>
                    </>)}

                    <div className="line-container">
                        <span className="text-line-container">PAYMENT</span>
                        <div className="line"></div>
                    </div>

                    <div className="profile-cont-t3">
                        <div className="slider-label"
                             style={{marginBottom: '5px'}}
                        >{t('BillingInfo')}</div>
                        <div className="main-elyza-text-response-settings">{t('ManageBilling')}</div>
                    </div>


                    <div className="profile-cont-t4">

                        <div className="grid-payment-2">

                            <div className="active-plan-container">

                                <label className="info-sub-2-label">{t('ActivePlan')}
                                    <EditIcon
                                        className="edit-settings-icon"
                                        onClick={async () => {
                                            if (customerId === undefined) {
                                                navigate('/pricing')
                                            }

                                            const response = await fetch('https://api.elyza.app/v1/profile-data-update', {
                                                method: 'POST', headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': await getUserAccessToken(),
                                                }, body: JSON.stringify({
                                                    userId: await getUserID(),
                                                    newName: "",
                                                    newEmail: "",
                                                    newLanguage: "",
                                                    requestSelfServePortal: true,
                                                    customerId: customerId,
                                                }),
                                            });

                                            if (!response.ok) {
                                                throw new Error(`HTTP error ${response.status}`);
                                            }

                                            const data = await response.json();
                                            window.location.href = data.url;
                                        }}
                                    />

                                </label>
                                <div className="sub-container">


                                    {profileData.subscription_data.tier === 'FREE' ?
                                        <FreeCard className="sub-cart-details"
                                                  style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                      if (profileData.subscription_data.tier === 'FREE') {
                                                          window.location.href = '/pricing';
                                                      }
                                                  }}

                                        /> : <Card className="sub-cart-details"/>

                                    }
                                    <div className="container-text-capture">


                                        <div className="plan-length">{subscriptionType}</div>
                                        <div
                                            className={`plan-type ${profileData.subscription_data.tier === 'FREE' ? 'free-plan' : ''}`}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                if (profileData.subscription_data.tier === 'FREE') {
                                                    window.location.href = '/pricing';
                                                }
                                            }}
                                        >
                                            Elyza {profileData.subscription_data.tier === 'GOLD' ? 'ULTIMATE' : profileData.subscription_data.tier}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-method-container">
                                <label className="info-sub-2-label">{t('PaymentMethod')}
                                    <EditIcon
                                        className="edit-settings-icon"
                                        onClick={async () => {
                                            if (customerId === undefined) {
                                                navigate('/pricing')
                                            }

                                            const response = await fetch('https://api.elyza.app/v1/profile-data-update', {
                                                method: 'POST', headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': await getUserAccessToken(),
                                                }, body: JSON.stringify({
                                                    userId: await getUserID(),
                                                    newName: "",
                                                    newEmail: "",
                                                    newLanguage: "",
                                                    requestSelfServePortal: true,
                                                    customerId: customerId,
                                                }),
                                            });

                                            if (!response.ok) {
                                                throw new Error(`HTTP error ${response.status}`);
                                            }

                                            const data = await response.json();
                                            window.location.href = data.url;
                                        }}
                                    />
                                </label>
                                <div className="act-container">
                                    {profileData.subscription_data.tier === 'FREE' ? (
                                        <VisaGray
                                            className="sub-cart-details"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                if (profileData.subscription_data.tier === 'FREE') {
                                                    window.location.href = '/pricing';
                                                }
                                            }}
                                        />
                                    ) : (
                                        lastFourDigits === '0000' ? (
                                            <Crypto className="sub-cart-details"/>
                                        ) : (
                                            <Visa className="sub-cart-details"/>
                                        )
                                    )}

                                    <div
                                        className={`container-text-capture ${profileData.subscription_data.tier === 'Free' ? 'free-plan' : ''}`}>
                                        {profileData.subscription_data.tier === 'FREE' ? (
                                            <div className="plan-length"
                                                 style={{cursor: 'pointer'}}
                                                 onClick={() => {
                                                     if (profileData.subscription_data.tier === 'FREE') {
                                                         window.location.href = '/pricing';
                                                     }
                                                 }}
                                            >None</div>) : (<>
                                            <div className="plan-length">{t('EndingIn')}</div>
                                            <div className="plan-type-2">
                                                <svg height="10" width="10">
                                                    <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                </svg>
                                                <svg height="10" width="10">
                                                    <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                </svg>
                                                <svg height="10" width="10">
                                                    <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                </svg>
                                                <svg height="10" width="10">
                                                    <circle cx="5" cy="5" r="4" fill="currentColor"/>
                                                </svg>
                                                {lastFourDigits}
                                            </div>
                                        </>)}
                                    </div>
                                </div>
                            </div>

                        </div>


                        {profileData.subscription_data.tier !== "FREE" && (<>
                                <div
                                    style={{color: '#c4c4c4', fontWeight: 'normal', fontSize: '0.95em'}}
                                >{t('LastPayment')}
                                    <span
                                        style={{color: '#fff', fontWeight: 'bold', fontSize: '0.95em'}}
                                    > {formatTimestamp(lastPayment)} EST.</span>
                                </div>
                                <div
                                    style={{color: '#c4c4c4', fontWeight: 'normal', fontSize: '0.95em'}}
                                >{t('NextPayment')}
                                    <span
                                        style={{color: '#fff', fontWeight: 'bold', fontSize: '0.95em'}}
                                    > {formatTimestamp(nextPayment)} EST.</span>
                                </div>
                            </>
                        )}

                        <span className="terms-link">
                         <Link to="/legal/other">18 U.S.C. § 2257 Exemption</Link>
                        </span>

                    </div>

                    {showNameChangePopup && (
                        <NameChangePopup
                            handleClosePopup={handleClosePopup}
                            setNewUsername={setNewUsername}
                            internalOnSave={internalOnSave}
                            usernameChanged={usernameChanged}
                            newUsername={newUsername}
                        />
                    )}

                    {showEmailChangePopup && (
                        <EmailChangePopup
                            handleClosePopup={handleClosePopup}
                            newEmail={newEmail}
                            handleNewEmailChange={handleNewEmailChange}
                            emailChangeStatus={emailChangeStatus}
                            updateEmail={updateEmail}
                        />
                    )}
                </div>
            </div>
        </>

    );
};

export default ProfileEdit;
import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import {useProfileData} from "../../../components/Context/ProfileDataContext";
import {useTranslation} from "react-i18next";
import './ExpandableDescription.css';

const ExpandableDescriptionMobile = ({ description, maxLength, charName }) => {
    const { profileData } = useProfileData();
    let username = profileData.username;

    const {t} = useTranslation();

    const [isExpanded, setIsExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const contentRef = useRef(null);
    const containerRef = useRef(null);

    const correctDescription = description
        .replace(/{{char}}/gi, charName)
        .replace(/{{user}}/g, username)
        .replace(/user/g, username)
        .replace(/{char}/g, charName)
        .replace(/{user}/g, username)
        .replace(/{Char}/g, charName)
        .replace(/{User}/g, username);

    useEffect(() => {
        setShowButton(correctDescription.length > maxLength);
    }, [correctDescription, maxLength]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <motion.div
            className="expandable-description"
            ref={containerRef}
            initial={false}
            animate={{ maxHeight: isExpanded ? '1000px' : `${maxLength / 2}px` }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
            <h4 className="description-content">
                 <span ref={contentRef}>
                    {isExpanded ? correctDescription : correctDescription.slice(0, maxLength)}
                     {!isExpanded && correctDescription.length > maxLength && '...'}
                </span>
            </h4>
            {showButton && (
                <motion.button
                    layout
                    onClick={toggleExpand}
                    className="read-more-button"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    {isExpanded ? t('readLess') : t('readMore')}
                </motion.button>
            )}
        </motion.div>
    );
};

export default ExpandableDescriptionMobile;
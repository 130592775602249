import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Notifications/ModalAndNotifications.css';
import { ReactComponent as CloseIcon } from "../MainMenuPage/icons/cross.svg";
import ReportReceivedModal from "./ReportReceivedModal";
import {getUserAccessToken} from "../../App";

const SupportModal = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const [reportReceivedModal, setReportReceivedModal] = React.useState(false);
    const [shortDescription, setShortDescription] = React.useState('');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [error, setError] = React.useState(null);

    const handleDescriptionChange = (event) => {
        setShortDescription(event.target.value);
        setError(null);
    }

    const handleSupportButton = async () => {
        if (!shortDescription.trim()) {
            setError('Please describe your issue');
            return;
        }

        setIsSubmitting(true);
        setError(null);

        try {
            const response = await fetch('https://api.elyza.app/v1/support', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', Authorization: await getUserAccessToken(),
                },
                body: JSON.stringify({
                    subject: 'Support Request',
                    message: shortDescription
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to submit support request');
            }

            // Clear the form and show success modal
            setShortDescription('');
            setReportReceivedModal(true);

        } catch (err) {
            console.error('Support submission error:', err);
            setError(err.message || 'Failed to submit support request. Please try again.');
            setReportReceivedModal(false);
        } finally {
            setIsSubmitting(false);
        }
    }

    if (!isOpen) return null;

    return (
        <>
            <div className="modal-backdrop">
                <div className="modal-content-notification">
                    <div className='notification-label'>
                        {t('havingProblem')}
                        <div className="modal-close-icon-notification" onClick={onClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className='sublabel-notification'>
                        {t('supportDescription')}
                        <br />
                        <div className="line-container">
                            <span className="text-line-container">{t('report')}</span>
                            <div className="line"></div>
                        </div>

                        <div className="textbox-name-general-short">
                            <textarea
                                id="personalityDescription"
                                value={shortDescription}
                                onChange={handleDescriptionChange}
                                rows="2"
                                maxLength="200"
                                cols="50"
                                placeholder={t('issueplaceholder')}
                                disabled={isSubmitting}
                            />
                        </div>

                        {error && (
                            <div style={{
                                color: '#ff4444',
                                fontSize: '0.9em',
                                marginTop: '5px',
                                textAlign: 'left'
                            }}>
                                {error}
                            </div>
                        )}

                        <br />
                        <button
                            style={{
                                color: "#C4C4C4",
                                fontSize: "1em",
                                fontWeight: "500",
                                cursor: "pointer",
                                textAlign: "left"
                            }}
                            onClick={() => window.open('https://discord.gg/V4YUYRBc5M', '_blank')}
                        >
                            {t('discordHelp')}
                        </button>
                    </div>

                    <div
                        className="a-straight-line-3"
                        style={{marginTop: '10px', marginBottom: '5px'}}
                    />

                    <button
                        onClick={handleSupportButton}
                        className="notification-button-main"
                        style={{marginTop: '15px'}}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? t('submitting') : t('submit')}
                    </button>
                </div>
            </div>

            <ReportReceivedModal
                isOpen={reportReceivedModal}
                onClose={() => {
                    setReportReceivedModal(false);
                    onClose();
                }}
            />
        </>
    );
};

export default SupportModal;
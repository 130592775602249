import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";

const CreateShareMemoryModal = ({isOpen, onClose, shareNewMemory, shareMemoryName, setShareMemoryName}) => {

    const [portalRoot, setPortalRoot] = useState(null);

    useEffect(() => {
        setPortalRoot(document.getElementById("modal-root"));
    }, []);

    const handleCustomizationNameChange = (e) => {
        setShareMemoryName(e.target.value);
    };

    const {t} = useTranslation()

    const handleSave = () => {
        shareNewMemory();
    }

    if (!isOpen || !portalRoot) return null;

    const modalContent = (<>
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-centered-container">
                <div className="app-modal-content-response-settings"
                     style={{maxWidth: "420px"}}
                     onClick={(e) => e.stopPropagation()}>
                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">{t('NameMemory')}</div>
                        <div className="close-modal-icon" onClick={onClose}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="line-container">
                        <span className="text-line-container">
                          {t('MemoryNameBIG')}
                        </span>
                        <div className="line"></div>
                    </div>

                    <div className="main-elyza-text-response-settings">
                        {t('VisibleIfPublic')}
                    </div>

                    <div className="textbox-name-general"
                         style={{marginTop: '5px', marginBottom: '15px', width: '100%'}}
                    >
                        <input
                            type="text"
                            placeholder={t('NameMemory')}
                            value={shareMemoryName}
                            onChange={handleCustomizationNameChange}
                            maxLength="25"
                        />
                    </div>

                    <div className="footer-button-container">

                        <button className="need-help-button"
                                style={{backgroundColor: '#757575'}}
                                onClick={onClose}>
                            {t('Cancel')}
                        </button>

                        <button className="save-settings-button" onClick={handleSave}>
                            {t('save')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>);

    return ReactDOM.createPortal(modalContent, portalRoot);
};

export default CreateShareMemoryModal;
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import './AdvancedCharCreatorSteps.css';
import {useAdvancedCharacterCreation} from "../AdvancedCharacterContext";

const AdvancedStepSix = () => {
    const { t } = useTranslation();

    const { advancedCharData, updateAdvancedCharacterData } = useAdvancedCharacterCreation();

    const [firstMessage, setFirstMessage] = useState(advancedCharData.dialogue || '');

    const handleFirstMessageChange = (e) => {
        setFirstMessage(e.target.value);
        updateAdvancedCharacterData({dialogue: e.target.value});
    };

    return (
        <div className ="final-step-container">

            <div className="input-container-middle">
                <div className="textbox-name-general-short">
                        <label  className="label-sign-up-h">{t('FirstMessage')}</label>

                    <textarea
                        id="personalityDescription"
                        value={firstMessage}
                        onChange={handleFirstMessageChange}
                        rows="8"
                        maxLength="3000"
                        cols="50"
                        placeholder={t('FirstMessagePlaceholder')}
                    />
                </div>
            </div>

        </div>
    );
};

export default AdvancedStepSix;
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {ReactComponent as CloseIcon} from '../../../icons/cross.svg';
import {useTranslation} from "react-i18next";

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    const [portalRoot, setPortalRoot] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        setPortalRoot(document.getElementById("modal-root"));
    }, []);

    if (!isOpen || !portalRoot) return null;

    const modalContent = (
        <div className="modal-overlay"
             onClick={onClose}
        >
            <div className="modal-centered-container">
                <div className="app-modal-content-response-settings"
                     onClick={(e) => e.stopPropagation()}
                     style={{ maxWidth: "420px", minWidth: "380px"}}
                >
                    <div className="main-upper-text-row">
                        <div className="profile-setting-label">{t('HeyThere')}</div>
                        <div className="close-modal-icon" onClick={onClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className="main-elyza-text-sublabel">
                        {t('DeleteFragmentConfirm')}
                        <br></br>
                        <br></br>
                        {t('YouWantContinue')}
                    </div>

                    <div className="footer-button-container"
                         style={{marginTop: '10px'}}
                    >
                        <button className="save-settings-button"
                                style={{width: '100%'}}
                                onClick={onConfirm}>
                            {t('DeleteFragment')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(modalContent, portalRoot);
};

export default DeleteConfirmationModal;
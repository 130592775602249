import React from 'react';
import ReactDOM from 'react-dom';
import '../ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../MainMenuPage/icons/cross.svg";
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom"; // Import the translation hook

const UpgradeNewModel = ({ isOpen, onClose }) => {
    const { t } = useTranslation(); // Initialize translation function

    const navigate = useNavigate();

    const handleUpgrade = () => {
        navigate('/pricing')
        onClose();
    }

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop"
                 style={{alignItems: 'center'}}
            >
                <div className="modal-content-notification"
                     style={{
                         borderRadius: '16px'
                     }}
                >
                    <div className='notification-label'>
                        💕 {t('want_to_try')}
                        <div className="modal-close-icon-notification" onClick={() => {
                            onClose(false)
                        }}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">

                        <div className='sublabel-notification'>
                            {t('premium_users_get')}
                            <span className="colored-purple-text"> {t('unlimited_access')} </span>
                            {t('to_all_models')}, {t('increased_limits')}
                            <span className="colored-purple-text"> {t('100_images')} </span>
                            {t('every_month')}.
                            <br></br>
                            <br></br>
                            {t('they_get_other_features')}
                        </div>

                    </div>

                    <button onClick={() => {
                        handleUpgrade()
                    }} className="notification-button-main">
                        {t('upgrade')}
                    </button>
                </div>
            </div>
        ),
        document.body
    );
};

export default UpgradeNewModel;

import React, {useEffect, useMemo, useRef, useState} from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useTranslation} from "react-i18next";
import {ReactComponent as VoiceIcon} from "../../SimpleCharacterCreation/icons/voiceIcon.svg";

import Dropdown from "react-dropdown";
import {useAdvancedCharacterCreation} from "../AdvancedCharacterContext";

const AdvancedSelectVoice = () => {
    const { advancedCharData, updateAdvancedCharacterData, selectedVoice, setVoiceSelection } = useAdvancedCharacterCreation();

    const [selectedLanguage, setSelectedLanguage] = useState(advancedCharData.languageSelection);

    useEffect(() => {
        if (advancedCharData.languageSelection === '') {
            setSelectedLanguage('EN');
            updateAdvancedCharacterData({ languageSelection: 'EN' });
        }
        //eslint-disable-next-line
    }, [advancedCharData.languageSelection]);

    const audioRef = useRef(new Audio());

    const {t} = useTranslation();

    const baseUrl = "https://supabase.elyza.app/storage/v1/object/public/audio-files/character-creator";

    const voicesFemaleEnglish = [
        { id: "e3827ec5-697a-4b7c-9704-1a23041bbc51", label: "Voice 1", name: "Sweet Lady", url: `${baseUrl}/english/sweet_lady.wav` },
        { id: "573e3144-a684-4e72-ac2b-9b2063a50b53", label: "Voice 2", name: "Teacher Lady", url: `${baseUrl}/english/teacher_lady.wav` },
        { id: "03496517-369a-4db1-8236-3d3ae459ddf7", label: "Voice 3", name: "ASMR Lady", url: `${baseUrl}/english/asmr_lady.wav` },
        { id: "11af83e2-23eb-452f-956e-7fee218ccb5c", label: "Voice 4", name: "Midwestern Woman", url: `${baseUrl}/english/midwestern_woman.wav` },
        { id: "34bde396-9fde-4ebf-ad03-e3a1d1155205", label: "Voice 5", name: "New York Woman", url: `${baseUrl}/english/new_york_woman.wav` },
        { id: "21b81c14-f85b-436d-aff5-43f2e788ecf8", label: "Voice 6", name: "Laidback Woman", url: `${baseUrl}/english/laidback_woman.wav` },
        { id: "15a9cd88-84b0-4a8b-95f2-5d583b54c72e", label: "Voice 7", name: "Reading Lady", url: `${baseUrl}/english/reading_lady.wav` },
        { id: "5345cf08-6f37-424d-a5d9-8ae1101b9377", label: "Voice 8", name: "Maria", url: `${baseUrl}/english/maria.wav` },
        { id: "79a125e8-cd45-4c13-8a67-188112f4dd22", label: "Voice 9", name: "British Lady", url: `${baseUrl}/english/british_lady.wav` },
        { id: "00a77add-48d5-4ef6-8157-71e5437b282d", label: "Voice 10", name: "Calm Lady", url: `${baseUrl}/english/calm_lady.wav` },
        { id: "156fb8d2-335b-4950-9cb3-a2d33befec77", label: "Voice 11", name: "Helpful Woman", url: `${baseUrl}/english/helpful_woman.wav` },
        { id: "a3520a8f-226a-428d-9fcd-b0a4711a6829", label: "Voice 12", name: "Reflective Woman", url: `${baseUrl}/english/reflective_woman.wav` },
        { id: "b7d50908-b17c-442d-ad8d-810c63997ed9", label: "Voice 13", name: "California Girl", url: `${baseUrl}/english/california_girl.wav` },
        { id: "1001d611-b1a8-46bd-a5ca-551b23505334", label: "Voice 14", name: "Anime Girl", url: `${baseUrl}/english/anime_girl.wav` },
        { id: "248be419-c632-4f23-adf1-5324ed7dbf1d", label: "Voice 15", name: "Professional Woman", url: `${baseUrl}/english/professional_woman.wav` }
    ];

    const voicesMaleEnglish = [
        { id: "79f8b5fb-2cc8-479a-80df-29f7a7cf1a3e", label: "Voice 1", name: "Nonfiction Man", url: `${baseUrl}/english/nonfiction_man.wav` },
        { id: "f146dcec-e481-45be-8ad2-96e1e40e7f32", label: "Voice 2", name: "Reading Man", url: `${baseUrl}/english/reading_man.wav` },
        { id: "5619d38c-cf51-4d8e-9575-48f61a280413", label: "Voice 3", name: "Announcer Man", url: `${baseUrl}/english/announcer_man.wav` },
        { id: "34575e71-908f-4ab6-ab54-b08c95d6597d", label: "Voice 4", name: "New York Man", url: `${baseUrl}/english/new_york_man.wav` },
        { id: "565510e8-6b45-45de-8758-13588fbaec73", label: "Voice 5", name: "Midwestern Man", url: `${baseUrl}/english/midwestern_man.wav` },
        { id: "63ff761f-c1e8-414b-b969-d1833d1c870c", label: "Voice 6", name: "Confident British Man", url: `${baseUrl}/english/confident_british_man.wav` },
        { id: "98a34ef2-2140-4c28-9c71-663dc4dd7022", label: "Voice 7", name: "Southern Man", url: `${baseUrl}/english/southern_man.wav` },
        { id: "d46abd1d-2d02-43e8-819f-51fb652c1c61", label: "Voice 8", name: "Newsman", url: `${baseUrl}/english/newsman.wav` },
        { id: "a0e99841-438c-4a64-b679-ae501e7d6091", label: "Voice 9", name: "Barbershop Man", url: `${baseUrl}/english/barbershop_man.wav` },
        { id: "ee7ea9f8-c0c1-498c-9279-764d6b56d189", label: "Voice 10", name: "Polite Man", url: `${baseUrl}/english/polite_man.wav` },
        { id: "421b3369-f63f-4b03-8980-37a44df1d4e8", label: "Voice 11", name: "Friendly Australian Man", url: `${baseUrl}/english/friendly_australian_man.wav` },
        { id: "69267136-1bdc-412f-ad78-0caad210fb40", label: "Voice 12", name: "Friendly Reading Man", url: `${baseUrl}/english/friendly_reading_man.wav` },
        { id: "820a3788-2b37-4d21-847a-b65d8a68c99a", label: "Voice 13", name: "Salesman", url: `${baseUrl}/english/salesman.wav` },
        { id: "13524ffb-a918-499a-ae97-c98c7c4408c4", label: "Voice 14", name: "Australian Male", url: `${baseUrl}/english/australian_male.wav` }
    ];

    const voicesFemaleSpanish = [
        { id: "2deb3edf-b9d8-4d06-8db9-5742fb8a3cb2", label: "Voice 1", name: "Spanish Narrator Lady", url: `${baseUrl}/spanish/spanish_narrator_lady.wav` },
        { id: "846d6cb0-2301-48b6-9683-48f5618ea2f6", label: "Voice 2", name: "Spanish-speaking Lady", url: `${baseUrl}/spanish/spanish-speaking_lady.wav` },
        { id: "db832ebd-3cb6-42e7-9d47-912b425adbaa", label: "Voice 3", name: "Young Spanish-speaking Woman", url: `${baseUrl}/spanish/young_spanish-speaking_woman.wav` },
        { id: "5c5ad5e7-1020-476b-8b91-fdcbe9cc313c", label: "Voice 4", name: "Mexican Woman", url: `${baseUrl}/spanish/mexican_woman.wav` }
    ];

    const voicesMaleSpanish = [
        { id: "846fa30b-6e1a-49b9-b7df-6be47092a09a", label: "Voice 1", name: "Spanish-speaking Storyteller Man", url: `${baseUrl}/spanish/spanish-speaking_storyteller_man.wav` },
        { id: "34dbb662-8e98-413c-a1ef-1a3407675fe7", label: "Voice 2", name: "Spanish-speaking Man", url: `${baseUrl}/spanish/spanish-speaking_man.wav` },
        { id: "2695b6b5-5543-4be1-96d9-3967fb5e7fec", label: "Voice 3", name: "Spanish-speaking Reporter Man", url: `${baseUrl}/spanish/spanish-speaking_reporter_man.wav` },
        { id: "a67e0421-22e0-4d5b-b586-bd4a64aee41d", label: "Voice 4", name: "Spanish Narrator Man", url: `${baseUrl}/spanish/spanish_narrator_man.wav` },
        { id: "15d0c2e2-8d29-44c3-be23-d585d5f154a1", label: "Voice 5", name: "Mexican Man", url: `${baseUrl}/spanish/mexican_man.wav` }
    ];

    const voicesFemaleRussian = [
        { id: "779673f3-895f-4935-b6b5-b031dc78b319", label: "Voice 1", name: "Russian Calm Lady", url: `${baseUrl}/russian/russian_calm_lady.wav` },
        { id: "642014de-c0e3-4133-adc0-36b5309c23e6", label: "Voice 2", name: "Russian Narrator Woman", url: `${baseUrl}/russian/russian_narrator_woman.wav` }
    ];

    const voicesMaleRussian = [
        { id: "2b3bb17d-26b9-421f-b8ca-1dd92332279f", label: "Voice 1", name: "Russian Narrator Man 1", url: `${baseUrl}/russian/russian_narrator_man_1.wav` },
        { id: "da05e96d-ca10-4220-9042-d8acef654fa9", label: "Voice 2", name: "Russian Narrator Man 2", url: `${baseUrl}/russian/russian_narrator_man_2.wav` }
    ];

    const voicesFemaleGerman = [
        { id: "3f4ade23-6eb4-4279-ab05-6a144947c4d5", label: "Voice 1", name: "German Conversational Woman", url: `${baseUrl}/german/german_conversational_woman.wav` },
        { id: "b9de4a89-2257-424b-94c2-db18ba68c81a", label: "Voice 2", name: "German Woman", url: `${baseUrl}/german/german_woman.wav` },
        { id: "119e03e4-0705-43c9-b3ac-a658ce2b6639", label: "Voice 3", name: "German Reporter Woman", url: `${baseUrl}/german/german_reporter_woman.wav` }
    ];

    const voicesMaleGerman = [
        { id: "db229dfe-f5de-4be4-91fd-7b077c158578", label: "Voice 1", name: "German Storyteller Man", url: `${baseUrl}/german/german_storyteller_man.wav` },
        { id: "384b625b-da5d-49e8-a76d-a2855d4f31eb", label: "Voice 2", name: "German Conversation Man", url: `${baseUrl}/german/german_conversation_man.wav` },
        { id: "fb9fcab6-aba5-49ec-8d7e-3f1100296dde", label: "Voice 3", name: "Friendly German Man", url: `${baseUrl}/german/friendly_german_man.wav` },
        { id: "3f6e78a8-5283-42aa-b5e7-af82e8bb310c", label: "Voice 4", name: "German Reporter Man", url: `${baseUrl}/german/german_reporter_man.wav` }
    ];

    const voicesFemalePolish = [
        { id: "575a5d29-1fdc-4d4e-9afa-5a9a71759864", label: "Voice 1", name: "Polish Narrator Woman", url: `${baseUrl}/polish/polish_narrator_woman.wav` }
    ];

    const voicesMalePolish = [
        { id: "3d335974-4c4a-400a-84dc-ebf4b73aada6", label: "Voice 1", name: "Polish Confident Man", url: `${baseUrl}/polish/polish_confident_man.wav` },
        { id: "4ef93bb3-682a-46e6-b881-8e157b6b4388", label: "Voice 2", name: "Polish Narrator Man", url: `${baseUrl}/polish/polish_narrator_man.wav` },
        { id: "82a7fc13-2927-4e42-9b8a-bb1f9e506521", label: "Voice 3", name: "Polish Young Man", url: `${baseUrl}/polish/polish_young_man.wav` }
    ];

    const voicesFemaleJapanese = [
        { id: "2b568345-1d48-4047-b25f-7baccf842eb0", label: "Voice 1", name: "Japanese Woman Conversational", url: `${baseUrl}/japanese/japanese_woman_conversational.wav` }
    ];

    const voicesMaleJapanese = [
        { id: "e8a863c6-22c7-4671-86ca-91cacffc038d", label: "Voice 1", name: "Japanese Male Conversational", url: `${baseUrl}/japanese/japanese_male_conversational.wav` }
    ];

    const voicesFemaleKorean = [
        { id: "663afeec-d082-4ab5-827e-2e41bf73a25b", label: "Voice 1", name: "Korean Narrator Woman", url: `${baseUrl}/korean/korean_narrator_woman.wav` },
        { id: "29e5f8b4-b953-4160-848f-40fae182235b", label: "Voice 2", name: "Korean Calm Woman", url: `${baseUrl}/korean/korean_calm_woman.wav` }
    ];

    const voicesMaleKorean = [
        { id: "57dba6ff-fe3b-479d-836e-06f5a61cb5de", label: "Voice 1", name: "Korean Narrator Man", url: `${baseUrl}/korean/korean_narrator_man.wav` }
    ];

    const voicesFemaleChinese = [
        { id: "d4d4b115-57a0-48ea-9a1a-9898966c2966", label: "Voice 1", name: "Chinese Woman Narrator", url: `${baseUrl}/chinese/chinese_woman_narrator.wav` },
        { id: "0b904166-a29f-4d2e-bb20-41ca302f98e9", label: "Voice 2", name: "Chinese Commercial Woman", url: `${baseUrl}/chinese/chinese_commercial_woman.wav` },
        { id: "e90c6678-f0d3-4767-9883-5d0ecf5894a8", label: "Voice 3", name: "Chinese Female Conversational", url: `${baseUrl}/chinese/chinese_female_conversational.wav` }
    ];

    const voicesMaleChinese = [
        { id: "eda5bbff-1ff1-4886-8ef1-4e69a77640a0", label: "Voice 1", name: "Chinese Commercial Man", url: `${baseUrl}/chinese/chinese_commercial_man.wav` },
        { id: "3a63e2d1-1c1e-425d-8e79-5100bc910e90", label: "Voice 2", name: "Chinese Call Center Man", url: `${baseUrl}/chinese/chinese_call_center_man.wav` }
    ];

    const voicesFemaleFrench = [
        { id: "a249eaff-1e96-4d2c-b23b-12efa4f66f41", label: "Voice 1", name: "French Conversational Lady", url: `${baseUrl}/french/french_conversational_lady.wav` },
        { id: "8832a0b5-47b2-4751-bb22-6a8e2149303d", label: "Voice 2", name: "French Narrator Lady", url: `${baseUrl}/french/french_narrator_lady.wav` },
        { id: "a8a1eb38-5f15-4c1d-8722-7ac0f329727d", label: "Voice 3", name: "Calm French Woman", url: `${baseUrl}/french/calm_french_woman.wav` },
        { id: "65b25c5d-ff07-4687-a04c-da2f43ef6fa9", label: "Voice 4", name: "Helpful French Lady", url: `${baseUrl}/french/helpful_french_lady.wav` }
    ];

    const voicesMaleFrench = [
        { id: "0418348a-0ca2-4e90-9986-800fb8b3bbc0", label: "Voice 1", name: "Stern French Man", url: `${baseUrl}/french/stern_french_man.wav` },
        { id: "5c3c89e5-535f-43ef-b14d-f8ffe148c1f0", label: "Voice 2", name: "French Narrator Man", url: `${baseUrl}/french/french_narrator_man.wav` },
        { id: "ab7c61f5-3daa-47dd-a23b-4ac0aac5f5c3", label: "Voice 3", name: "Friendly French Man", url: `${baseUrl}/french/friendly_french_man.wav` }
    ];

    const voicesFemaleItalian = [
        { id: "0e21713a-5e9a-428a-bed4-90d410b87f13", label: "Voice 1", name: "Italian Narrator Woman", url: `${baseUrl}/italian/italian_narrator_woman.wav` }
    ];

    const voicesMaleItalian = [
        { id: "408daed0-c597-4c27-aae8-fa0497d644bf", label: "Voice 1", name: "Italian Calm Man", url: `${baseUrl}/italian/italian_calm_man.wav` },
        { id: "029c3c7a-b6d9-44f0-814b-200d849830ff", label: "Voice 2", name: "Italian Narrator Man", url: `${baseUrl}/italian/italian_narrator_man.wav` }
    ];

    const voicesFemalePortuguese = [
        { id: "700d1ee3-a641-4018-ba6e-899dcadc9e2b", label: "Voice 1", name: "Pleasant Brazilian Lady", url: `${baseUrl}/portuguese/pleasant_brazilian_lady.wav` }
    ];

    const voicesMalePortuguese = [
        { id: "6a16c1f4-462b-44de-998d-ccdaa4125a0a", label: "Voice 1", name: "Friendly Brazilian Man", url: `${baseUrl}/portuguese/friendly_brazilian_man.wav` },
        { id: "5063f45b-d9e0-4095-b056-8f3ee055d411", label: "Voice 2", name: "Brazilian Young Man", url: `${baseUrl}/portuguese/brazilian_young_man.wav` }
    ];


    const languageOptions = [
        { value: 'EN', label: '🇺🇸 English', nativeName: 'English' },
        { value: 'ES', label: '🇪🇸 Español', nativeName: 'Español' },
        { value: 'RU', label: '🇷🇺 Русский', nativeName: 'Русский' },
        { value: 'DE', label: '🇩🇪 Deutsch', nativeName: 'Deutsch' },
        { value: 'PL', label: '🇵🇱 Polski', nativeName: 'Polski' },
        { value: 'JA', label: '🇯🇵 日本語', nativeName: '日本語' },
        { value: 'KO', label: '🇰🇷 한국어', nativeName: '한국어' },
        { value: 'ZH', label: '🇨🇳 中文', nativeName: '中文' },
        { value: 'FR', label: '🇫🇷 Français', nativeName: 'Français' },
        { value: 'IT', label: '🇮🇹 Italiano', nativeName: 'Italiano' },
        { value: 'PT', label: '🇵🇹 Português', nativeName: 'Português' }
    ];

    const voiceMap = useMemo(() => ({
        EN: { Male: voicesMaleEnglish, Female: voicesFemaleEnglish },
        ES: { Male: voicesMaleSpanish, Female: voicesFemaleSpanish },
        RU: { Male: voicesMaleRussian, Female: voicesFemaleRussian },
        DE: { Male: voicesMaleGerman, Female: voicesFemaleGerman },
        PL: { Male: voicesMalePolish, Female: voicesFemalePolish },
        JA: { Male: voicesMaleJapanese, Female: voicesFemaleJapanese },
        KO: { Male: voicesMaleKorean, Female: voicesFemaleKorean },
        ZH: { Male: voicesMaleChinese, Female: voicesFemaleChinese },
        FR: { Male: voicesMaleFrench, Female: voicesFemaleFrench },
        IT: { Male: voicesMaleItalian, Female: voicesFemaleItalian },
        PT: { Male: voicesMalePortuguese, Female: voicesFemalePortuguese }
        // eslint-disable-next-line
    }), []);


    const currentVoices = useMemo(() => {
        const gender = advancedCharData.gender === 'Female' ? 'Female' : 'Male';
        return voiceMap[selectedLanguage][gender] || [];
    }, [selectedLanguage, advancedCharData.gender, voiceMap]);


    useEffect(() => {
        if (advancedCharData.languageSelection) {
            setSelectedLanguage(advancedCharData.languageSelection);
        }
        if (advancedCharData.voiceSelection) {
            setVoiceSelection(advancedCharData.voiceSelection);
        }
        //eslint-disable-next-line
    }, [advancedCharData.languageSelection, advancedCharData.voiceSelection]);


    const handleLanguageSelect = (option) => {
        setSelectedLanguage(option.value);
        updateAdvancedCharacterData({ languageSelection: option.value, voiceSelection: '' });
        setVoiceSelection('');
    };

    const handleVoiceSelection = (voice) => {
        setVoiceSelection(voice.id);
        updateAdvancedCharacterData({ voiceSelection: voice.id });
        playAudio(voice.url);
    };

    const playAudio = (url) => {
        audioRef.current.pause();
        audioRef.current.src = url;
        audioRef.current.play().catch(error => console.error("Audio playback failed:", error));
    };

    return (<div>
            <div className="general-multiple-options-container">
                <div className="dropdown-container-bottom"
                     style={{width: '100%'}}>
                    <div className="custom-dropdown"

                    >
                        <Dropdown
                            options={languageOptions}
                            value={selectedLanguage}
                            onChange={handleLanguageSelect}
                            placeholder={t('SelectLanguage')}
                            className="model-dropdown-creator"
                            controlClassName="model-dropdown-control-creator"
                            menuClassName="model-dropdown-menu-creator-voice"
                            arrowClassName="model-dropdown-arrow-creator"
                        />
                    </div>
                </div>

                <div className="voice-grid"
                     style={{display: 'grid', width: '100%'}}>
                    {currentVoices.map((voice, index) => (
                        <div key={`${voice.id}-${index}`} className="general-option-wrapper">
                            <div
                                className={`general-option-voice-items ${selectedVoice === voice.id ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleVoiceSelection(voice)}
                            >
                                <div className="voice-example-container">
                                    {t(`${voice.label}`)}
                                    <VoiceIcon/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


export default AdvancedSelectVoice;
import React, { useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import './AdvancedCharCreatorSteps.css';
import { useAdvancedCharacterCreation } from "../AdvancedCharacterContext";
import Dropdown from "react-dropdown";

const AdvancedStepOne = () => {
    const { advancedCharData, updateAdvancedCharacterData, setVoiceSelection} = useAdvancedCharacterCreation();
    const { t } = useTranslation();
    const [genderOption, setGenderOption] = useState(advancedCharData.gender || 'Female');
    const [contentType, setContentType] = useState(advancedCharData.nsfw ? 'NSFW' : 'SFW');

    const baseAdvancedSettings = [
        { value: 'Futa', label: t('AdvancedFuta') },
        { value: 'Femboy', label: t('AdvancedFemboy') },
        // { value: 'Furry', label: t('AdvancedFurry') },
    ];

    const [selectedAdvancedSetting, setSelectedAdvancedSetting] = useState('');

    const advancedSettings = useMemo(() => {
        if (selectedAdvancedSetting) {
            return [
                ...baseAdvancedSettings,
                { value: '', label: t('AdvancedReset') }
            ];
        }
        return baseAdvancedSettings;
        //eslint-disable-next-line
    }, [selectedAdvancedSetting, t]);

    const handleChangeAdvancedSetting = (option) => {
        setSelectedAdvancedSetting(option.value);
        updateAdvancedCharacterData({ specialCase: option.value });
    };

    const handleOptionSelect = (category, option) => {
        let updatedData = {};

        switch (category) {
            case 'contentType':
                setContentType(option);
                updatedData = { nsfw: option === 'NSFW' };
                break;
            case 'gender':
                setGenderOption(option);
                updatedData = { gender: option, voiceSelection: '' };
                setVoiceSelection('');
                break;
            default:
                return;
        }

        updateAdvancedCharacterData(updatedData);
    };

    const genderOptions = [
        { option: 'Female', description: t('GenderFemaleDesc') },
        { option: 'Male', description: t('GenderMaleDesc') },
        { option: 'Other', description: t('GenderOtherDesc') },
    ];

    const contentTypeOptions = [
        { option: 'NSFW', description: t('ContentTypeNSFWDesc') },
        { option: 'SFW', description: t('ContentTypeSFWDesc') },
    ];

    const renderOptions = (options, selectedOption, category) => (
        <div className="options-container">
            {options.map(({ option, description }) => (
                <div
                    key={option}
                    className={`option-box ${selectedOption === option ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(category, option)}
                >
                    <span className="option-text">{t(option)}</span>
                    <span className="option-description">{description}</span>
                </div>
            ))}
        </div>
    );

    return (
        <div className="advanced-step-one">
            {renderOptions(genderOptions, genderOption, 'gender')}

            {renderOptions(contentTypeOptions, contentType, 'contentType')}

            <div className="dropdown-container-bottom">
                <div className="custom-dropdown">
                    <Dropdown
                        options={advancedSettings}
                        value={selectedAdvancedSetting}
                        onChange={handleChangeAdvancedSetting}
                        placeholder={t('AdvancedSettings')}
                        className="model-dropdown-creator"
                        controlClassName="model-dropdown-control-creator"
                        menuClassName="model-dropdown-menu-creator"
                        arrowClassName="model-dropdown-arrow-creator"
                    />
                </div>
            </div>
        </div>
    );
};

export default AdvancedStepOne;
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './ModalAndNotifications.css';
import { ReactComponent as UnlockIcon } from "../MainMenuPage/icons/unlock.svg";
import { AuthModal } from "../Forms/AuthModal";
import { useTranslation } from "react-i18next";



const SignUpToSeeMore = ({ opacity, onClose }) => {
    const { t } = useTranslation();
    const isFullyVisible = opacity >= 0.85;
    const [showModal, setShowModal] = useState(false);

    const handleUnlockClick = () => {
        setShowModal(true);
        onClose(false);
        document.body.style.overflow = 'hidden'; // Disable scrolling
    };

    const handleCloseModal = () => {
        setShowModal(false);
        document.body.style.overflow = 'auto'; // Re-enable scrolling
    };

    return ReactDOM.createPortal(
        (<div
            className="modal-backdrop"
            style={{
                zIndex: '1005',
                backgroundColor: `rgba(0,0,0,${opacity * 0.75})`,
                backdropFilter: `blur(${10 * opacity}px)`,
                pointerEvents: isFullyVisible ? 'auto' : 'none',
                alignItems: 'center',
            }}
        >
            <div
                className="modal-content-notification"
                style={{
                    opacity,
                    pointerEvents: isFullyVisible ? 'auto' : 'none',
                    zIndex: '10',
                    borderRadius: '16px'
                }}
            >
                <div className='notification-label'>
                    {t('signUpToSeeMore')}
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        {t('getUnlimitedMessages')}
                        <span className="colored-purple-text"> {t('unlimitedMessagesSmall')} </span>
                        {t('andCreateCompanion')}
                        <br /><br />
                        {t('otherFeatures')}
                    </div>
                </div>

                <button
                    onClick={handleUnlockClick}
                    className="notification-button-main"
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        display: 'flex'
                    }}
                >
                    <UnlockIcon />
                    {t('unlockNow')}
                </button>
            </div>
            {showModal && <AuthModal onClose={handleCloseModal} />}
        </div>),
        document.body
    );
};

export default SignUpToSeeMore;
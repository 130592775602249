import React from 'react';
import {useTranslation} from 'react-i18next';
import '../Notifications/ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";

const ReportReceived = ({isOpen, onClose}) => {
    const { t } = useTranslation();

    const handleSupportButton = () => {
        window.open('https://discord.gg/V4YUYRBc5M', '_blank')
        onClose();
    }

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-label'>
                    {t('reportReceived')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {t('reportReviewMessage')}
                    <br></br>
                    <div className="line-container">
                        <span className="text-line-container">{t('success')}</span>
                        <div className="line"></div>
                    </div>

                    {t('wantFasterSupport')}
                    <br></br> <br></br>
                    {t('joinDiscordMessage')}
                </div>
                <button
                    onClick={handleSupportButton}
                    className="notification-button-main"
                    style={{backgroundColor: '#7289da'}}
                >
                    {t('joinDiscord')}
                </button>
                <button
                    onClick={onClose}
                    style={{
                        color: 'white',
                        fontWeight: 700,
                        fontSize: '1em',
                        cursor: "pointer",
                        width: '100%',
                        marginTop: '15px'
                    }}
                >
                    {t('close')}
                </button>
            </div>
        </div>
    );
};

export default ReportReceived;

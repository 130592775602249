import React from "react";
// import {ReactComponent as CounterIconUp} from "../icons/CountetUpIcon.svg";
// import {ReactComponent as CounterIconDown} from "../icons/CounterDownIcon.svg";
import './GenerateImagePageSubs.css'
import {ReactComponent as CounterIcon} from "../icons/ElyzaCoin.svg";

export const ImageCounter = ({imageCount, setImageCount, maxCount = 4}) => {
    // const incrementCount = () => {
    //     if (imageCount < maxCount) {
    //         setImageCount(imageCount + 1);
    //     }
    // };
    //
    // const decrementCount = () => {
    //     if (imageCount > 1) {
    //         setImageCount(imageCount - 1);
    //     }
    // };

    return (
        <div className="image-count-container">

            <div className="image-count-number"
            style={{borderRadius: '6px'}}
            >
                {imageCount}
                <CounterIcon/>
            </div>

            {/*<div className="image-counter-change">*/}
            {/*    <div className="arrow-general-change">*/}
            {/*        <button className="arrow-control-counter top"*/}
            {/*                onClick={incrementCount}*/}
            {/*                disabled={true}*/}
            {/*        >*/}
            {/*            <CounterIconUp/>*/}
            {/*        </button>*/}
            {/*    </div>*/}

            {/*    <div className="arrow-general-change">*/}
            {/*        <button className="arrow-control-counter bottom"*/}
            {/*                onClick={decrementCount}><CounterIconDown/></button>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};
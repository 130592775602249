// CharacterContext.js
import React, { useState, createContext } from 'react';

export const CharacterContext = createContext({
    realChars: [],
    localSearchText: "", // Changed from searchText to localSearchText
    setLocalSearchText: () => {}, // New function name
    // fetchCharacterList: () => {}
});

export const CharacterContextProvider = ({ children }) => {
    const [realChars, setRealChars] = useState([]);
    const [localSearchText, setLocalSearchText] = useState(""); // State for local search text
    const [searchText, setSearchText] = useState("");
    const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

    const value = {
        searchText, setSearchText,
        realChars,
        setRealChars,
        localSearchText,
        setLocalSearchText, // Providing the new function to the context
        isSearchBarVisible, setIsSearchBarVisible
    };

    return <CharacterContext.Provider value={value}>{children}</CharacterContext.Provider>;
}



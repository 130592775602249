import {useEffect, useRef, useState} from "react";
import {createPortal} from 'react-dom';
import './TempLanguageDropdown.css'
import {getUserAccessToken} from "../../App";
import {useTranslation} from "react-i18next";

const TempLanguageDropdown = ({
                                  isOpen,
                                  anchorRef,
                                  language,
                                  onLanguageChange,
                                  isLoggedIn
                              }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const {i18n} = useTranslation();
    const initialScrollPosition = useRef(null);

    useEffect(() => {
        if (anchorRef.current && isOpen) {
            const rect = anchorRef.current.getBoundingClientRect();
            setPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX - 175,
                width: rect.width
            });
            // Store initial scroll position when dropdown opens
            initialScrollPosition.current = window.scrollY;
        }
    }, [isOpen, anchorRef]);

    useEffect(() => {
        const handleScroll = () => {
            if (isOpen && initialScrollPosition.current !== null) {
                const currentScroll = window.scrollY;
                const scrollDifference = Math.abs(currentScroll - initialScrollPosition.current);

                // Close dropdown if user has scrolled more than 50 pixels
                if (scrollDifference > 20) {
                    setIsDropdownVisible(false);
                    initialScrollPosition.current = null;
                    // Notify parent component
                    if (onLanguageChange) {
                        onLanguageChange(language);
                    }
                }
            }
        };

        if (isOpen) {
            window.addEventListener('scroll', handleScroll, { passive: true });
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen, language, onLanguageChange]);

    const languages = [
        {code: 'en', name: '🇺🇸 English'},
        {code: 'es', name: '🇪🇸 Español'},
        {code: 'fr', name: '🇫🇷 Français'},
        {code: 'ru', name: '🇷🇺 Русский'},
        {code: 'de', name: '🇩🇪 Deutsch'},
        {code: 'it', name: '🇮🇹 Italiano'},
        {code: 'pl', name: '🇵🇱 Polski'},
        {code: 'pt', name: '🇵🇹 Portuguese'},
        {code: 'ja', name: '🇯🇵 日本語'},
        {code: 'ko', name: '🇰🇷 한국어'},
        {code: 'zh', name: '🇨🇳 中文 (简体)'}
    ];

    const handleLanguageChange = async (option) => {
        onLanguageChange(option.value);
        if (isLoggedIn) {
            await handleLanguageChangeBackend({ target: { value: option.value } });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [isOpen]);

    useEffect(() => {
        setIsDropdownVisible(isOpen);
    }, [isOpen]);

    const languageCodeToEnglishName = {
        'en': 'English',
        'es': 'Spanish',
        'fr': 'French',
        'ru': 'Russian',
        'de': 'German',
        'it': 'Italian',
        'pl': 'Polish',
        'pt': 'Portuguese',
        'ja': 'Japanese',
        'ko': 'Korean',
        'zh': 'Chinese'
    };

    const mapLanguageCodeToEnglishName = (code) => {
        return languageCodeToEnglishName[code] || code;
    };

    const handleLanguageChangeBackend = async (e) => {
        const newLang = e.target.value;
        i18n.changeLanguage(newLang);

        if (isLoggedIn) {
            try {
                const response = await fetch('https://api.elyza.app/v1/change-language', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': await getUserAccessToken()
                    },
                    body: JSON.stringify({language: mapLanguageCodeToEnglishName(newLang)}),
                });

                if (!response.ok) {
                    throw new Error('Failed to update language on server');
                }
            } catch (error) {
                console.error('Error updating language:', error);
            }
        }
    };

    if (!isOpen) return null;

    // Portal content
    const dropdownContent = (
        <div
            className="language-dropdown-container"
            style={{
                // position: 'fixed',
                top: `${position.top}px`,
                left: `${position.left}px`,
                zIndex: 9999
            }}
            ref={dropdownRef}
        >
            {isDropdownVisible && (
                <div className="language-dropdown-menu-main">
                    {languages.map((lang) => (
                        <div
                            key={lang.code}
                            className={`language-option ${language === lang.code ? 'selected' : ''}`}
                            onClick={() => handleLanguageChange({value: lang.code})}
                        >
                            {lang.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    // Render using Portal
    return createPortal(
        dropdownContent,
        document.body
    );
};

export default TempLanguageDropdown;
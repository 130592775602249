import React, {useEffect} from 'react';
import './BannerStyle.css';
import {ReactComponent as Discord} from './BannerIcons/discordicon.svg';
import {ReactComponent as StarIcon} from './BannerIcons/buttonIcon.svg';
import {ReactComponent as Twitter} from './BannerIcons/twitter.svg';
// import Anime1 from './BannerIcons/anime1.png';
// import Real1 from './BannerIcons/real1.png';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from "../../icons/cross.svg";
import Cookies from 'js-cookie';
import {useTranslation} from "react-i18next";

const MobileHeroBanner = ({isBannerVisibleFirstDiscord, hideBanner}) => {

    const {t} = useTranslation();

    const hideBannerAndSetCookie = () => {
        hideBanner();
        Cookies.set('bannerHidden', 'true', { expires: 10 });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (Cookies.get('bannerHidden')) {
                hideBanner();
            } else {
                document.body.style.overflow = 'hidden'; // Disable scrolling
            }
        }, );

        return () => {
            clearTimeout(timer);
            document.body.style.overflow = 'auto'; // Enable scrolling when component is unmounted
        };
        //eslint-disable-next-line
    }, []);


    if (!isBannerVisibleFirstDiscord) return null;

    const bannerContent = (<div className="banner-container-hero-mobile banner-state-4">
            <div className="banner-content-hero">
                <div className="banner-left-hero-mobile">
                    <div className="row-title-screen-mobile">
                    <span className="main-title-screen">
                    <span className="banner-title-hero">{t('Chat')}
                        <span className="colorful-text-nsfw" style={{padding: '0'}}> {t('NSFW')} </span>
                        {t('With')}
                    </span>
                    <span className="banner-title-hero">
                        {t('AnAiCompanion')}
                    </span>
                    </span>

                        <CloseIcon style={{cursor: "pointer"}}
                                   onClick={hideBanner}/>
                    </div>


                    <span className="banner-subtitle-hero">
                        {t('ElyzaIsHome')}
                    </span>
                    <span className="banner-subtitle-hero">
                       {t('ElyzaIsHome2')}
                    </span>

                    <button className="banner-button-hero button-state-4"  onClick={hideBannerAndSetCookie}
                    style={{maxWidth: 'unset', width: 'unset'}}
                    >
                        <StarIcon className='star-icon-banner'/>
                        {t('ViewAllChars')}
                    </button>
                    <span className='sub-button-text' style={{cursor: "unset"}}>{t('OrJoinCommunity')}</span>
                    <div className="join-community-row">

                        <Discord className="hero-banner-icon"
                                 onClick={() => window.open('https://discord.gg/V4YUYRBc5M', '_blank')}/>

                        <Twitter className="hero-banner-icon"
                                 onClick={() => window.open('https://x.com/elyzaapp', '_blank')}/>


                    </div>
                </div>
                <div className="banner-right-hero"></div>
            </div>
        </div>);


    return ReactDOM.createPortal(bannerContent, document.getElementById('modal-root'));
};

export default MobileHeroBanner;

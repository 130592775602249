import React, { useState, useEffect, useCallback } from 'react';
import {fetchCharacterMemories} from "../CharacterCardFunctions";
import {useNavigate} from "react-router-dom";
import ViewMemoryModal from "../ViewMemoryModal";
import {getUserAccessToken} from "../../../App";
import {useTranslation} from "react-i18next";
import './PublicChatMemoriesMobile.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PublicChatsMobile = ({ identityName, setIsUserLoggedIn, publicChatPublicMessages, setPublicChatPublicMessages }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [shareCode, setShareCode] = useState('');
    const {t} = useTranslation();

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        className: 'public-chats-slider',
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const memoryId = urlParams.get('memoryId');
        const shareCode = urlParams.get('shareCode');

        if (memoryId) {
            if(shareCode === null || shareCode === '') {
                const chat = publicChatPublicMessages.find(chat => chat.id === parseInt(memoryId));
                if (chat) {
                    setSelectedChat(chat);
                    setIsModalOpen(true);
                }
            } else {
                setShareCode(shareCode);
                fetchCharacterMemoriesByMemoryId(memoryId, shareCode)
                    .then(data => {
                        if(data.error === 'Unauthorized to access this memory') {
                            return;
                        } else {
                            setSelectedChat(data);
                        }
                    })
                    .catch(err => {
                        console.log('Error fetching memory:', err);
                    });
                setIsModalOpen(true);
            }
        }
    }, [publicChatPublicMessages]);

    const fetchCharacterMemoriesByMemoryId = async (memoryId, shareCode = '') => {
        const response = await fetch('https://api.elyza.app/v1/render-chat-memory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({memoryId, shareCode})
        });

        return response.json();
    };

    const handleUsernameClick = (username, e) => {
        e.stopPropagation(); // Prevent slide change when clicking username
        navigate('/user/' + username);
    };

    const handleChatClick = (chat) => {
        setSelectedChat(chat);
        setIsModalOpen(true);
    };

    const fetchPublicMemories = useCallback(async () => {
        if (identityName) {
            try {
                const response = await fetchCharacterMemories(identityName);
                if (response.success && Array.isArray(response.data)) {
                    setPublicChatPublicMessages(response.data);
                } else {
                    setPublicChatPublicMessages([]);
                }
            } catch (err) {
                console.error('Error fetching public memories:', err);
                setPublicChatPublicMessages([]);
            }
        }
        //eslint-disable-next-line
    }, [identityName]);

    useEffect(() => {
        fetchPublicMemories();
    }, [fetchPublicMemories]);

    const truncate = (str, num) => {
        if (str.length <= num) return str;
        return str.slice(0, num) + '...';
    };

    return (
        <div className="public-chats-mobile-container">
            <Slider {...sliderSettings}>
                {publicChatPublicMessages.map((chat, index) => (
                    <div key={chat.id || index} className="slider-chat-item">
                        <div className="chat-card" onClick={() => handleChatClick(chat)}>
                            <div className="public-chat-upper"
                                 onClick={(e) => handleUsernameClick(chat.tagname, e)}
                            >
                                <img
                                    src={chat.profile_url}
                                    alt={''}
                                    className='user-avatar'
                                />
                                <div className="public-chat-author">
                                    <span className="public-chat-by-author">
                                        {t('By')}
                                    </span>
                                    <br />
                                    @{chat.tagname}
                                </div>
                            </div>

                            <div className="public-chat-title">{chat.memory_name}</div>

                            <div className="public-chat-messages">
                                {chat.lastMessages
                                    .sort((a, b) => (a.isUser === b.isUser) ? 0 : a.isUser ? 1 : -1)
                                    .map((message, msgIndex) => (
                                        <div
                                            key={message.id || msgIndex}
                                            className={message.isUser ? "public-chat-user" : "public-chat-bot"}
                                        >
                                            {truncate(message.content, 50)}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>

            {selectedChat && (
                <ViewMemoryModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    title={selectedChat.memory_name}
                    memoryId={selectedChat.id}
                    profileURL={selectedChat.profile_url}
                    setIsUserLoggedIn={setIsUserLoggedIn}
                    shareCode={shareCode.length > 0 ? shareCode : selectedChat.share_code}
                >
                    <div className="modal-chat-messages">
                        {selectedChat.lastMessages
                            .sort((a, b) => (a.isUser === b.isUser) ? 0 : a.isUser ? 1 : -1)
                            .map((message, index) => (
                                <div
                                    key={index}
                                    className={message.isUser ? "modal-chat-user" : "modal-chat-bot"}
                                >
                                    {message.content}
                                </div>
                            ))}
                    </div>
                </ViewMemoryModal>
            )}
        </div>
    );
};

export default React.memo(PublicChatsMobile);
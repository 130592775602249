import React, {useState} from 'react';
import './NewMainPage.css';
import {NewCharacterList} from "../components/MainMenuPage/NewCharacterList";
import {HeaderMainMenu} from "../components/MainMenuPage/Header";
import {CharacterContextProvider} from '../components/Context/CharacterContext';
import {MobileNavigation} from "../components/MainMenuPage/MobileNavigation";

export const MenuPageUpdated = () => {
    const [searchText, setSearchText] = useState('');
    const [realChars, setRealChars] = useState([]);
    const [searchCharacters, setSearchCharacters] = useState([]);

    return (
        <CharacterContextProvider value={{realChars, setRealChars}}>
            <div className="MainNewPage">

                <MobileNavigation searchText={searchText} setSearchText={setSearchText}
                                  setSearchCharacters={setSearchCharacters}/>

                <HeaderMainMenu searchText={searchText} setSearchText={setSearchText}
                                setSearchCharacters={setSearchCharacters}/>

                <div className="CharacterListH">
                    <NewCharacterList
                        ownCharacters={"Created Characters"}
                        search={searchText}
                        setSearchText={setSearchText}
                        tag={"All"}
                        searchCharacters={searchCharacters}
                        searchText={searchText}
                        setSearchCharacters={setSearchCharacters}
                    />
                </div>
            </div>
        </CharacterContextProvider>
    );
};
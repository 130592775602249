import React, {useCallback, useState, useEffect} from 'react';
import './AdvancedCharacterEdit.css';
import {ReactComponent as CloseIcon} from "../../ChatIcons/cross.svg";
import {getUserAccessToken, getUserID} from "../../../../App";
import Tooltip from '@mui/material/Tooltip';
import ReactDOM from 'react-dom';
import {Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {styled} from '@mui/system';
import {BeatLoader} from "react-spinners";
import {SuccessModal} from "./SuccessModalAdvancedEdit";
import {fetchData, updateCharacter} from "./AdvancedCharacterEditFunctions";
import ChangeToPublicModal from "./Private/ChangeToPublicModal";
import ChangeToPrivateModal from "./Private/ChangeToPrivateModal";
import UnderReviewModal from "./Private/PublicChangesModal";
import {useTranslation} from "react-i18next";

const CustomAccordion = styled(Accordion)({
    backgroundColor: '#171717',
    color: '#C4C4C4',
    borderRadius: '8px',
    '@media (max-width: 680px)': {
        // Add styles for smaller screens
    }
});

const CustomAccordionSummary = styled(AccordionSummary)({
    backgroundColor: '#242424',
    color: 'white',
    borderRadius: '8px',
    "&.Mui-expanded": {
        borderRadius: '0',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        maxHeight: '52px',
        minHeight: '52px'
    },
    '&:hover': {
        backgroundColor: '#202020', // Lighter shade on hover
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'white'
    },
    '@media (max-width: 680px)': {
        padding: '0 10px 0 10px',
        fontSize: '0.9rem',
        maxHeight: '42px',
        minHeight: '42px'
    }
});

const CustomAccordionDetails = styled(AccordionDetails)({
    backgroundColor: '#282828',
    color: 'white',
    padding: '10px',
    borderRadius: '0px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    '@media (max-width: 680px)': {
        padding: '5px 10px 5px 10px'
    }
});

const CustomTextArea = styled('textarea')({
    width: '100%',
    padding: '10px',
    fontSize: '1rem',
    color: 'white',
    backgroundColor: '#171717',
    border: '1px solid #696969',
    borderRadius: '16px',
    boxSizing: 'border-box',
    resize: 'vertical',
    maxHeight: '220px',
    minHeight: '80px',
    overflow: 'auto',
    '@media (max-width: 680px)': {
        resize: 'none',
        maxHeight: '80px'
    }
});

const AdvancedCharacterEdit = ({isOpen, onClose}) => {
    const [data, setData] = useState({});
    const [expanded, setExpanded] = useState('panel0');
    const [isLoading, setIsLoading] = useState(false);
    const [privateStatus, setPrivateStatus] = useState(false);
    const [profileLink, setProfileLink] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [inputs, setInputs] = useState({
        displayName: '',
        shortDescription: '',
        personalityDescription: '',
        appearanceDescription: '',
        scenarioDescription: '',
        dialogueDescription: ''
    });

    const identityName = window.location.pathname.substring(6);

    const [showChangeToPrivateModal, setShowChangeToPrivateModal] = useState(false);
    const [showChangeToPublicModal, setShowChangeToPublicModal] = useState(false);
    const [showUnderReviewModal, setShowUnderReviewModal] = useState(false);
    const [underReview, setUnderReview] = useState(false);

    //TODO: implement review process for character edit!

    const confirmPrivacyChange = () => {
        setPrivateStatus(!privateStatus);
        setShowChangeToPrivateModal(false);
        setShowChangeToPublicModal(false);
        setUnderReview(true);
    };

    useEffect(() => {
        const loadData = async () => {
            if (isOpen) {
                setIsLoading(true);
                try {
                    const characterData = await fetchData(identityName);
                    if (characterData) {
                        setData(characterData);
                        setInputs({
                            displayName: characterData.display_name || '',
                            shortDescription: characterData.description || '',
                            personalityDescription: characterData.personality || '',
                            appearanceDescription: characterData.appearance || '',
                            scenarioDescription: characterData.character_scenario || '',
                            dialogueDescription: characterData.dialogue || '',
                        });
                        setPrivateStatus(characterData.is_public);
                        setUnderReview(characterData.isUnderReview);
                        setImagePreviewUrl(characterData.profile || '');
                    }
                } catch (error) {
                    console.error('Error loading character data:', error);
                    // Handle error (e.g., show error message to user)
                } finally {
                    setIsLoading(false);
                }
            }
        };

        loadData();
    }, [isOpen, identityName]);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleToggle = () => {
        if (privateStatus) {
            setShowChangeToPrivateModal(true);
        } else {
            setShowChangeToPublicModal(true);
        }
    };

    const handleInputChange = (field) => (event) => {
        setInputs({...inputs, [field]: event.target.value});
    };

    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (file) => {
        if (!file) return;
        if (file.size > MAX_FILE_SIZE) {
            alert("File is too large. Please upload a file smaller than 5MB.");
            return;
        }
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
        if (!validTypes.includes(file.type)) {
            alert("Invalid file type. Only PNG, JPG, JPEG, and WEBP are allowed.");
            return;
        }

        setIsLoading(true);
        try {
            const userId = await getUserID();
            const userAccessToken = await getUserAccessToken();
            const base64 = await fileToBase64(file);
            setImagePreviewUrl(base64);

            const response = await fetch('https://api.elyza.app/v1/create-profile-picture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': userAccessToken,
                },
                body: JSON.stringify({
                    userId,
                    base64,
                    isAiGenerated: false,
                    triggerWords: '',
                    baseLora: '',
                    backgroundInformation: {
                        display_name: inputs.displayName
                    }
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setProfileLink(data.url);
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const onFileInputChange = useCallback((event) => {
        handleFileChange(event.target.files[0]);
        //eslint-disable-next-line
    }, []);

    const onDrop = useCallback((event) => {
        event.preventDefault();
        handleFileChange(event.dataTransfer.files[0]);
        //eslint-disable-next-line
    }, []);

    const onDragOver = useCallback((event) => {
        event.preventDefault();
    }, []);


    const handleSave = async () => {
        if (data.is_public) {
            setShowUnderReviewModal(true);
            setUnderReview(true);
        }

        const updatedCharacterData = {
            identityName,
            characterName: inputs.displayName,
            description: inputs.shortDescription,
            isPublic: privateStatus,
            personality: inputs.personalityDescription,
            appearance: inputs.appearanceDescription,
            scenario: inputs.scenarioDescription,
            dialogue: inputs.dialogueDescription,
            profileUrl: profileLink ?? imagePreviewUrl,
            // Add any missing fields that the API expects
            gender: data.gender,
            modelStyle: data.modelStyle,
            isNSFW: data.isNSFW,
            lora: data.lora
        };

        setIsLoading(true);
        try {
            await updateCharacter(updatedCharacterData);
            const updatedData = await fetchData(identityName);
            setData(updatedData);
            setShowSuccessModal(true);
        } catch (error) {
            console.error("Failed to update character:", error);
            // Handle error, maybe show an error modal
        } finally {
            setIsLoading(false);
        }
};

const onCloseModal = () => {
    setExpanded('panel0');
    setIsLoading(false);
    setPrivateStatus(data.is_public);
    setProfileLink(null);
    setImagePreviewUrl(data.profile);
    setInputs({
        displayName: data.display_name,
        shortDescription: data.description,
        personalityDescription: data.personality,
        appearanceDescription: data.appearance,
        scenarioDescription: data.character_scenario,
        dialogueDescription: data.dialogue
    });
    setShowSuccessModal(false);
    onClose();
};

const {t} = useTranslation();

if (!isOpen) return null;

return ReactDOM.createPortal(
    <div className="modal-backdrop">
        {isLoading && (
            <div className="modal-backdrop">
                <div className="message-loading-spin">
                    <BeatLoader color="white"/>
                </div>
            </div>
        )}

        {showSuccessModal && <SuccessModal onClose={() => {
            setShowSuccessModal(false);
            onCloseModal();
        }}/>}

        <div className='content-setting-menu'>
            <div className="main-upper-text-row">
                <div className="profile-setting-label">
                    {t('AdvancedCharacterEdit')}
                </div>
                <div className="close-modal-icon" onClick={onCloseModal}>
                    <CloseIcon/>
                </div>
            </div>

            <div className="line-container">
                <span className="text-line-container">{t('Edit')}</span>
                <div className="line"></div>
            </div>

            <div className="edit-main-selection-container">

                <span className="profile-setting-sublabel">{t('PublicCharacterNotice')}</span>
                <div className="textbox-generate-button-general">
                    <div className="update-profile-image" onDrop={onDrop} onDragOver={onDragOver}>
                        <input type="file" style={{display: 'none'}} id="file-input" onChange={onFileInputChange}/>
                        {imagePreviewUrl ? (
                            <img src={imagePreviewUrl} alt=""
                                 onClick={() => document.getElementById('file-input').click()}/>
                        ) : (
                            <label htmlFor="file-input">{t('UploadImage')}</label>
                        )}
                    </div>
                    <div>
                        <div className="textbox-name-general">
                            <Tooltip title={t('CharacterNameTooltip')} placement="top" arrow>
                                <label className="label-sign-up-h">{t('CharacterNameLabel')}</label>
                            </Tooltip>
                            <input
                                type="text"
                                placeholder={t('CharacterNamePlaceholder')}
                                maxLength="25"
                                value={inputs.displayName}
                                onChange={handleInputChange('displayName')}
                            />
                        </div>

                        <div className='public-to-private-switch'>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={privateStatus}
                                        onChange={handleToggle}
                                        // disabled={isUnderReview}
                                        name="switch1"
                                        sx={{
                                            '& .MuiSwitch-switchBase.Mui-checked': {
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                                },
                                            },
                                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                backgroundColor: '#9072D9',

                                            },
                                            '& .MuiSwitch-track': {
                                                backgroundColor: 'grey',
                                            },
                                            '& .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart': {
                                                marginLeft: '0',
                                            },
                                        }}
                                    />
                                }
                                labelPlacement="start"
                                label={underReview ? t('ChangePendingReview') : t('Public')}
                            />
                        </div>
                    </div>
                </div>

                <div className="accordion-component-main">
                    {[
                        {
                            id: 'panel0',
                            title: t('ShortDescription'),
                            field: 'shortDescription',
                            maxLength: 250,
                            placeholder: t('EnterShortDescription')
                        },
                        {
                            id: 'panel1',
                            title: t('PersonalityDescription'),
                            field: 'personalityDescription',
                            maxLength: 3000,
                            placeholder: t('EnterPersonalityDescription')
                        },
                        {
                            id: 'panel2',
                            title: t('AppearanceDescription'),
                            field: 'appearanceDescription',
                            maxLength: 2000,
                            placeholder: t('EnterAppearanceDescription')
                        },
                        {
                            id: 'panel3',
                            title: t('ScenarioDescription'),
                            field: 'scenarioDescription',
                            maxLength: 1500,
                            placeholder: t('EnterScenarioDescription')
                        },
                        {
                            id: 'panel4',
                            title: t('FirstMessage'),
                            field: 'dialogueDescription',
                            maxLength: 1500,
                            placeholder: t('EnterFirstMessage')
                        },

                    ].map(({id, title, field, maxLength, placeholder}) => (
                        <CustomAccordion key={id} expanded={expanded === id} onChange={handleAccordionChange(id)}>
                            <CustomAccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography>{title}</Typography>
                            </CustomAccordionSummary>
                            <CustomAccordionDetails>
                                <CustomTextArea
                                    rows="4"
                                    cols="50"
                                    maxLength={maxLength}
                                    value={inputs[field]}
                                    onChange={handleInputChange(field)}
                                    placeholder={placeholder}
                                />
                            </CustomAccordionDetails>
                        </CustomAccordion>
                    ))}
                </div>
            </div>

            <div className="footer-button-container"
                 style={{borderTop: '1px solid #696969', marginTop: '10px'}}
            >
                <button
                    style={{width: '100%', marginTop: '15px'}}
                    className="save-settings-button" onClick={handleSave}
                    disabled={underReview}
                >
                    {underReview ? t('ChangesUnderReview') : t('Save')}
                </button>
            </div>
        </div>
        {showChangeToPrivateModal && (
            <ChangeToPrivateModal
                onConfirm={confirmPrivacyChange}
                onCancel={() => setShowChangeToPrivateModal(false)}
            />
        )}

        {showChangeToPublicModal && (
            <ChangeToPublicModal
                onConfirm={confirmPrivacyChange}
                onCancel={() => setShowChangeToPublicModal(false)}
            />
        )}

        {showUnderReviewModal && (
            <UnderReviewModal
                onClose={() => setShowUnderReviewModal(false)}
            />
        )}
    </div>,
    document.body
);
}
;

export default AdvancedCharacterEdit;
import React, {useState, useEffect} from 'react';
import './MainPageLoader.css';
import {useTimer} from "../Context/TimerContext";

const MainPageLoader = () => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [numberOfCards, setNumberOfCards] = useState(0);
    const [numberOfTags, setNumberOfTags] = useState(0);
    const {timerExpired} = useTimer();

    const cardWidth = windowWidth < 780 ? 140 : 200;
    const tagWidth = 80;
    const gap = 10;

    const updateDimensions = () => {
        const screenWidth = window.innerWidth;
        setWindowWidth(screenWidth);
        setNumberOfCards(Math.floor(screenWidth / (cardWidth + gap)));
        setNumberOfTags(Math.floor(screenWidth / (tagWidth + gap)));
    };

    useEffect(() => {
        updateDimensions(); // Set initial size
        window.addEventListener('resize', updateDimensions); // Add resize listener

        // Add class to body to hide scrollbar
        document.body.classList.add('no-scroll');

        return () => {
            window.removeEventListener('resize', updateDimensions);
            // Remove class from body when component unmounts
            document.body.classList.remove('no-scroll');
        };
        //eslint-disable-next-line
    }, []);

    return (
        <div className="main-page-loader"
             style={{marginTop: !timerExpired ? '84px' : '95px'}}
        >
            <div className="loading-tags">
                {Array(numberOfTags).fill().map((_, index) => (
                    <div key={index} className="loading-tag"></div>
                ))}
            </div>

            <div id="loading-event-container"></div>
            <div className="placeholder-unify">
                <div className="placeholder-row">
                    {Array(2).fill().map((_, containerIndex) => (
                        <div key={containerIndex} className="loading-character-row">
                            <div className="loading-row-header"></div>

                            <div className="loading-character-cards">
                                {Array(numberOfCards).fill().map((_, cardIndex) => (
                                    <div key={cardIndex} className="loading-card"></div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="placeholder-row">
                    {Array(2).fill().map((_, containerIndex) => (
                        <div key={containerIndex} className="loading-character-row">
                            <div className="loading-row-header"></div>

                            <div className="loading-character-cards">
                                {Array(numberOfCards).fill().map((_, cardIndex) => (
                                    <div key={cardIndex} className="loading-card"></div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
}

export default MainPageLoader;

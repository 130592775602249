import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";

const FailToCharge = ({ isOpen, onClose }) => {

    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop"
             style={{alignItems: 'center'}}
        >
            <div className="modal-content-notification"
                 style={{
                     borderRadius: '16px'
                 }}
            >
                <div className='notification-label'>
                    ✋ {t('headsUp')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {t('cardChargeError')} <br></br>
                    <div
                        style={{marginTop: '20px'}}
                        className="sublabel-notification">
                        {t('tryDifferentMethod')}
                    </div>
                </div>


                <button onClick={onClose} className="notification-button-main">{t('okay')}

                </button>


            </div>
        </div>
    );
};

export default FailToCharge;
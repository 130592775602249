import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import './AdvancedCharCreatorSteps.css';
import {useAdvancedCharacterCreation} from "../AdvancedCharacterContext";

const AdvancedStepFive = () => {
    const { t } = useTranslation();

    const { advancedCharData, updateAdvancedCharacterData } = useAdvancedCharacterCreation();

    const [scenario, setScenario] = useState(advancedCharData.scenario || '');

    const handleScenarioChange = (e) => {
        setScenario(e.target.value);
        updateAdvancedCharacterData({scenario: e.target.value});
    };

    return (
        <div className ="final-step-container">

            <div className="input-container-middle">
                <div className="textbox-name-general-short">

                        <label className="label-sign-up-h">{t('ScenarioDescription')}</label>
                    <textarea
                        id="personalityDescription"
                        value={scenario}
                        onChange={handleScenarioChange}
                        rows="8"
                        maxLength="3000"
                        cols="50"
                        placeholder={t('ScenarioDescriptionPlaceholder')}
                    />
                </div>
            </div>

        </div>
    );
};

export default AdvancedStepFive;
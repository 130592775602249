import React from 'react';
import ReactDOM from 'react-dom';
import '../ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../../MainMenuPage/icons/cross.svg";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";

const MessageLimitUpsell = ({ isOpen, onClose, onSwitchFree, model, modelSwitchExternalCache }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    if (!isOpen || modelSwitchExternalCache === "Elyza Free") return null;

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop"
                 style={{alignItems: 'center'}}
            >
                <div className="modal-content-notification"
                     style={{
                         borderRadius: '16px'
                     }}
                >
                    <div className='notification-label'>
                        ✋ {t('whoaThere')}
                        <div className="modal-close-icon-notification" onClick={() => {
                            onClose(false);
                            onSwitchFree(true);
                        }}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className="sublabel-notification">
                            <p>
                                {t('reachedLimit')}
                                <span className="colored-purple-text font-bold"> {t('advancedMessages')} </span>
                                {t('limitPeriod')}.
                            </p>
                            <p>
                                {t('unlimitedMessagesPromo')}
                                <span className="colored-purple-text font-bold"> {t('premiumUser')}</span>.
                            </p>

                            {model !== 'Elyza Free' && (
                                t('switchToFreePrompt')
                            )}
                        </div>
                    </div>

                    <button onClick={() => {
                        navigate('/pricing')
                    }} className="notification-button-main"
                            style={{ marginTop: model === 'Elyza Classic' ? '20px' : '10px' }}
                    >{t('upgradeNow')}
                    </button>

                    {model !== 'Elyza Free' && (
                        <button onClick={() => {
                            onSwitchFree(true);
                        }} className="notification-button-main"
                                style={{ backgroundColor: 'transparent', marginTop: '10px', color: "#c4c4c4", fontWeight: "500", fontSize: "0.95em", height: "30px"}}
                        >{t('switchToFree')}</button>
                    )}
                </div>
            </div>
        ),
        document.body
    );
};

export default MessageLimitUpsell;
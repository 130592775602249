import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import './CharCreatorSteps.css';

import AnimeFSmallButt from "../images/femaleSpecifics/anime/butt/AnimeFSmallButt.png";
import AnimeFMediumButt from "../images/femaleSpecifics/anime/butt/AnimeFMediumButt.png";
import AnimeFBigButt from "../images/femaleSpecifics/anime/butt/AnimeFBigButt.png";
import AnimeFLargeButt from "../images/femaleSpecifics/anime/butt/AnimeFLargeButt.png";

import AnimeFSmallTits from "../images/femaleSpecifics/anime/tits/AnimeFSmallTits.png";
import AnimeFMediumTits from "../images/femaleSpecifics/anime/tits/AnimeFMediumTits.png";
import AnimeFBigTits from "../images/femaleSpecifics/anime/tits/AnimeFLargeTits.png";
import AnimeFLargeTits from "../images/femaleSpecifics/anime/tits/AnimeFGiantTits.png";

import RealFSmallButt from "../images/femaleSpecifics/real/butt/RealFSmallButt.png";
import RealFMediumButt from "../images/femaleSpecifics/real/butt/RealFMediumButt.png";
import RealFBigButt from "../images/femaleSpecifics/real/butt/RealFBigButt.png";
import RealFLargeButt from "../images/femaleSpecifics/real/butt/RealFLargeButt.png";

import RealFSmallTits from "../images/femaleSpecifics/real/tits/RealFSmallTits.png";
import RealFMediumTits from "../images/femaleSpecifics/real/tits/RealFMediumTits.png";
import RealFBigTits from "../images/femaleSpecifics/real/tits/RealFLargeTits.png";
import RealFLargeTits from "../images/femaleSpecifics/real/tits/RealFGiantTits.png";
import { useCharacterCreation } from "../CharacterCreationContext";

const SimpleStepEight = () => {
    const { isAnime, characterData, updateCharacterData } = useCharacterCreation();
    const [selectedBreastSize, setSelectedBreastSize] = useState(characterData.breastSize || '');
    const [selectedButtSize, setSelectedButtSize] = useState(characterData.buttSize || '');
    const { t } = useTranslation();

    const bodyParts = {
        animeBreasts: [
            { name: 'Small', image: AnimeFSmallTits },
            { name: 'Medium', image: AnimeFMediumTits },
            { name: 'Large', image: AnimeFBigTits },
            { name: 'Extreme', image: AnimeFLargeTits },
        ],
        animeButt: [
            { name: 'Small', image: AnimeFSmallButt },
            { name: 'Medium', image: AnimeFMediumButt },
            { name: 'Large', image: AnimeFBigButt },
            { name: 'Extreme', image: AnimeFLargeButt },
        ],
        realBreasts: [
            { name: 'Small', image: RealFSmallTits },
            { name: 'Medium', image: RealFMediumTits },
            { name: 'Large', image: RealFBigTits },
            { name: 'Extreme', image: RealFLargeTits },
        ],
        realButt: [
            { name: 'Small', image: RealFSmallButt },
            { name: 'Medium', image: RealFMediumButt },
            { name: 'Large', image: RealFBigButt },
            { name: 'Extreme', image: RealFLargeButt },
        ],
    };

    const handleBreastSelect = (size) => {
        setSelectedBreastSize(size);
        updateCharacterData({ breastSize: size });
    };

    const handleButtSelect = (size) => {
        setSelectedButtSize(size);
        updateCharacterData({ buttSize: size });
    };

    const currentBreasts = isAnime ? bodyParts.animeBreasts : bodyParts.realBreasts;
    const currentButt = isAnime ? bodyParts.animeButt : bodyParts.realButt;

    return (
        <>
            <div className="general-multiple-options-container">
                <div className="top-row">
                    {currentBreasts.map((part, index) => (
                        <div key={`breast-${part.name}-${index}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedBreastSize === part.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleBreastSelect(part.name)}
                            >
                                <img
                                    src={part.image}
                                    alt={part.name}
                                    className={`general-image-creator ${selectedBreastSize === part.name ? 'selected' : ''}`}
                                    style={{pointerEvents: 'none'}}
                                />
                                <span className='enforced-display-char-creator-small'
                                      style={{fontSize: '0.65em', left: '10px'}}
                                >{t(part.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="bottom-row">
                    {currentButt.map((part, index) => (
                        <div key={`butt-${part.name}-${index}`} className="general-option-wrapper">
                            <div
                                className={`general-option-creator-multiple-items ${selectedButtSize === part.name ? 'general-option-creator-selected' : ''}`}
                                onClick={() => handleButtSelect(part.name)}
                            >
                                <img
                                    src={part.image}
                                    alt={part.name}
                                    className={`general-image-creator ${selectedButtSize === part.name ? 'selected' : ''}`}
                                    style={{pointerEvents: 'none'}}
                                />
                                <span className='enforced-display-char-creator-small'
                                      style={{fontSize: '0.65em', left: '10px'}}
                                >{t(part.name)}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default SimpleStepEight;
import React, { useState, useEffect } from 'react';
import './AdvancedCharCreatorSteps.css';
import { useAdvancedCharacterCreation } from "../AdvancedCharacterContext";
import { useTranslation } from "react-i18next";

const AdvancedStepLoras = () => {
    const { loras, advancedCharData, updateAdvancedCharacterData } = useAdvancedCharacterCreation();
    const [unblurredImages, setUnblurredImages] = useState({});
    const [selectedLoraUrl, setSelectedLoraUrl] = useState(advancedCharData.loraAirUrl || '');
    const { t } = useTranslation();

    const regular = loras?.regular || [];

    // Sync with context when component mounts or when relevant data changes
    useEffect(() => {
        setSelectedLoraUrl(advancedCharData.loraAirUrl || '');
    }, [advancedCharData.loraAirUrl]);

    const handleLoraSelect = (lora) => {
        if (selectedLoraUrl === lora.airUrl) {
            // Deselecting current LORA
            setSelectedLoraUrl('');
            updateAdvancedCharacterData({
                loraAirUrl: '',
                loraTrainedWords: ''
            });
        } else {
            // Selecting new LORA
            setSelectedLoraUrl(lora.airUrl);
            updateAdvancedCharacterData({
                loraAirUrl: lora.airUrl,
                loraTrainedWords: lora.trainedWords || ''
            });
        }
    };

    const handleUnblur = (loraName, e) => {
        e.stopPropagation();
        setUnblurredImages(prev => ({ ...prev, [loraName]: true }));
    };

    return (
        <div>
            <div className="general-multiple-options-container">
                <div className="top-row">
                    {regular.length > 0 ? (
                        regular.map((lora, index) => (
                            <div key={`${lora.name}-${index}`} className="general-option-wrapper">
                                <div
                                    className={`general-option-creator-multiple-items ${selectedLoraUrl === lora.airUrl ? 'general-option-creator-selected' : ''}`}
                                    onClick={() => handleLoraSelect(lora)}
                                >
                                    <img
                                        src={lora.imageUrl}
                                        alt={''}
                                        className={`general-image-creator ${selectedLoraUrl === lora.airUrl ? 'selected' : ''}`}
                                        style={{
                                            pointerEvents: 'none',
                                            filter: lora.nsfw && !unblurredImages[lora.name] ? 'blur(10px)' : 'none'
                                        }}
                                    />
                                    {lora.nsfw && !unblurredImages[lora.name] && (
                                        <button
                                            className="unblur-button"
                                            onClick={(e) => handleUnblur(lora.name, e)}
                                        >
                                            {t('Unblur')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdvancedStepLoras;
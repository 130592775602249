import {MoonLoader} from "react-spinners";
import React from "react";
import {useTranslation} from "react-i18next";

const LoadingModal = ({isOpen}) => {

    const {t} = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(10px)',
            background: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999
        }}>
            <div className="modal loading-modal" style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1000
            }}>
                <div className="modal-content">
                    <h2>{t('PleaseWait')}</h2>
                    {t('WeLoadSomething')}
                    <br></br>
                    {t('ItWillTakeASecond')}
                    <div className="message-loading-spin" style={{display: 'flex'}}>
                        <MoonLoader color="white"/>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default LoadingModal;

import React, {useCallback, useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useAdvancedCharacterCreation} from "../AdvancedCharacterContext";
import './AdvancedCharCreatorSteps.css';

//TODO: ensure that we eat up the tokens for profile generation and don't allow free users to do it.
//TODO: ENSURE THAT WE HAVE A GOOD LOADING SCREEN FOR IMAGE GEN PELASE BABY STOP WORKIGN
const CustomSwitch = ({isOn, handleToggle, onText}) => {
    const {t} = useTranslation();

    return (
        <div className="custom-switch-container" onClick={handleToggle}>
            <div className={`custom-switch ${isOn ? 'on' : 'off'}`}>
                <div className="switch-handle"></div>
            </div>
            <span className="switch-text">{t(onText)}</span>
        </div>
    );
};

const AdvancedStepSeven = () => {
    const fileInputRef = useRef(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const {t} = useTranslation();
    const {
        advancedCharData,
        updateAdvancedCharacterData,
        useAiGeneration,
        setUseAiGeneration,
        setImageFile,
        showPrompt,
        setShowPrompt,
        prompt,
        setPrompt,
        generatedProfileImage
    } = useAdvancedCharacterCreation();

    useEffect(() => {
        if (generatedProfileImage) {
            setUseAiGeneration(false);
            setShowPrompt(false);
            // Update the character data with the generated image URL
            updateAdvancedCharacterData({ profileURL: generatedProfileImage });
            setImageFile(generatedProfileImage);
        }
        //eslint-disable-next-line
    }, [generatedProfileImage]);

    useEffect(() => {
        if (advancedCharData.profileURL) {
            setImagePreviewUrl(advancedCharData.profileURL);
        }
    }, [advancedCharData.profileURL]);

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const handleFileChange = async (file) => {
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
        if (!validTypes.includes(file.type)) {
            alert("Invalid file type. Only PNG, JPG, JPEG, WEBP are allowed.");
            return;
        }

        const maxSize = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSize) {
            alert("File size exceeds 5MB limit.");
            return;
        }

        try {
            const base64 = await fileToBase64(file);
            updateAdvancedCharacterData({profileURL: base64});  // Correct function name
            setImagePreviewUrl(base64);
            setImageFile(base64);
        } catch (error) {
            console.error("Error converting file to base64:", error);
        }
    };

    const onFileInputChange = useCallback((event) => {
        const file = event.target.files[0];
        if (file) handleFileChange(file);
        //eslint-disable-next-line
    }, []);

    const onDrop = useCallback((event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) handleFileChange(file);
        //eslint-disable-next-line
    }, []);

    const onDragOver = (event) => {
        event.preventDefault();
    };

    const clearImage = () => {
        setImageFile(null);
        setImagePreviewUrl('');
        updateAdvancedCharacterData({profileURL: ''});
        // Reset the file input value
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleAiGenerationToggle = () => {
        setUseAiGeneration(prev => !prev);
        if (!useAiGeneration) {
            setShowPrompt(true);
        } else {
            setShowPrompt(false);
            setPrompt('');
        }
    };

    const handleShowPromptToggle = () => {
        setShowPrompt(prev => !prev);
    };

    const handlePromptChange = (event) => {
        setPrompt(event.target.value);
    };

    return (
        <>
            <CustomSwitch
                isOn={useAiGeneration}
                handleToggle={handleAiGenerationToggle}
                onText={t('GenerateWithAI')}
            />

            {useAiGeneration && (
                <>
                    <CustomSwitch
                        isOn={showPrompt}
                        handleToggle={handleShowPromptToggle}
                        onText={t('AutoPrompt')}
                    />
                    <div className="textbox-name-general-short">
                        <textarea
                            value={prompt}
                            onChange={handlePromptChange}
                            rows="6"
                            maxLength="500"
                            cols="50"
                            placeholder={t('EnterPrompt')}
                            disabled={showPrompt}
                            style={{maxHeight: '200px'}}
                        />
                    </div>
                    {/*<button onClick={handleGenerate} className="generate-image-button">*/}
                    {/*    Generate Image*/}
                    {/*</button>*/}
                    {/*{generatedProfileImage && (*/}
                    {/*    <div className="generated-image-container">*/}
                    {/*        <img*/}
                    {/*            // src={generatedProfileImage}*/}
                    {/*            src={AnimeTest}*/}
                    {/*            alt="Generated Character"*/}
                    {/*            className="char-final-image"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </>
            )}

            {!useAiGeneration && (
                <div className="image-upload-container" onDrop={onDrop} onDragOver={onDragOver}>
                    {imagePreviewUrl ? (
                        <div className="final-image-option-h">
                            <div className="final-option-image-upload" onClick={clearImage} style={{cursor: 'pointer'}}>
                                <img src={imagePreviewUrl} alt="" className="char-final-image"/>
                                <div className="image-overlay">
                                    <span>{t('ClickToClearImage')}</span>
                                </div>
                            </div>
                            <button onClick={clearImage} className="clear-image-button">
                                {t('ClearImage')}
                            </button>
                        </div>
                    ) : (
                        <label htmlFor="file-input"
                               style={{cursor: 'pointer', display: 'flex', justifyContent: 'center'}}>
                            <div className="image-upload-prompt">
                                <span className="upload-char-creator-adv">{t('DropImageBrowse')}</span>
                                <span>{t('SupportedImages')}</span>
                            </div>
                        </label>
                    )}
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/webp"
                        onChange={onFileInputChange}
                        style={{display: 'none'}}
                        id="file-input"
                    />
                </div>
            )}
        </>
    );
};

export default AdvancedStepSeven;
// ChatContext.js
import React, { createContext, useContext, useState } from 'react';

// Create the Chat context
const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }) => {
    const [isGalleryBannerVisible, setIsGalleryBannerVisible] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [description, setDescription] = useState("");
    const [scenario, setScenario] = useState("");
    const [profileURLBanner, setProfileURLBanner] = useState("");
    const [activeComponent, setActiveComponent] = useState("");
    const [isActiveModal, setIsActiveModal] = useState(false);
    const [wasRecentChatUpdated, setWasRecentChatUpdated] = useState(false);
    const [selectMessageMode, setSelectMessageMode] = useState(false);
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [reloadShareMemories, setReloadShareMemories] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const value = {
        isGalleryBannerVisible,
        setIsGalleryBannerVisible,
        description,
        setDescription,
        scenario,
        setScenario,
        profileURLBanner,
        setProfileURLBanner,
        isMenuOpen,
        setIsMenuOpen,
        activeComponent,
        setActiveComponent,
        toggleMenu,
        isActiveModal,
        setIsActiveModal,
        wasRecentChatUpdated,
        setWasRecentChatUpdated,
        selectedMessages,
        setSelectedMessages,
        selectMessageMode,
        setSelectMessageMode,
        selectAll,
        setSelectAll,
        subscriptionError,
        setSubscriptionError,
        reloadShareMemories, setReloadShareMemories
    };

    return (
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
};

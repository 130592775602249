import React, {useState} from 'react';
import '../MainRewardModal.css';
import {ReactComponent as CopyIcon} from '../RewardIcons/copyIcon.svg';
import {ReactComponent as ElyzaCoinCrossedOut} from '../RewardIcons/rewardCoinCrossedOut.svg';
import {ReactComponent as ElyzaCoin} from '../RewardIcons/elyzaCoin.svg';
import {ReactComponent as ElyzaCoinRedeem} from '../RewardIcons/elyzaCoinRedeem.svg';
import {ReactComponent as RewardMessage} from '../RewardIcons/rewardMessage.svg';
import {ReactComponent as RewardMessageRedeem} from '../RewardIcons/rewardMessageRedeem.svg';
import {useTranslation} from "react-i18next";
import {useProfileData} from "../../Context/ProfileDataContext";
import {getUserAccessToken} from "../../../App";

export const SpecialSection = ({
                                   specialTasks,
                                   clearTaskRewards,
                                   userBonusMessages,
                                   setUserBonusMessages,
                                   userTokens,
                                   setUserTokens
                               }) => {

    const [showSharePopup, setShowSharePopup] = useState(false);
    const {t} = useTranslation();

    const {profileData} = useProfileData();

    const isAccountFree = profileData.subscription_data.tier === "FREE";

    const SharePopup = ({show}) => (
        <div className={`share-popup ${show ? 'show' : ''}`}>
            {t('urlCopied')}
        </div>
    );

    const redeemReward = async (taskItem) => {
        console.log(taskItem.task_code.toUpperCase())
        clearTaskRewards(taskItem.task_code);
        const response = await fetch('https://api.elyza.app/v1/redeem-task', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({taskType: taskItem.task_code.toUpperCase()})
        });

        if (!response.ok) {
            console.error('Failed to redeem reward');
        }

        const data = await response.json();
        const rewards = data.redemptionResponse.rewardCount.split('|');

        setUserBonusMessages(userBonusMessages + parseInt(rewards[0]));
        setUserTokens(userTokens + parseInt(rewards[1]));
    }

    const isTaskComplete = (taskItem) => (taskItem.progress / taskItem.goal) >= 1;
    const isRewardAvailable = (taskItem) => taskItem.extra_data.rewards !== undefined && taskItem.extra_data.rewards.length > 0;
    const isRewardRedeemed = (taskItem) => (taskItem.progress / taskItem.goal) === 1 && taskItem.extra_data.rewards !== undefined && taskItem.extra_data.rewards.length === 0;

    return (
        <>
            {specialTasks.map((taskItem, index) => (
                <div className="reward-item-container special-reward-item" key={index}>
                    <div className={`reward-item-container-sub ${isRewardRedeemed(taskItem) ? 'reward-item-redeemed' : ''}`}>
                        <div className="special-reward-item-content">
                            <div className="special-reward-item-top">
                                <div className={`reward-item-title ${isRewardRedeemed(taskItem) ? 'reward-item-redeemed' : ''}`}>
                                    {taskItem.name}
                                    <br></br>
                                    {taskItem.progress}/{taskItem.goal}
                                </div>
                            </div>


                            {/*<div className="special-reward-item-bottom">*/}
                            {/*    <div className={`reward-item-subtitle ${isRewardRedeemed(taskItem) ? 'reward-item-redeemed' : ''}`}>*/}
                            {/*        {taskItem.description}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        {isTaskComplete(taskItem) && isRewardAvailable(taskItem) ? (
                            <button className="redeem-reward-button" onClick={() => redeemReward(taskItem)}>
                                {t('redeem')}
                                <div className="reward-item-reward-button">
                                    {isAccountFree ? (
                                        <>
                                            +{taskItem.reward.split('|')[0]}
                                            <RewardMessage />
                                        </>
                                    ) : (
                                        <>
                                            +{taskItem.reward.split('|')[1]}
                                            <ElyzaCoin />
                                        </>
                                    )}
                                </div>
                            </button>
                        ) : (
                            <div className="reward-item-reward">
                                {isAccountFree ? (
                                    <>
                                        +{taskItem.reward.split('|')[0]}
                                        {isRewardRedeemed(taskItem) ? <RewardMessageRedeem /> : <RewardMessage />}
                                        <span className="crossed-out-reward">
                                            +{taskItem.reward.split('|')[1]}
                                            <ElyzaCoinCrossedOut />
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        +{taskItem.reward.split('|')[1]}
                                        {isRewardRedeemed(taskItem) ? <ElyzaCoinRedeem /> : <ElyzaCoin />}
                                    </>
                                )}
                            </div>
                        )}

                        {taskItem.name === t('refer_friends_name') && (
                            <div className="share-button-container">
                                <SharePopup show={showSharePopup}/>
                                <button
                                    onClick={() => {
                                        navigator.clipboard.writeText(`https://elyza.app?referral=${profileData.referral}`);
                                        setShowSharePopup(true);
                                        setTimeout(() => setShowSharePopup(false), 2000);
                                    }}
                                    className="special-reward-button"
                                >
                                    {t('copy_link')}
                                    <CopyIcon/>
                                </button>
                            </div>
                        )}



                    </div>
                    <div className="progress-bar-container-reward">
                        <div
                            className="progress-bar-reward"
                            style={{width: `${(taskItem.progress / taskItem.goal) * 100}%`}}
                        ></div>
                    </div>
                </div>
            ))}
        </>
    );
}
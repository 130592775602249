import React, {useContext , useEffect } from 'react';
import './Searchbar.css';
import { ReactComponent as SearchIcon } from "../../icons/search-2.svg";
import {CharacterContext} from "../Context/CharacterContext";
import {useTranslation} from "react-i18next";

const SearchBarNew = ({ setSearchText, searchText }) => {
    const { localSearchText, setLocalSearchText } = useContext(CharacterContext);

    useEffect(() => {

        const handler = setTimeout(() => {
            setSearchText(localSearchText);
        }, 150);

        // Cleanup function to clear the timeout if the component unmounts or the value changes
        return () => clearTimeout(handler);
    }, [localSearchText, setSearchText]);

    const {t} = useTranslation();

    return (
        <div className="search-container">
            <SearchIcon className="search-icon" />
            <input
                className="search-input"
                placeholder={t('Search')}
                value={localSearchText}
                onChange={(e) => setLocalSearchText(e.target.value)}
            />
        </div>
    );
};

export default SearchBarNew;

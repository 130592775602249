import React, { useState, useEffect, useCallback, useRef } from 'react';
import { fetchCharacterMemories } from "../CharacterCardFunctions";
import { useNavigate } from "react-router-dom";
import ViewMemoryModal from "../ViewMemoryModal";
import { getUserAccessToken } from "../../../App";
import { useTranslation } from "react-i18next";

const PublicChats = ({ identityName, setIsUserLoggedIn, publicChatPublicMessages, setPublicChatPublicMessages }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [shareCode, setShareCode] = useState('');
    const [visibleChats, setVisibleChats] = useState([]);
    const containerRef = useRef(null);

    const { t } = useTranslation();

    // Initialize with first 4 items
    useEffect(() => {
        setVisibleChats(publicChatPublicMessages);
    }, [publicChatPublicMessages]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const memoryId = urlParams.get('memoryId');
        const shareCode = urlParams.get('shareCode');

        if (memoryId) {
            if (shareCode === null || shareCode === '') {
                const chat = publicChatPublicMessages.find(chat => chat.id === parseInt(memoryId));
                if (chat) {
                    setSelectedChat(chat);
                    setIsModalOpen(true);
                }
            } else {
                setShareCode(shareCode);
                fetchCharacterMemoriesByMemoryId(memoryId, shareCode)
                    .then(data => {
                        if (data.error === 'Unauthorized to access this memory') {
                            return;
                        } else {
                            setSelectedChat(data);
                        }
                    })
                    .catch(err => {
                        console.log('Error fetching memory:', err);
                    });
                setIsModalOpen(true);
            }
        }
    }, [publicChatPublicMessages]);

    const fetchCharacterMemoriesByMemoryId = async (memoryId, shareCode = '') => {
        const response = await fetch('https://api.elyza.app/v1/render-chat-memory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': await getUserAccessToken()
            },
            body: JSON.stringify({ memoryId, shareCode })
        });
        return response.json();
    };

    const handleUsernameClick = (username) => {
        navigate('/user/' + username)
    }

    const handleChatClick = (chat) => {
        setSelectedChat(chat);
        setIsModalOpen(true);
    };

    const fetchPublicMemories = useCallback(async () => {
        if (identityName) {
            try {
                const response = await fetchCharacterMemories(identityName);
                if (response.success && Array.isArray(response.data)) {
                    setPublicChatPublicMessages(response.data);
                } else {
                    setPublicChatPublicMessages([]);
                }
            } catch (err) {
                console.error('Error fetching public memories:', err);
                setPublicChatPublicMessages([]);
            }
        }
        //eslint-disable-next-line
    }, [identityName]);

    useEffect(() => {
        fetchPublicMemories();
    }, [fetchPublicMemories]);

    const truncate = (str, num) => {
        if (str.length <= num) return str;
        return str.slice(0, num) + '...';
    };

    const containerStyle = {
        // maxHeight: `${INITIAL_ITEMS * 148.75}px`, // Adjust this value based on your chat item height
        overflowY: 'auto',
        display: 'grid',
        // gridTemplateColumns: '1fr 1fr',
        gap: '15px',
        width: '100%'
    };

    return (
        <>
            <div ref={containerRef} style={containerStyle} className="public-chats-grid">
                {visibleChats.map((chat, index) => (
                    <div key={chat.id || index} className="general-public-chat-container">
                        <div className="public-chat-upper"
                             onClick={() => handleUsernameClick(chat.tagname)}
                        >
                            <img
                                src={chat.profile_url}
                                alt={''}
                                className='user-avatar'
                            />
                            <div className="public-chat-author">
                                <span className="public-chat-by-author">
                                    {t('By')}
                                </span>
                                <br/>
                                @{chat.tagname}
                            </div>
                        </div>

                        <div className="public-chat-title">{chat.memory_name}</div>

                        <div
                            className="public-chat-messages"
                            onClick={() => handleChatClick(chat)}
                        >
                            {chat.lastMessages
                                .sort((a, b) => (a.isUser === b.isUser) ? 0 : a.isUser ? 1 : -1)
                                .map((message, msgIndex) => (
                                    <div
                                        key={message.id || msgIndex}
                                        className={message.isUser ? "public-chat-user" : "public-chat-bot"}
                                    >
                                        {truncate(message.content, 50)}
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>

            {selectedChat && (
                <ViewMemoryModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        setShareCode('');
                    }}
                    title={selectedChat.memory_name}
                    memoryId={selectedChat.id}
                    profileURL={selectedChat.profile_url}
                    setIsUserLoggedIn={setIsUserLoggedIn}
                    shareCode={shareCode.length > 0 ? shareCode : selectedChat.share_code}
                    privacyStatus={selectedChat.privacy_status}
                >
                    <div className="modal-chat-messages">
                        {selectedChat.lastMessages
                            .sort((a, b) => (a.isUser === b.isUser) ? 0 : a.isUser ? 1 : -1)
                            .map((message, index) => (
                                <div
                                    key={index}
                                    className={message.isUser ? "modal-chat-user" : "modal-chat-bot"}
                                >
                                    {message.content}
                                </div>
                            ))}
                    </div>
                </ViewMemoryModal>
            )}
        </>
    );
};

export default React.memo(PublicChats);
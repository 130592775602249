import React from 'react';
import ReactDOM from 'react-dom';
import '../Notifications/ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";

const CharWarningModalCentralized = ({isOpen, onClose, message}) => {

    if (!isOpen) return null;

    let content;

    switch (message) {
        case 'Content violates our policies':
            content = {
                title: '🚫 Violation Detected',
                body: 'A severe violation of our terms of usage has been detected. The content you provided is not allowed on our platform.',
                contactSupport: 'If you believe this is a mistake, please contact support.',
                action: 'Your account may be subject to further review and possible suspension.',
                buttonText: 'Acknowledge'
            };
            break;
        default:
            content = {
                title: '📢 Uh oh!',
                body: 'Something went wrong. It might be something on our end. Please try again later.',
                contactSupport: 'If the issue persists, please contact support.',
                buttonText: 'OK'
            };
    }

    return ReactDOM.createPortal(
        (
            <div className="modal-backdrop"
            style={{zIndex: '9999', alignItems: 'center'}}
            >

                    <div className="modal-content-notification"
                         style={{
                             borderRadius: '16px'
                         }}
                    >
                    <div className='notification-label'>
                        {content.title}
                        <div className="modal-close-icon-notification" onClick={onClose}>
                            <CloseIcon/>
                        </div>
                    </div>

                    <div className="gap-between-notification">
                        <div className='sublabel-notification'>
                            {content.body}
                            <br></br>
                            <br></br>
                            {content.action}
                            <br></br>
                            <br></br>
                            {content.contactSupport}
                            <br></br>
                        </div>
                    </div>

                    <button onClick={onClose} className="notification-button-main">{content.buttonText}</button>
                </div>
            </div>
        ),
        document.body
    );
};

export default CharWarningModalCentralized;
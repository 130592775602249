import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import './AdvancedCharCreatorSteps.css';
import {useAdvancedCharacterCreation} from "../AdvancedCharacterContext";
import PublicCharacterWarning from "../../../components/Modals/PublicCharacterWarning";

const AdvancedStepOneHalf = () => {
    const {advancedCharData, updateAdvancedCharacterData} = useAdvancedCharacterCreation();
    const {t} = useTranslation();
    // Initialize visibility based on the isPublic string value, not boolean conversion
    const [visibility, setVisibility] = useState(advancedCharData.isPublic);
    const [imageStyle, setImageStyle] = useState(advancedCharData.style || '');
    const [publicCharacterWarning, setPublicCharacterWarning] = useState(false);

    // Ensure synchronization with context
    useEffect(() => {
        setVisibility(advancedCharData.isPublic);
    }, [advancedCharData.isPublic]);

    const onConfirm = () => {
        setVisibility('Public');
        updateAdvancedCharacterData({isPublic: 'Public'});
        setPublicCharacterWarning(false);
    }

    const handleOptionSelect = (category, option) => {
        let updatedData = {};

        switch (category) {
            case 'visibility':
                if (option === 'Public') {
                    setPublicCharacterWarning(true);
                } else {
                    setVisibility('Private');
                    updatedData = {isPublic: 'Private'};
                    updateAdvancedCharacterData(updatedData);
                }
                break;
            case 'imageStyle':
                setImageStyle(option);
                updatedData = {style: option};
                updateAdvancedCharacterData(updatedData);
                break;
            default:
                return;
        }
    };

    const imageStyleOptions = [
        {option: 'Anime', description: t('AnimeDescription')},
        {option: 'Realistic', description: t('RealisticDescription')},
        {option: 'Furry', description: t('FurryDescription')},
    ];

    const visibilityOptions = [
        {option: 'Public', description: t('PublicDescription')},
        {option: 'Private', description: t('PrivateDescription')},
    ];

    const renderOptions = (options, selectedOption, category) => (
        <div className="options-container">
            {options.map(({option, description}) => (
                <div
                    key={option}
                    className={`option-box ${selectedOption === option ? 'selected' : ''}`}
                    onClick={() => handleOptionSelect(category, option)}
                >
                    <span className="option-text">{t(option)}</span>
                    <span className="option-description">{description}</span>
                </div>
            ))}
        </div>
    );

    return (
        <>
            <div className="advanced-step-one">
                {renderOptions(visibilityOptions, visibility, 'visibility')}
                {renderOptions(imageStyleOptions, imageStyle, 'imageStyle')}
            </div>
            <PublicCharacterWarning
                isOpen={publicCharacterWarning}
                onClose={() => {
                    setPublicCharacterWarning(false);
                }}
                onConfirm={onConfirm}
            />
        </>
    );
};

export default AdvancedStepOneHalf;
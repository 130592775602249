import React from 'react';
import './BannerStyle.css';
import {ReactComponent as Discord} from './BannerIcons/discordbanner.svg';
import {ReactComponent as StarIcon} from './BannerIcons/buttonIcon.svg';
import {useTranslation} from "react-i18next";

const JoinCommunityBanner = ({ isBannerVisibleFirstDiscord, hideBanner }) => {
    const {t} = useTranslation();
    const handleClick = () => {
        window.open('https://discord.gg/V4YUYRBc5M', '_blank');
    };

    if (!isBannerVisibleFirstDiscord) return null;


    return (
        <div className="banner-container banner-state-1">
            <div className="banner-content">
                <div className="banner-left">
                    <span className="banner-title">{t('JoinCommunity')}</span>
                    <span className="banner-subtitle">{t('OnDiscord')}
                        <div className='banner-icon'><Discord/></div>
                    </span>
                </div>
                <div className="banner-right">
                    <div className="center-component-banner">
                        <button className="banner-button button-state-1" onClick={handleClick}>
                            <StarIcon className='star-icon-banner'/>
                            {t('JoinServer')}
                        </button>
                        <span className='sub-button-text' onClick={hideBanner}>{t('RemindJoinLater')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JoinCommunityBanner;

import React, { useState, useEffect } from 'react';
import './ChatPageLoader.css';
import {useTimer} from "../Context/TimerContext";
import {useChat} from "../Context/ChatContext";

const ChatPageLoader = () => {
    const [messages, setMessages] = useState([]);

    const [sendMessageSkeletonHeight, setSendMessageSkeletonHeight] = useState(calculateSendMessageSkeletonHeight());
    const extraSpaceForOneMoreMessage = 160; // Additional space reserved for clarity
    const glidingRectangleHeight = 80;

    // Function to calculate sendMessageSkeletonHeight based on screen width
    function calculateSendMessageSkeletonHeight() {
        return window.innerWidth < 680 ? 56 : 76;
    }

    const updateMessages = () => {
        const totalSubtraction = glidingRectangleHeight + sendMessageSkeletonHeight + extraSpaceForOneMoreMessage;
        const availableHeight = window.innerHeight - totalSubtraction;

        let totalHeight = 0;
        const newMessages = [];
        let messageId = 0;

        while (totalHeight < availableHeight) {
            const messageHeight = messageId % 2 === 0 ? 160 : 80; // User messages are 160px, bot messages are 80px

            if (totalHeight + messageHeight > availableHeight) break;

            newMessages.push({
                id: messageId,
                type: messageId % 2 === 0 ? 'user-message' : 'bot-message',
                height: messageHeight,
            });
            totalHeight += messageHeight;
            messageId += 1;
        }

        setMessages(newMessages);
    };

    useEffect(() => {
        updateMessages(); // Initial calculation and setup

        // Handler to update based on resize events
        const handleResize = () => {
            setSendMessageSkeletonHeight(calculateSendMessageSkeletonHeight());
            updateMessages();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendMessageSkeletonHeight]); // Depend on sendMessageSkeletonHeight to re-calculate when it changes

    const { timerExpired } = useTimer();

    const {
        isMenuOpen,
    } = useChat();

    let marginBottom;
    if (window.innerWidth < 680) {
        // Small screen scenarios
        marginBottom = timerExpired !== false ? '20px' : '80px'; // last option is active timer
    } else {
        // Big screen scenarios
        marginBottom = timerExpired !== false ? '20px' : '80px';
    }

    const getStyles = () => ({
        top: timerExpired !== false ? '0' : '60px',
        width: window.innerWidth < 680 ? '100vw' : (isMenuOpen ? 'calc(100vw - 360px)' : '100vw'),
    });

    return (
        <div className="chat-page-loader"
             style={getStyles()}
        >
            <div className="gliding-rectangle"></div>

            {messages.map((message) => (
                <div key={message.id} className={message.type} style={{height: `${message.height}px`}}></div>
            ))}
            <div
                className="send-message-skeleton"
                style={{
                    height: `${sendMessageSkeletonHeight}px`,
                    marginBottom: `${marginBottom}`,
                }}
            ></div>
        </div>
    );
}

export default ChatPageLoader;

import React, {useEffect, useState, useCallback} from "react";
import {ReactComponent as CrownIcon} from "../../icons/crown.svg";
import {useTimer} from "../Context/TimerContext";
import {useProfileData} from "../Context/ProfileDataContext";
import {useTranslation} from "react-i18next";

const HOUR_IN_MS = 3600000;
const DAY_IN_MS = 86400000;

const getTargetTime = () => {
    const savedTargetTime = localStorage.getItem('countdownTargetTime');
    return savedTargetTime ? parseInt(savedTargetTime, 10) : null;
};

const setNewTargetTime = (isAlternateVersion) => {

    const now = Date.now();
    const newTargetTime = now + (isAlternateVersion ? DAY_IN_MS : HOUR_IN_MS);
    localStorage.setItem('countdownTargetTime', newTargetTime.toString());
    return newTargetTime;
};

const calculateTimeLeft = (targetTime) => {
    const now = Date.now();
    const distance = targetTime - now;
    if (distance < 0) {
        return {hours: 0, minutes: 0, seconds: 0, milliseconds: 0, expired: true};
    } else {
        return {
            hours: Math.floor(distance / HOUR_IN_MS),
            minutes: Math.floor((distance % HOUR_IN_MS) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
            milliseconds: Math.floor((distance % 1000) / 10),
            expired: false
        };
    }
};

const CountdownTimer = ({onExpire, targetTime, isAlternateVersion}) => {
    const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(targetTime));

    useEffect(() => {
        const updateTimer = () => {
            const newTimeLeft = calculateTimeLeft(targetTime);
            setTimeLeft(newTimeLeft);

            if (!newTimeLeft.expired) {
                requestAnimationFrame(updateTimer);
            } else {
                onExpire();
            }
        };

        const animationId = requestAnimationFrame(updateTimer);
        return () => cancelAnimationFrame(animationId);
    }, [targetTime, onExpire]);

    const timerStyle = {display: 'flex', alignItems: 'baseline'};
    const timerSegmentStyle = {display: 'flex', flexDirection: 'column', alignItems: 'flex-start'};
    const numberStyle = {fontFamily: 'Sans-Serif'};
    const textStyle = {fontFamily: 'unset'};

    const {t} = useTranslation();

    return (
        <div style={timerStyle}>
            {isAlternateVersion && (
                <>
                    <div style={timerSegmentStyle}>
                        <span style={numberStyle}>{String(timeLeft.hours).padStart(2, '0')}</span>
                        <small style={textStyle}>{t('Hrs')}</small>
                    </div>
                    <span style={{marginRight: '6px'}}>:</span>
                </>
            )}
            <div style={timerSegmentStyle}>
                <span style={numberStyle}>{String(timeLeft.minutes).padStart(2, '0')}</span>
                <small style={textStyle}>{t('Min')}</small>
            </div>
            <span style={{marginRight: '6px'}}>:</span>
            <div style={timerSegmentStyle}>
                <span style={numberStyle}>{String(timeLeft.seconds).padStart(2, '0')}</span>
                <small style={textStyle}>{t('Sec')}</small>
            </div>
            <span style={{marginRight: '6px'}}>:</span>
            <div style={timerSegmentStyle}>
                <span style={numberStyle}>{String(timeLeft.milliseconds).padStart(2, '0')}</span>
                <small style={textStyle}>{t('Ms')}</small>
            </div>
        </div>
    );
};

const TopTimer = ({onTimerActive}) => {
    const {profileData} = useProfileData();
    const {timerExpired, setTimerExpired, isAlternateVersion, bonusOfferTimestamp} = useTimer();
    const [targetTime, setTargetTime] = useState(null);

    const [bonusOfferPercent, setBonusOfferPercent] = useState(100);

    useEffect(() => {
        if (profileData.bonus_offer === 'BASIC') {
            setBonusOfferPercent(100);
        } else if (profileData.bonus_offer === 'PREMIUM') {
            setBonusOfferPercent(140);
        } else if (profileData.bonus_offer === 'ULTIMATE') {
            setBonusOfferPercent(220);
        } else {
            setBonusOfferPercent(100);
        }
    }, [profileData.bonus_offer]);

    const initializeTimer = useCallback(() => {
        if (isAlternateVersion && bonusOfferTimestamp) {
            const offerTimestamp = new Date(bonusOfferTimestamp).getTime();
            const expirationTime = offerTimestamp + DAY_IN_MS;
            setTargetTime(expirationTime);
        } else {
            const savedTargetTime = getTargetTime();
            if (savedTargetTime && savedTargetTime > Date.now()) {
                setTargetTime(savedTargetTime);
            } else {
                setTargetTime(setNewTargetTime(isAlternateVersion));
            }
        }
    }, [isAlternateVersion, bonusOfferTimestamp]);

    useEffect(() => {
        const visitedPricing = localStorage.getItem('visitedPricing') === 'true';
        if (!visitedPricing) {
            setTimerExpired(true);
        } else if (profileData.subscription_data.tier !== "FREE" && !isAlternateVersion) {
            setTimerExpired(true);
        } else {
            setTimerExpired(false);
            initializeTimer();
        }
        //eslint-disable-next-line
    }, [profileData.subscription_data.tier, setTimerExpired, initializeTimer]);

    useEffect(() => {
        if (typeof onTimerActive === 'function') {
            onTimerActive(!timerExpired);
        }
    }, [timerExpired, onTimerActive]);

    const handleExpire = useCallback(() => {
        localStorage.removeItem('countdownTargetTime');
        setTimerExpired(true);
    }, [setTimerExpired]);

    const containerStyle = {
        backgroundColor: isAlternateVersion ? '#984BC4FF' : '#6C3EA6',
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    const handleClick = () => {
        const path = isAlternateVersion ? '/addons' : '/pricing';
        if (window.location.pathname !== path) {
            window.location.href = path;
        }
    };

    const { t } = useTranslation();

    return (
        <div  className="top-timer-container" style={containerStyle} onClick={handleClick}>
            <div className="top-timer-text">
                <CrownIcon/>
                {isAlternateVersion ? t('LIMITED_PREMIUM_OFFER') : t('FIRST_SUBSCRIPTION')}
            </div>
            <div className="top-timer-padding">
                {isAlternateVersion ? `${t('Get')} ${bonusOfferPercent}% ${t('More Tokens!')}` : t('OFF')}
            </div>
            {!timerExpired && targetTime && (
                <div className="top-timer-timer-general">
                    <div className="top-timer-timer">
                        <CountdownTimer onExpire={handleExpire} targetTime={targetTime}
                                        isAlternateVersion={isAlternateVersion}/>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TopTimer;
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg';
import { ReactComponent as GroupChatIcon } from "../../../icons/multiplepeople.svg";
import { useTranslation } from "react-i18next";
import './ChatMainMenu.css';

const DesktopRecentChatItem = ({
                                   chat,
                                   isSelected,
                                   onDelete,
                                   onSelect,
                                   formatTimeAgo,
                                   truncateMessage,
                               }) => {
    const { t } = useTranslation();

    return (
        <div
            className={`sidebar-menu-item ${isSelected ? 'selected' : ''}`}
            onClick={() => onSelect(chat)}
        >
            <div className="recent-chat-info">
                {chat.imageSrc ? (
                    <div className="chat-image-container">
                        <LazyLoadImage
                            className="recent-chat-image"
                            src={`https://elyza.app/cdn-cgi/image/format=avif,width=128,height=128,compression=fast/${chat.imageSrc}`}
                            alt="Chat Image"
                            effect="blur"
                            loading="lazy"
                            draggable="false"
                            onContextMenu={(e) => e.preventDefault()}
                        />
                    </div>
                ) : (
                    <GroupChatIcon className="group-chat-icon" />
                )}
                <div className="chat-details">
                    <div className="recent-chat-label">{chat.name}</div>
                    <div className="recent-chat-last-message">
                        <span>
                            {truncateMessage(chat.lastMessageContent, 40, chat.name, chat.identityName)}
                        </span>
                    </div>
                </div>
            </div>

            <div className="recent-chat-time">
                {formatTimeAgo(chat.lastMessageTime)}
            </div>

            <div className="icons-container" onClick={(event) => event.stopPropagation()}>
                <button
                    className="recent-chat-delete-icon"
                    onClick={() => onDelete(chat)}
                >
                    <Tooltip title={t('DeleteConvo')} arrow>
                        <DeleteIcon />
                    </Tooltip>
                </button>
            </div>
        </div>
    );
};

export default DesktopRecentChatItem;
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as CloseIcon } from '../icons/cross.svg';
import { useTranslation } from "react-i18next";

const EditNameShareMemoryModal = ({ isOpen, onClose, onChangeConfirm, newName, setNewName }) => {

    const handleNameChange = (e) => {
        setNewName(e.target.value);
    };

    const handleEditConfirm = () => {
        onChangeConfirm();
        onClose();
    }

    const { t } = useTranslation();

    if (!isOpen) return null;

    const modalContent = (
        <div className="main-space-finder-modal" onClick={onClose} role="dialog" aria-modal="true">
            <div className="app-modal-content-response-settings"
                 style={{ width: '50%' }}
                 onClick={(e) => e.stopPropagation()}>

                <div className="main-upper-text-row">
                    <div className="profile-setting-label">{t('editMemoryTitle')}</div>
                    <div className="close-modal-icon" onClick={onClose} aria-label={t('close')}>
                        <CloseIcon />
                    </div>
                </div>

                <div className="profile-setting-sublabel">
                    {t('editMemoryDescription')}
                </div>

                <div className="textbox-name-general"
                     style={{ marginTop: '5px', marginBottom: '15px', width: '100%' }}
                >
                    <input
                        type="text"
                        placeholder={t('memoryNamePlaceholder')}
                        value={newName}
                        onChange={handleNameChange}
                        maxLength="25"
                    />
                </div>

                <div className="parent">
                    <button onClick={handleEditConfirm} className="save-settings-button" style={{ width: '100%', height: '45px', marginTop: '15px' }}>
                        {t('updateMemoryButton')}
                    </button>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.body
    );
};

export default EditNameShareMemoryModal;

import React, { createContext, useState, useEffect, useContext } from 'react';
import { useProfileData } from "./ProfileDataContext";

const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
    const [timerExpired, setTimerExpired] = useState(null);
    const [isAlternateVersion, setIsAlternativeVersion] = useState(null);
    const [bonusOfferTimestamp, setBonusOfferTimestamp] = useState(null);
    const { profileData } = useProfileData();

    useEffect(() => {
        if (profileData.bonus_offer === 'BASIC' || profileData.bonus_offer === 'PREMIUM' || profileData.bonus_offer === 'ULTIMATE') {
            setIsAlternativeVersion(true);
            setTimerExpired(false);
            setBonusOfferTimestamp(profileData.bonus_offer_timestamp);
        }

        if (profileData.bonus_offer_timestamp) {
            const offerTimestamp = new Date(profileData.bonus_offer_timestamp).getTime();
            const currentTime = new Date().getTime();
            const timeDifference = currentTime - offerTimestamp;
            const hoursPassed = timeDifference / (1000 * 60 * 60);

            if (hoursPassed > 24) {
                setIsAlternativeVersion(false);
                setTimerExpired(true);
            }
        }
    }, [profileData]);

    const value = { timerExpired, setTimerExpired, isAlternateVersion, bonusOfferTimestamp };

    return <TimerContext.Provider value={value}>{children}</TimerContext.Provider>;
};

export const useTimer = () => {
    const context = useContext(TimerContext);
    if (context === undefined) {
        throw new Error('useTimer must be used within a TimerProvider');
    }
    return context;
};
import {getUserAccessToken} from "../../App";

export async function fetchCharacterCardDetails(identityName) {
    const response = await fetch('https://api.elyza.app/v1/fetch-identity-card-details', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({identityName})
    });
    const data = await response.json();
    if (data.error === "Failed to fetch identity data" || data.error === 'Identity is private') {
        throw new Error("Failed to fetch identity data");
    }
    return data;
}

export async function addToFavorites(identityName) {
    const response = await fetch('https://api.elyza.app/v1/add-to-favorites', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ identityName })
    });
    return response.json();
}

export async function removeFromFavorites(identityName) {
    const response = await fetch('https://api.elyza.app/v1/remove-from-favorites', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ identityName })
    });
    return response.json();
}

export async function fetchSimilarCharacters(identityName, start = 0, count = 4) {

    const response = await fetch('https://api.elyza.app/v1/similar-character', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({ identityName, start, count })
    });
    return response.json();
}

export async function fetchCharacterMemories(identityName) {

    const response = await fetch('https://api.elyza.app/v1/render-chat-memories', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await getUserAccessToken()
        },
        body: JSON.stringify({identityName})
    });

    return response.json();
}


import React from "react";
import { useTranslation } from 'react-i18next';

export const AspectRatioButton = ({ ratio, selected, onClick }) => {
    const { t } = useTranslation();

    const ratioValues = {
        landscape: { width: 3, height: 2 },
        square: { width: 1, height: 1 },
        portrait: { width: 2, height: 3 }
    };

    const { width, height } = ratioValues[ratio];

    return (
        <button
            onClick={() => onClick(ratio)}
            className={`aspect-ratio-button ${selected ? 'selected' : ''}`}
        >
            <div className="aspect-ratio-visual" style={{ aspectRatio: `${width} / ${height}` }}>
                <div className="aspect-ratio-inner"></div>
            </div>
            <span>{t(ratio.charAt(0).toUpperCase() + ratio.slice(1))}</span>
        </button>
    );
};

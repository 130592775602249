import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Notifications/ModalAndNotifications.css';
import { ReactComponent as CloseIcon } from "../MainMenuPage/icons/cross.svg";
import { Link } from "react-router-dom";

const PublicCharacterWarning = ({ isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation();

    const handleSubmitCharacter = () => {
        onConfirm();
        onClose();
    }

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-label'>
                    ✋{t('Important!')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='sublabel-notification'>
                    {t('By making a public character on Elyza, you are agreeing to share this character with other users.')}
                    <br></br>
                    <br></br>
                    {t('Additionally, you are agreeing to the')}
                    <span className="terms-link">
                        <Link to="/legal/public-content">{t('Terms & Conditions')}</Link>
                    </span>
                    {t('of public content on Elyza.')}
                    <br></br>
                    <br></br>
                    {t('Your character will be reviewed by our moderation team before it becomes public.')}
                </div>

                <button onClick={handleSubmitCharacter} className="notification-button-main">
                    {t('Acknowledge')}
                </button>
                <button onClick={onClose}
                        style={{
                            color: 'white',
                            fontWeight: 700,
                            fontSize: '1em',
                            cursor: "pointer",
                            width: '100%',
                            marginTop: '15px'
                        }}
                >
                    {t('I changed my mind.')}
                </button>

            </div>
        </div>
    );
};

export default PublicCharacterWarning;

import React, {useEffect, useState} from "react";
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";
import Cookies from 'js-cookie';

const BioChangePopup = ({ isOpen, onClose, newBio, setNewBio, onSave }) => {
    const { t } = useTranslation();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        const checkCooldown = () => {
            const lastSaveTime = Cookies.get('lastBioSaveTime');
            if (lastSaveTime) {
                const currentTime = new Date().getTime();
                const timeDiff = currentTime - parseInt(lastSaveTime);
                const cooldownPeriod = 8 * 60 * 60 * 1000;

                if (timeDiff < cooldownPeriod) {
                    setIsButtonDisabled(true);
                    setTimeLeft(Math.ceil((cooldownPeriod - timeDiff) / (1000 * 60 * 60))); // remaining hours
                } else {
                    setIsButtonDisabled(false);
                    setTimeLeft(0);
                }
            } else {
                setIsButtonDisabled(false);
                setTimeLeft(0);
            }
        };

        checkCooldown();
        const interval = setInterval(checkCooldown, 60000); // Check every minute

        return () => clearInterval(interval);
    }, []);

    const handleBioChange = (event) => {
        setNewBio(event.target.value);
    }

    const handleSave = () => {
        onSave();
        Cookies.set('lastBioSaveTime', new Date().getTime(), { expires: 1 }); // Expires in 1 day
        setIsButtonDisabled(true);
        setTimeLeft(8);
    }

    if (!isOpen) return null;

    return (
        <div className="popup-background" onClick={onClose}>
            <div className="name-change-popup" onClick={(e) => e.stopPropagation()} style={{ maxWidth: '480px' }}>
                <div className="main-upper-text-row">
                    <div className="profile-setting-label">{t('writeNewBio')}</div>
                    <div className="close-modal-icon" onClick={onClose}><CloseIcon/></div>
                </div>
                <div className="profile-setting-sublabel">{t('bioChangeLimit')}</div>
                <div className="textbox-name-general-short">
                    <textarea
                        id="scenarioDescription"
                        value={newBio}
                        style={{ resize: 'none', marginTop: '20px' }}
                        onChange={handleBioChange}
                        rows="4"
                        maxLength="1500"
                        cols="50"
                        placeholder={t('bioPlaceholder')}
                    />
                </div>
                <div className='a-straight-line-3'></div>
                <button
                    className={`save-new-name-button ${isButtonDisabled ? 'disabled' : ''}`}
                    onClick={handleSave}
                    disabled={isButtonDisabled}
                    style={{
                        background: isButtonDisabled ? 'gray' : '',
                        cursor: isButtonDisabled ? 'not-allowed' : 'pointer'
                    }}
                >
                    {isButtonDisabled ? t('changeBioIn', { timeLeft }) : t('saveBio')}
                </button>
            </div>
        </div>
    );
}

export default BioChangePopup;

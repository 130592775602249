import React from 'react';
import ReactDOM from 'react-dom';
import './DeleteMessageModal.css';
import { ReactComponent as CloseIcon } from '../../icons/cross.svg';
import {useTranslation} from "react-i18next";

const DeleteMessageModal = ({ isVisible, onDeleteConfirm, onCancel }) => {

    const { t } = useTranslation();
    if (!isVisible) return null;

    const modalContent = (
        <div className="main-space-finder-modal" onClick={onCancel}>
            <div className="app-modal-content-response-settings"
                 style={{width: '35%'}}
                 onClick={(e) => e.stopPropagation()}>

                <div className="main-upper-text-row">
                    <div className="profile-setting-label">{t('deleteMessage1')}</div>
                    <div className="close-modal-icon" onClick={onCancel}><CloseIcon/></div>

                </div>
                <div className="profile-setting-sublabel">{t('deleteMessage2')}</div>

                <div className="parent">
                    <button onClick={onDeleteConfirm} className="save-settings-button" style={{width: '100%', height: '45px', marginTop: '15px' }}>{t('DeleteMessage')}</button>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.body
    );
};

export default DeleteMessageModal;

import React from "react";
import "./NewPricingContainer.css";
import {ReactComponent as CheckIcon} from "../icons/CheckIcon.svg";
import {ReactComponent as SafeCrossIcon} from "../icons/safeCross.svg"
import {useTranslation} from "react-i18next";

export function NewPricingContainer({ heading, features, onSelect, isSelected }) {

    const {t} = useTranslation();

    const getPlanClass = (heading) => {
        switch (heading) {
            case `${t('premiumPlanHeading')}`:
                return "new-premium-container";
            case `${t('ultimatePlanHeading')}`:
                return "new-ultimate-container";
            case `${t('basicPlanHeading')}`:
                return "new-basic-container";
            case `${t('freePlanHeading')}`:
                return "new-free-container";
            default:
                return "";
        }
    };

    const iconForFeature = (feature) => {
        switch (feature) {
            case t('allBasicPlanFeatures'):
            case t('unlimitedMessages'):
            case t('unlimitedMessagesFeature'):
            case t('aiCharacterCreation'):
            case t('advancedMemory'):
            case t('nsfwImages'):
            case t('earlyAccess'):
            case t('basicMemory'):
            case t('hundredAudiosPerMonth'):
            case t('fiveHundredAudiosPerMonth'):
            case t('fiftyAudiosPerMonth'):
            case t('fiftyMessagesPerMonth'):
            case t('joinGroupChatsWithLimits'):
            case t('joinGroupChatsWithoutLimits'):
            case t('createAndJoinGroupChats'):
            case t('createExclusiveCharactersBasic'):
            case t('earlyAccessNewFeaturesVideo'):
            case t('customScenariosAndSettings'):
            case t('ultimateMemory'):
            case t('createExclusiveCharacters'):
            case t('unlimitedImageGeneration'):
            case t('giftMessagesAndImages'):
            case t('betterMemory'):
            case t('fasterAccessBetaFeatures'):
            case t('maximumMemory'):
            case t('earlyAccessNewFeatures'):
            case t('advancedChatModel'):
            case t('unlimitedMLModel'):
            case t('refundPoorImages'):
            case t('dynamicScenario'):
            case t('oneMemoryFragment'):
            case t('onePersonalityProfile'):
            case t('twoMemoryFragments'):
            case t('twoPersonalityProfiles'):
            case t('fourMemoryFragments'):
            case t('fourPersonalityProfiles'):
            case t('sixMemoryFragments'):
            case t('sixPersonalityProfiles'):
                return <CheckIcon className="check-icon-pricing" />;
            case t('referralProgram'):
            case t('accessFreeCharacters'):
                return <CheckIcon className="check-icon-pricing" />;
            case t('accessCapableModelsAndNSFW'):
            case t('createGroupChats'):
            case t('customSettings'):
                return <SafeCrossIcon className="check-icon-pricing" />;
            default:
                return null;
        }
    };


    const handleClick = () => {
        if(heading === `${t('freePlanHeading')}`) return;
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Enables smooth scrolling
        });
        if (onSelect) onSelect(heading);
    };



    return (
        <div className="feature-card-container" onClick={handleClick}>
            <div className={`new-pricing-card ${getPlanClass(heading)} ${isSelected ? "selected-plan" : ""}`}>



                {heading === `${t('premiumPlanHeading')}` ? <div className="new-mostPopular">{t('MostPopular')}</div> : null}
                {heading === `${t('ultimatePlanHeading')}` ? <div className="new-ultimatePlan">{t('BestValue')}</div> : null}
                {heading === `${t('basicPlanHeading')}` ? <div className="new-unlimitedMessages">{t('StayConnected')}</div> : null}


            <div className="new-sidebarMenu">
                <h2 className="new-textnamesub">{heading}</h2>

                {features.map((feature, index) => (
                    <div className="new-menuOptionContainer" key={index}>
                        {iconForFeature(feature)}
                        <span className="new-menuText">{feature}</span>
                    </div>
                ))}

            </div>
        </div>
        </div>
    );
}

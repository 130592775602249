import React from 'react';
import {NewPricingContainer} from "../../components/MainMenuPage/PricingPage/NewPricingContainer";
import Slider from "react-slick";
import './MorePlansModal.css';

const MorePlansModal = ({
                            showMorePlans,
                            handleClickOutside,
                            extraAdditionalPricingData,
                            selectedPlanHeading,
                            handlePlanSelection,
                            isWide
                        }) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isWide ? 2 : 1,
        slidesToScroll: 1,
        arrows: false,
        className: 'elyza-pricing-slider'
    };

    if (!showMorePlans) return null;

    return (
        <>
            <div className="elyza-modal-backdrop" onClick={handleClickOutside}></div>
            <div className="elyza-modal-pricing-wrapper" onClick={(e) => e.stopPropagation()}>
                <div className="elyza-modal-content">
                        <Slider {...settings}>
                            {extraAdditionalPricingData.map(plan => (
                                <div key={plan.heading} className="elyza-pricing-slide-item">
                                    <NewPricingContainer
                                        {...plan}
                                        heading={plan.heading}
                                        features={plan.features}
                                        isSelected={selectedPlanHeading === plan.heading}
                                        onSelect={handlePlanSelection}
                                    />
                                </div>
                            ))}
                        </Slider>

                </div>
            </div>
        </>
    );
};

export default MorePlansModal;
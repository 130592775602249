import React from 'react';
import ReactDOM from 'react-dom';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CloseIcon} from "../../../ChatIcons/cross.svg";

const ChangeToPrivateModal = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation(); // Initialize the translation function

    return ReactDOM.createPortal(
        <div className="modal-backdrop">
            <div className="modal-content">
                <div className='notification-label'>
                    {t('change_to_private')} {/* Translated string */}
                    <div className="modal-close-icon-notification" onClick={onCancel}>
                        <CloseIcon/>
                    </div>
                </div>
                <p>{t('change_to_private_description')}</p> {/* Translated description */}
                <div className="modal-actions">
                    <button className="save-settings-button"
                            style={{width: '100%'}}
                            onClick={onConfirm}>{t('confirm_button')} {/* Translated button text */}
                    </button>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default ChangeToPrivateModal;

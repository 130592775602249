import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from "../icons/CloseIcon.svg";
import {useTranslation} from "react-i18next";


const UnderReviewModal = ({ onClose }) => {


    const {t} = useTranslation();


    return ReactDOM.createPortal(
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className="notification-label">
                    ✅ {t('ThankYou')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon />
                    </div>
                </div>

                <div className='sublabel-notification'>
                    <h2>{t('ChangesUnderReview')}</h2>
                    <br></br>
                    <span>{t('ReviewMessage')}</span>
                </div>

                <button onClick={onClose} className="notification-button-main">{t('okay')}</button>


            </div>
        </div>,
        document.body
    );
};

export default UnderReviewModal;
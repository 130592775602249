import React from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CloseIcon} from '../icons/CloseIcon.svg'
import '../AdvancedCharacterEdit.css'
import {useTranslation} from "react-i18next";

const ChangeToPublicModal = ({onConfirm, onCancel}) => {

    const { t } = useTranslation();

    return ReactDOM.createPortal(
        <div className="modal-backdrop">
            <div className="modal-content">
                <div className='notification-label'>
                    ✋ {t('WaitASecond')}
                    <div className="modal-close-icon-notification" onClick={onCancel}>
                        <CloseIcon/>
                    </div>
                </div>
                <span className="sublabel-notification">
                    {t('MakePublicConfirmation')}
                    <br></br>    <br></br>
                    {t('ReviewWarning')}
                </span>
                <div className="modal-actions">
                    <button className="save-settings-button"
                            style={{width: '100%'}}
                            onClick={onConfirm}>
                        {t('Continue')}
                    </button>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default ChangeToPublicModal;
import React from 'react';
import './ModalAndNotifications.css';
import {ReactComponent as CloseIcon} from "../MainMenuPage/icons/cross.svg";
import {useTranslation} from "react-i18next";

const ImageGenerationModal = ({isOpen, onClose, children, images}) => {

    const {t} = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content-notification">
                <div className='notification-label'>
                    💔 {t('sorry')}
                    <div className="modal-close-icon-notification" onClick={onClose}>
                        <CloseIcon/>
                    </div>
                </div>

                <div className="gap-between-notification">
                    <div className='sublabel-notification'>
                        {t('imageGenNotPar')}
                    </div>

                    <div className='sublabel-notification'>
                        {t('reimbursed')}
                        <span className="colored-purple-text"> {images / 4} {t('tokens')}</span>.
                    </div>

                    <div className='sublabel-notification'>
                        {t('extraImages')}
                        <span className="colored-purple-text"> {(3 * images) / 4} {t('extraImagesApology')}</span>.
                    </div>

                    <div className='sublabel-notification'>
                        {t('thankYou')}
                    </div>
                </div>

                <button onClick={onClose} className="notification-button-main">{t('claimReward')}</button>

            </div>
        </div>
    );
};

export default ImageGenerationModal;